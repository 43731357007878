import _useHasAIJourneysAccessFragment_query from "./__generated__/useHasAIJourneysAccessFragment_query.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import useHasAIJourneysAccessFragment_query from './__generated__/useHasAIJourneysAccessFragment_query.graphql';
export const useHasAIJourneysAccessFragment = _useHasAIJourneysAccessFragment_query; // a client has access to AI Journeys as long as they currently use or have previously used AI Journeys.

export function useHasAIJourneysAccess(queryRef) {
  var _fragmentData$feature, _fragmentData$feature2;

  const fragmentData = useFragment(useHasAIJourneysAccessFragment_query, queryRef);
  const hasAIJourneysAccess = !!(fragmentData !== null && fragmentData !== void 0 && (_fragmentData$feature = fragmentData.featureFlags) !== null && _fragmentData$feature !== void 0 && (_fragmentData$feature2 = _fragmentData$feature.edges) !== null && _fragmentData$feature2 !== void 0 && _fragmentData$feature2.length);
  return [hasAIJourneysAccess];
}