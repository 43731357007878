/**
 * @generated SignedSource<<bfac3cd5d91b5c4abf1607eeefacca54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AIBrandVoiceAllCapsUsage = "ALL_CAPS_USAGE_ADAPTIVE" | "ALL_CAPS_USAGE_FREQUENTLY" | "ALL_CAPS_USAGE_NEVER" | "ALL_CAPS_USAGE_SPARINGLY" | "%future added value";
export type AIBrandVoiceEmojiFrequency = "EMOJI_FREQUENCY_ADAPTIVE" | "EMOJI_FREQUENCY_FREQUENTLY" | "EMOJI_FREQUENCY_NEVER" | "EMOJI_FREQUENCY_SPARINGLY" | "%future added value";
export type AIBrandVoiceProductTitleFormatting = "PRODUCT_TITLE_FORMATTING_LOWER_CASE" | "PRODUCT_TITLE_FORMATTING_TITLE_CASE" | "PRODUCT_TITLE_FORMATTING_UPPER_CASE" | "%future added value";
export type AIBrandVoiceUrgency = "URGENCY_ADAPTIVE" | "URGENCY_FREQUENTLY" | "URGENCY_NEVER" | "URGENCY_SPARINGLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2$data = {
  readonly allCapsUsage: AIBrandVoiceAllCapsUsage | null;
  readonly emojiFrequency: AIBrandVoiceEmojiFrequency;
  readonly id: string;
  readonly productTitleFormatting: AIBrandVoiceProductTitleFormatting | null;
  readonly toneKeywords: ReadonlyArray<string>;
  readonly urgency: AIBrandVoiceUrgency | null;
  readonly " $fragmentType": "BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2";
};
export type BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2$key = {
  readonly " $data"?: BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urgency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toneKeywords",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allCapsUsage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emojiFrequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productTitleFormatting",
      "storageKey": null
    }
  ],
  "type": "AIBrandVoiceSettingsV2",
  "abstractKey": null
};

(node as any).hash = "07a30c6579135bac96008cb977f77f70";

export default node;
