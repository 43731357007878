/**
 * @generated SignedSource<<8e20ace2f639806c14b7ffcb8b608a90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AutoJourneyStatus = "AUTO_JOURNEY_STATUS_ACTIVE" | "AUTO_JOURNEY_STATUS_DELETED" | "AUTO_JOURNEY_STATUS_PAUSED" | "AUTO_JOURNEY_STATUS_UNKNOWN" | "%future added value";
export type UpdateAutoJourneyInput = {
  clientSettings?: AutoJourneyClientSettingsInput | null;
  config?: AutoJourneyConfigInput | null;
  id: string;
  status?: AutoJourneyStatus | null;
};
export type AutoJourneyClientSettingsInput = {
  utmSettingsInput: UtmSettingsInput;
};
export type UtmSettingsInput = {
  customUrlInputParams?: ReadonlyArray<CustomUrlInputParamsEntry> | null;
  isUtmTrackingEnabled: boolean;
  utmCampaign?: string | null;
};
export type CustomUrlInputParamsEntry = {
  key: string;
  value: string;
};
export type AutoJourneyConfigInput = {
  journeyId?: number | null;
  trafficPercentage?: number | null;
};
export type ExperimentStatusDialogMutation$variables = {
  input: UpdateAutoJourneyInput;
};
export type ExperimentStatusDialogMutation$data = {
  readonly updateAutoJourney: {
    readonly journey: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AIJourneysTableRowFragment_autoJourney">;
    };
  } | null;
};
export type ExperimentStatusDialogMutation = {
  response: ExperimentStatusDialogMutation$data;
  variables: ExperimentStatusDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExperimentStatusDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAutoJourneysPayload",
        "kind": "LinkedField",
        "name": "updateAutoJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoJourney",
            "kind": "LinkedField",
            "name": "journey",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AIJourneysTableRowFragment_autoJourney"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExperimentStatusDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAutoJourneysPayload",
        "kind": "LinkedField",
        "name": "updateAutoJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoJourney",
            "kind": "LinkedField",
            "name": "journey",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoJourneyConfig",
                "kind": "LinkedField",
                "name": "config",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trafficPercentage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a94beb1d13784bb1cdc11cdb6768d24",
    "id": null,
    "metadata": {},
    "name": "ExperimentStatusDialogMutation",
    "operationKind": "mutation",
    "text": "mutation ExperimentStatusDialogMutation(\n  $input: UpdateAutoJourneyInput!\n) {\n  updateAutoJourney(input: $input) {\n    journey {\n      id\n      ...AIJourneysTableRowFragment_autoJourney\n    }\n  }\n}\n\nfragment AIJourneysTableRowFragment_autoJourney on AutoJourney {\n  id\n  name\n  status\n  config {\n    trafficPercentage\n  }\n  updated\n}\n"
  }
};
})();

(node as any).hash = "5b0513843f17aaa4825452469cf2c250";

export default node;
