import React from 'react';

import { RadioGroup, styled } from '@attentive/picnic';

export interface StyledRadioButtonProps {
  disabled?: boolean;
  label: React.ReactNode;
  variant?: 'magic';
  value: string;
}

const StyledRadioWrapper = styled('div', {
  width: '100%',
  '& label': {
    borderRadius: '$radius2',
    display: 'flex',
    minHeight: '$size15',
    height: '100%',
    padding: '$space2 $space3',
    alignItems: 'center',
    gap: '$space2',
    border: '1px solid $borderDefault',
    justifyContent: 'flex-start',
  },

  variants: {
    variant: {
      magic: {
        '& label': {
          backgroundImage: '$bgGradientMagic',
          backgroundColor: '$bgGradientMagicFallback',
          border: 'none',
        },
      },
    },
  },
});

export const StyledRadioButton: React.FunctionComponent<StyledRadioButtonProps> = ({
  disabled,
  label,
  variant,
  value,
}) => {
  return (
    <StyledRadioWrapper variant={variant}>
      <RadioGroup.Radio value={value} disabled={disabled}>
        {label}
      </RadioGroup.Radio>
    </StyledRadioWrapper>
  );
};

export { RadioGroup };
