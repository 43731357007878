import React, { useContext } from 'react';

import { Box, compositeComponent } from '@attentive/picnic';

import { PillButton, PillButtonProps } from '../PillButton';

type PillButtonGroupProps<T extends string> = {
  children: React.ReactElement<PillButtonProps<T>> | Array<React.ReactElement<PillButtonProps<T>>>;
  onChange: (value: T[]) => void;
  value: T[];
  label: string;
} & Omit<React.ComponentProps<typeof Box>, 'children' | 'onChange'>;

type PillButtonGroupContextType<T extends string> = {
  values: T[];
  onChange: (val: T) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PillButtonGroupContext = React.createContext<PillButtonGroupContextType<any>>({
  values: [],
  onChange: () => {},
});

export const usePillButtonGroup = <T extends string>(): PillButtonGroupContextType<T> =>
  useContext(PillButtonGroupContext);

const PillButtonGroup = <T extends string>({
  children,
  onChange,
  value = [],
  label,
  ...boxProps
}: PillButtonGroupProps<T>) => {
  const handleOnChange = (singleValue: T) => {
    if (value.includes(singleValue)) {
      onChange(value.filter((val) => val !== singleValue));
    } else {
      onChange([...value, singleValue]);
    }
  };

  return (
    <PillButtonGroupContext.Provider value={{ values: value, onChange: handleOnChange }}>
      <Box as="section" aria-label={label} {...boxProps}>
        {children}
      </Box>
    </PillButtonGroupContext.Provider>
  );
};

const ContextualPillButton = <T extends string>(
  props: Omit<PillButtonProps<T>, 'onClick' | 'isSelected'>
) => {
  const { values, onChange } = usePillButtonGroup<T>();
  const isSelected = values.includes(props.value);

  return <PillButton<T> {...props} isSelected={isSelected} onClick={onChange} />;
};

const Namespace = compositeComponent(PillButtonGroup, {
  PillButton: ContextualPillButton,
});

export { Namespace as PillButtonGroup };
