import React from 'react';

import { StandardDialog, Button, Tooltip, Text } from '@attentive/picnic';

import {
  BrandVoiceSettingsFormProvider,
  useBrandVoiceSettingsForm,
  useSaveBrandVoiceSettingsForm,
} from '../BrandVoiceSettingsFormProvider';

import { BrandVoiceSettingsDialogContent } from './BrandVoiceSettingsDialogContent';

interface BrandVoiceSettingsDialogComponentProps {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onSaveSuccess?: () => void;
  showProductTitleFormatting?: boolean;
}

const BrandVoiceSettingsDialogComponent: React.FunctionComponent<
  BrandVoiceSettingsDialogComponentProps
> = ({ open, onOpenChange, onSaveSuccess, showProductTitleFormatting }) => {
  const [saveBrandVoiceSettings, inFlight] = useSaveBrandVoiceSettingsForm();
  const [brandVoiceSettingsForm] = useBrandVoiceSettingsForm();

  const handleSaveSettings = () => {
    saveBrandVoiceSettings({
      onCompleted: () => {
        onSaveSuccess?.();
        onOpenChange(false);
      },
    });
  };

  return (
    <StandardDialog open={open} onOpenChange={onOpenChange}>
      {/** Make dialog as wide as possible to prevent sides scrolling as much as possible */}
      <StandardDialog.Content css={{ maxWidth: 'unset', width: '90%' }}>
        <StandardDialog.Header>
          <StandardDialog.Heading>Brand voice settings</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <BrandVoiceSettingsDialogContent
            showProductTitleFormatting={showProductTitleFormatting}
          />
        </StandardDialog.Body>
        <StandardDialog.Footer>
          <StandardDialog.Close variant="secondary">Cancel</StandardDialog.Close>
          <Tooltip>
            <Tooltip.Trigger>
              <Button
                onClick={handleSaveSettings}
                loading={inFlight}
                disabled={brandVoiceSettingsForm.saveDisabled}
              >
                Save settings
              </Button>
            </Tooltip.Trigger>
            {brandVoiceSettingsForm.tooltipText && (
              <Tooltip.Content>
                <Text variant="body">{brandVoiceSettingsForm.tooltipText}</Text>
              </Tooltip.Content>
            )}
          </Tooltip>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};

export const BrandVoiceSettingsDialog = (props: BrandVoiceSettingsDialogComponentProps) => {
  return props.open ? (
    <BrandVoiceSettingsFormProvider>
      <BrandVoiceSettingsDialogComponent {...props} />
    </BrandVoiceSettingsFormProvider>
  ) : null;
};
