import type { Decorator } from '@storybook/react';

import { MockDataOverrideDecorator } from '@attentive/mock-data';

import { DropFirst } from '../../types';

import BrandVoiceSettingsFormLoaderQuery, {
  BrandVoiceSettingsFormLoaderQuery as BrandVoiceSettingsFormLoaderQueryType,
} from './__generated__/BrandVoiceSettingsFormLoaderQuery.graphql';
import SaveBrandVoiceSettingsFormMutation, {
  useSaveBrandVoiceSettingsFormMutation as SaveBrandVoiceSettingsFormMutationType,
} from './__generated__/useSaveBrandVoiceSettingsFormMutation.graphql';

type FormLoaderDecoratorOverrides = DropFirst<
  Parameters<typeof MockDataOverrideDecorator<BrandVoiceSettingsFormLoaderQueryType>>
>;

export const BrandVoiceSettingsFormLoaderQueryDecorator = (
  ...args: FormLoaderDecoratorOverrides
): Decorator => {
  return (...decoratorArgs) =>
    MockDataOverrideDecorator<BrandVoiceSettingsFormLoaderQueryType>(
      BrandVoiceSettingsFormLoaderQuery,
      ...args
    )(...decoratorArgs);
};

type SaveFormDecoratorOverrides = DropFirst<
  Parameters<typeof MockDataOverrideDecorator<SaveBrandVoiceSettingsFormMutationType>>
>;

export const SaveBrandVoiceSettingsFormMutationDecorator = (
  ...args: SaveFormDecoratorOverrides
): Decorator => {
  return (...decoratorArgs) =>
    MockDataOverrideDecorator<SaveBrandVoiceSettingsFormMutationType>(
      SaveBrandVoiceSettingsFormMutation,
      ...args
    )(...decoratorArgs);
};
