import React from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Box, ContainedLabel, Text, styled } from '@attentive/picnic';

import { useAIConnectedJourneys } from '../components/AIConnectedJourneysProvider';
import { AIJourneysSection } from '../components/AIJourneysSection';
import { IconHeader } from '../components/IconHeader';
import { JourneysTable } from '../components/JourneysTable';

const PageSection = styled('section', {
  marginBottom: '$space10',
});

export const AIJourneysList = () => {
  const isAIJourneysEnabled = useCompanyFeatureFlag('ENABLE_AI_JOURNEYS_UI');
  const { JOURNEY_TRIGGER_TYPE_ADD_TO_CART: hasCartAbandonmentJourney } = useAIConnectedJourneys();

  return (
    <Box css={{ paddingTop: '$space4' }}>
      {isAIJourneysEnabled && (
        <PageSection>
          <IconHeader text="AI Journeys" iconName="Magic">
            <ContainedLabel variant="decorative1">Beta</ContainedLabel>
          </IconHeader>
          {hasCartAbandonmentJourney ? (
            <AIJourneysSection />
          ) : (
            <Text>
              Company needs to have an active Cart Abandonment journey in order to activate an AI
              Journey.
            </Text>
          )}
        </PageSection>
      )}
      <PageSection>
        <IconHeader text="Journeys" iconName="Workflow" />
        <JourneysTable />
      </PageSection>
    </Box>
  );
};
