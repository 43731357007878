import React from 'react';

import { LoadingPlaceholder, Stack, Text, styled } from '@attentive/picnic';

const minHeight = '12rem';

const BrandVoicePreviewMessageCard = styled('div', {
  backgroundColor: '$bgDefault',
  border: '1px solid $bgDecorative3Accent',
  boxShadow: '$shadow1',
  padding: '$space3 $space4 $space2',
  borderRadius: '$radius2',
  whiteSpace: 'pre-wrap',
  width: '100%',
  minHeight,
  flexGrow: 1,
});

export const BrandVoicePreviewMessage = ({ children = '' }: { children?: React.ReactNode }) => (
  <BrandVoicePreviewMessageCard>
    <Text className="brand-voice-preview-text-content">{children}</Text>
  </BrandVoicePreviewMessageCard>
);

const TextPlaceholder = styled(LoadingPlaceholder, {
  width: '100%',
  height: '1rem',
});

export const BrandVoicePreviewMessageLoading = () => (
  <BrandVoicePreviewMessageCard>
    <Stack spacing="$space3" className="brand-voice-preview-text-loading">
      <TextPlaceholder />
      <TextPlaceholder />
      <TextPlaceholder />
      <TextPlaceholder css={{ width: '60%' }} />
    </Stack>
  </BrandVoicePreviewMessageCard>
);
