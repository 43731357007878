import React, { useState } from 'react';

import { IconPopover, PicnicCss } from '@attentive/picnic';

import { GeneratedTextFeedbackForm } from '../GeneratedTextFeedbackForm';
import { useGeneratedTextFeedbackState } from '../GeneratedTextFeedbackProvider';

type GeneratedTextFeedbackFlagProps = {
  trackingLabel?: string;
  css?: PicnicCss;
} & React.ComponentProps<typeof GeneratedTextFeedbackForm>;

export function GeneratedTextFeedbackFlag({
  onSuccess,
  trackingLabel = 'GeneratedText__feedback-flag',
  css = {},
  ...props
}: GeneratedTextFeedbackFlagProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { checkIsGeneratedTextFlagged } = useGeneratedTextFeedbackState();
  const isTextFlagged = checkIsGeneratedTextFlagged(props.generatedText.id);

  const onFormSubmitSuccess = () => {
    setIsPopoverOpen(false);
    onSuccess?.();
  };

  return (
    <IconPopover
      iconName="Flag"
      size="extraSmall"
      side="bottom"
      alignOffset={-3}
      align="end"
      open={isPopoverOpen}
      onOpenChange={(open) => setIsPopoverOpen(open)}
      iconColor={isTextFlagged ? 'error' : undefined}
      data-heap-tracking={trackingLabel}
      disabled={isTextFlagged}
      description="Flag suggestion"
      css={css}
    >
      <GeneratedTextFeedbackForm onSuccess={onFormSubmitSuccess} {...props} />
    </IconPopover>
  );
}
