/**
 * @generated SignedSource<<522366f22ae4c1bb1b85f3eab2b7372b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AutoJourneyStatus = "AUTO_JOURNEY_STATUS_ACTIVE" | "AUTO_JOURNEY_STATUS_DELETED" | "AUTO_JOURNEY_STATUS_PAUSED" | "AUTO_JOURNEY_STATUS_UNKNOWN" | "%future added value";
export type AIJourneysSectionQuery$variables = {
  companyId: string;
};
export type AIJourneysSectionQuery$data = {
  readonly company: {
    readonly autoJourneys?: {
      readonly journeys: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"AIJourneysTableFragment_listAutoJourneysResponse">;
    } | null;
  } | null;
};
export type AIJourneysSectionQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly autoJourneys: {
      readonly journeys: ReadonlyArray<{
        readonly config: {
          readonly trafficPercentage: number | null;
        };
        readonly id: string;
        readonly name: string;
        readonly status: AutoJourneyStatus;
        readonly updated: SerializedDateTime | null;
      }>;
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type AIJourneysSectionQuery = {
  rawResponse: AIJourneysSectionQuery$rawResponse;
  response: AIJourneysSectionQuery$data;
  variables: AIJourneysSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "statuses",
    "value": [
      "AUTO_JOURNEY_STATUS_ACTIVE",
      "AUTO_JOURNEY_STATUS_PAUSED"
    ]
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIJourneysSectionQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ListAutoJourneysResponse",
                "kind": "LinkedField",
                "name": "autoJourneys",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AIJourneysTableFragment_listAutoJourneysResponse"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AutoJourney",
                    "kind": "LinkedField",
                    "name": "journeys",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "autoJourneys(statuses:[\"AUTO_JOURNEY_STATUS_ACTIVE\",\"AUTO_JOURNEY_STATUS_PAUSED\"])"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIJourneysSectionQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ListAutoJourneysResponse",
                "kind": "LinkedField",
                "name": "autoJourneys",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AutoJourney",
                    "kind": "LinkedField",
                    "name": "journeys",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AutoJourneyConfig",
                        "kind": "LinkedField",
                        "name": "config",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trafficPercentage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updated",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "autoJourneys(statuses:[\"AUTO_JOURNEY_STATUS_ACTIVE\",\"AUTO_JOURNEY_STATUS_PAUSED\"])"
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06218e261d926fbae8a207ced9cd25c2",
    "id": null,
    "metadata": {},
    "name": "AIJourneysSectionQuery",
    "operationKind": "query",
    "text": "query AIJourneysSectionQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      autoJourneys(statuses: [AUTO_JOURNEY_STATUS_ACTIVE, AUTO_JOURNEY_STATUS_PAUSED]) {\n        ...AIJourneysTableFragment_listAutoJourneysResponse\n        journeys {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment AIJourneysTableFragment_listAutoJourneysResponse on ListAutoJourneysResponse {\n  journeys {\n    ...AIJourneysTableRowFragment_autoJourney\n    id\n    status\n  }\n}\n\nfragment AIJourneysTableRowFragment_autoJourney on AutoJourney {\n  id\n  name\n  status\n  config {\n    trafficPercentage\n  }\n  updated\n}\n"
  }
};
})();

(node as any).hash = "30ff97c7d498765b5c868b6f997eb89e";

export default node;
