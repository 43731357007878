/**
 * @generated SignedSource<<b4472015d28e24fae5bdfb506f563a01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Carrier = "CARRIER_APPALACHIAN" | "CARRIER_ATT" | "CARRIER_BELL" | "CARRIER_CLEARSKY" | "CARRIER_GOOGLE_VOICE" | "CARRIER_INTEROP" | "CARRIER_MISC" | "CARRIER_ROGERS" | "CARRIER_TELUS" | "CARRIER_TMOBILE" | "CARRIER_UNKNOWN" | "CARRIER_US_CELLULAR" | "CARRIER_VERIZON" | "%future added value";
export type MessageSortField = "MESSAGE_SORT_FIELD_CREATED_AT" | "MESSAGE_SORT_FIELD_UNKNOWN" | "%future added value";
export type MessageStatus = "STATUS_CREATED" | "STATUS_DELIVERED" | "STATUS_FAILED" | "STATUS_FATIGUED" | "STATUS_INCOMPLETE" | "STATUS_MITIGATED" | "STATUS_RECEIVED" | "STATUS_SEEN" | "STATUS_SENT" | "STATUS_UNDELIVERED" | "STATUS_UNKNOWN" | "%future added value";
export type Provider = "PROVIDER_BANDWIDTH" | "PROVIDER_GENERIC" | "PROVIDER_IMI_MOBILE" | "PROVIDER_MITTO" | "PROVIDER_OPEN_MARKET" | "PROVIDER_SINCH" | "PROVIDER_TWILIO" | "PROVIDER_UNKNOWN" | "PROVIDER_VIBES" | "PROVIDER_ZIPWHIP" | "%future added value";
export type SortDirection = "SORT_DIRECTION_ASC" | "SORT_DIRECTION_DESC" | "SORT_DIRECTION_UNKNOWN" | "%future added value";
export type FindMessagesFilter = {
  carriers?: ReadonlyArray<Carrier> | null;
  companyId?: string | null;
  countryCodes?: ReadonlyArray<string> | null;
  dateRange: DateRange;
  errorCode?: string | null;
  fromPhoneNumber?: string | null;
  fromPhoneNumbers?: ReadonlyArray<string> | null;
  messageId?: number | null;
  providers?: ReadonlyArray<Provider> | null;
  status?: MessageStatus | null;
  toPhoneNumber?: string | null;
  toPhoneNumbers?: ReadonlyArray<string> | null;
};
export type DateRange = {
  end: SerializedDateTime;
  start: SerializedDateTime;
};
export type MessageSpotlightLoaderQuery$variables = {
  after?: string | null;
  filter: FindMessagesFilter;
  first: number;
  sortBy?: MessageSortField | null;
  sortDirection?: SortDirection | null;
};
export type MessageSpotlightLoaderQuery$data = {
  readonly messages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly created: SerializedDateTime;
        readonly id: string;
        readonly mediaUrls: ReadonlyArray<string>;
      };
    }>;
  } | null;
};
export type MessageSpotlightLoaderQuery$rawResponse = {
  readonly messages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly created: SerializedDateTime;
        readonly id: string;
        readonly mediaUrls: ReadonlyArray<string>;
      };
    }>;
  } | null;
};
export type MessageSpotlightLoaderQuery = {
  rawResponse: MessageSpotlightLoaderQuery$rawResponse;
  response: MessageSpotlightLoaderQuery$data;
  variables: MessageSpotlightLoaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortDirection"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "sortBy",
        "variableName": "sortBy"
      },
      {
        "kind": "Variable",
        "name": "sortDirection",
        "variableName": "sortDirection"
      }
    ],
    "concreteType": "MessageConnection",
    "kind": "LinkedField",
    "name": "messages",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mediaUrls",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageSpotlightLoaderQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "MessageSpotlightLoaderQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "aaffcafb367a3462193e98d07c8fb998",
    "id": null,
    "metadata": {},
    "name": "MessageSpotlightLoaderQuery",
    "operationKind": "query",
    "text": "query MessageSpotlightLoaderQuery(\n  $first: Int!\n  $after: String\n  $filter: FindMessagesFilter!\n  $sortDirection: SortDirection\n  $sortBy: MessageSortField\n) {\n  messages(first: $first, after: $after, filter: $filter, sortDirection: $sortDirection, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        body\n        created\n        mediaUrls\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61ff0f0518d8fc36deb3dab073141ab6";

export default node;
