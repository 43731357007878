import * as Collapsible from '@radix-ui/react-collapsible';
import React, { useEffect, useState } from 'react';

import { useToast, ToastType, UiError, useCompanyFeatureFlag } from '@attentive/acore-utils';
import { PromptRefinementOption, AIGeneratedTextOrigin } from '@attentive/data/types';
import {
  Button,
  FormField,
  Icon,
  Stack,
  TextArea,
  Text,
  LoadingIndicator,
  Link,
  Box,
  Banner,
  IconCircle,
  IconButton,
  Heading,
  keyframes,
  styled,
} from '@attentive/picnic';

import { BrandVoiceSettingsDialog } from '../../brandVoice';
import { logger } from '../../logger';
import {
  GeneratedText,
  useSaveGeneratedTextAndFeedback,
  brandVoiceVendorOptions,
  useGenerateTextsFromTemplate,
  getLoggingMetadataFromGeneratedTextMetadata,
} from '../../utils';
import { useIsSelfServeAIProEnabled } from '../../utils/featureFlags';
import {
  GenerateTextsFunctionArgs,
  GenerateTextsFunctionInput,
  useGenerateExperimentTexts,
} from '../../utils/useGenerateTextsFromTemplate';
import { GeneratedTextButton } from '../GeneratedTextButton';
import { PillButton } from '../PillButton';

const MAX_NUM_AI_GENERATED_COPIES_DISPLAYED = 3;

const EMAIL_ORIGIN: `${AIGeneratedTextOrigin}` =
  'GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_EMAIL_SUBJECT_LINE';

type GenerateTextModelInput = Pick<GenerateTextsFunctionInput, 'template' | 'vendorOptions'>;

const defaultTemplateInput: GenerateTextModelInput = {
  template: {
    templateName: 'email_subject_line',
    version: 'live',
  },
  vendorOptions: undefined,
};
const defaultTemplateBVInput: GenerateTextModelInput = {
  template: {
    templateName: 'email_subject_line_bv',
    version: 'live',
  },
  vendorOptions: brandVoiceVendorOptions,
};

type VisibleRefinementOption = Extract<
  PromptRefinementOption,
  | 'PROMPT_REFINEMENT_OPTION_MAKE_SHORTER'
  | 'PROMPT_REFINEMENT_OPTION_ADD_URGENCY'
  | 'PROMPT_REFINEMENT_OPTION_MAKE_CASUAL'
  | 'PROMPT_REFINEMENT_OPTION_MAKE_FUNNY'
  | 'PROMPT_REFINEMENT_OPTION_ADD_EMOJIS'
>;

const refinementOptionMap: Record<VisibleRefinementOption, string> = {
  PROMPT_REFINEMENT_OPTION_MAKE_SHORTER: 'Make shorter',
  PROMPT_REFINEMENT_OPTION_ADD_URGENCY: 'Add urgency',
  PROMPT_REFINEMENT_OPTION_MAKE_CASUAL: 'Make casual',
  PROMPT_REFINEMENT_OPTION_MAKE_FUNNY: 'Make funny',
  PROMPT_REFINEMENT_OPTION_ADD_EMOJIS: 'Add emojis',
};

const UnstyledCollapsibleTrigger = styled(Collapsible.Trigger, {
  background: 'none',
  border: 'none',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
});

const openAnimation = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' },
});

const closeAnimation = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 },
});

const StyledTriggerIcon = styled(Icon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',

  ':hover > &': {
    color: '$textHover',
  },

  '[data-state=open] &': {
    transform: 'rotate(0deg)',
  },
  '[data-state=closed] &': {
    transform: 'rotate(180deg)',
  },
});

const CollapsibleHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTopLeftRadius: '$radius2',
  borderTopRightRadius: '$radius2',
});

const CollapsibleContent = styled(Collapsible.Content, {
  borderBottomLeftRadius: '$radius2',
  borderBottomRightRadius: '$radius2',
  overflow: 'hidden',
  '&[data-state="open"]': {
    animation: `${openAnimation} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${closeAnimation} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
});

const AIAccordion = ({
  children,
  isDisabled = false,
  open,
  onOpenChange,
  onBrandVoiceSettingsSaved = () => {},
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onBrandVoiceSettingsSaved?: () => void;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const isAIBrandVoiceUIEnabled = useCompanyFeatureFlag('ENABLE_AI_BRAND_VOICE_BRAND_KIT_UI');
  const isAIProBrandVoiceDevEnabled = useCompanyFeatureFlag('ENABLE_AI_PRO_BRAND_VOICE_DEV');
  const enableBrandVoiceSettings = isAIProBrandVoiceDevEnabled && isAIBrandVoiceUIEnabled;
  const isSelfServeAIProEnabled = useIsSelfServeAIProEnabled();

  return (
    <Box
      css={{
        backgroundColor: '$bgDecorative3Default',
        border: '1px solid $bgDecorative3Accent',
        borderRadius: '$radius2',
      }}
    >
      <Collapsible.Root open={open} onOpenChange={onOpenChange}>
        <CollapsibleHeader>
          <UnstyledCollapsibleTrigger aria-label="Toggle accordion" css={{ flexGrow: 1 }}>
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '$space2',
                padding: '$space4 $space6',
              }}
            >
              <IconCircle iconName="Magic" size="extraSmall" color="magic" />
              <Heading as="h3" variant="sm" color="default">
                Generate with AI
              </Heading>
            </Box>
          </UnstyledCollapsibleTrigger>
          <Box css={{ display: 'flex', columnGap: '$space1', paddingRight: '$space6' }}>
            {(isSelfServeAIProEnabled || enableBrandVoiceSettings) && (
              <IconButton
                description="Open brand voice settings dialog"
                iconName="Cog"
                size="small"
                variant="subdued"
                onClick={() => setOpenDialog(true)}
                disabled={isDisabled}
              />
            )}
            <UnstyledCollapsibleTrigger
              css={{ display: 'inline-flex', alignItems: 'center', height: '$size9' }}
            >
              <StyledTriggerIcon size="extraSmall" name="ChevronUp" />
            </UnstyledCollapsibleTrigger>
          </Box>
        </CollapsibleHeader>
        <CollapsibleContent>
          <Box css={{ padding: '$space4 $space6 ', borderTop: '1px solid $bgDecorative3Accent' }}>
            {children}
          </Box>
        </CollapsibleContent>
      </Collapsible.Root>
      <BrandVoiceSettingsDialog
        open={openDialog}
        onOpenChange={setOpenDialog}
        onSaveSuccess={onBrandVoiceSettingsSaved}
      />
    </Box>
  );
};

interface EmailSubjectLineAssistantProps {
  messageId?: string;
  onChange: (newSubjectLine: string) => void;
  suggestionActions?: (props: GeneratedText, genTextPosition: number) => React.ReactElement;
}

const EmailSubjectLineAssistantComponent = ({
  messageId,
  onChange,
  suggestionActions,
}: EmailSubjectLineAssistantProps) => {
  const [createToast] = useToast();
  const enableAIProBrandVoiceExp = useCompanyFeatureFlag('ENABLE_BV_AI_PRO_ASSISTANTS_EXPERIMENT');
  const isAIProBrandVoiceDev = useCompanyFeatureFlag('ENABLE_AI_PRO_BRAND_VOICE_DEV');
  const enableBrandVoiceBrandKitUI = useCompanyFeatureFlag('ENABLE_AI_BRAND_VOICE_BRAND_KIT_UI');
  const enableContentModeration = useCompanyFeatureFlag('ENABLE_MESSAGE_CONTENT_MODERATION');
  const hasBrandVoiceAccess = enableBrandVoiceBrandKitUI && isAIProBrandVoiceDev;
  const isSelfServeAIProEnabled = useIsSelfServeAIProEnabled();
  const isMessageContentModerationEnabled = enableContentModeration && isAIProBrandVoiceDev;
  const [inputQueryText, setInputQueryText] = useState('');
  const [selectedText, setSelectedText] = useState<string | undefined>();
  const [generatedTextResults, setGeneratedTextResults] = useState<GeneratedText[]>([]);
  const [open, onOpenChange] = useState(true);
  const [refinementOptionForRegeneration, setRefinementOptionForRegeneration] = useState<
    VisibleRefinementOption | undefined
  >();
  const [generateTexts, inFlight] = useGenerateTextsFromTemplate({ origin: EMAIL_ORIGIN });
  const [generateExpTexts, isExpLoading] = useGenerateExperimentTexts({
    origin: EMAIL_ORIGIN,
    control: {
      ...defaultTemplateInput,
      n: 2,
    },
    experiment: {
      ...defaultTemplateBVInput,
      n: 1,
      useMessageEvaluation: isMessageContentModerationEnabled || isSelfServeAIProEnabled,
    },
  });
  const isLoading = inFlight || isExpLoading;
  const saveTextAndFeedback = useSaveGeneratedTextAndFeedback();

  const handleError = ({ message }: { message: string }) => {
    createToast({
      type: ToastType.Error,
      title: 'Something went wrong',
      text: message,
    });
    logger.logError(new UiError(message));
  };

  const generateSubjectLines = async (refinement: VisibleRefinementOption | undefined) => {
    setGeneratedTextResults([]);

    const args: GenerateTextsFunctionArgs = {
      input: {
        prompt: `${inputQueryText}\n`,
        promptRefinementOptions: refinement ? [refinement] : undefined,
        ...(isSelfServeAIProEnabled || hasBrandVoiceAccess
          ? defaultTemplateBVInput
          : defaultTemplateInput),
        n: 1,
        useMessageEvaluation: isMessageContentModerationEnabled || isSelfServeAIProEnabled,
      },
      onCompleted(data) {
        if (data.length) {
          setGeneratedTextResults((texts) => [
            ...texts,
            {
              ...data[0],
            },
          ]);
        }
      },
      onError: handleError,
    };

    if (enableAIProBrandVoiceExp) {
      generateExpTexts({
        ...args,
        input: {
          prompt: args.input.prompt,
          promptRefinementOptions: args.input.promptRefinementOptions,
        },
        onCompleted: (data) => {
          if (data.length) setGeneratedTextResults(data);
        },
      });
    } else {
      //Longest time to generate text previously (out of 10 attempts) was 3.66s
      // New method, longest time to generate text was 2.2s
      for (let i = 0; i < MAX_NUM_AI_GENERATED_COPIES_DISPLAYED; i++) {
        generateTexts(args);
      }
    }
  };

  const onSubmit = () => {
    setRefinementOptionForRegeneration(undefined);
    generateSubjectLines(undefined);
  };

  const onRefinementClicked = (refinementOption: VisibleRefinementOption) => {
    setRefinementOptionForRegeneration(refinementOption);
    generateSubjectLines(refinementOption);
  };

  const onBrandVoiceSettingsSaved = () => {
    // we will only regenerate subject lines if a user has already generated
    // some prior to saving BV settings.
    if (generatedTextResults.length > 0) generateSubjectLines(refinementOptionForRegeneration);
  };

  const onGeneratedTextSelected = (genText: GeneratedText) => {
    const { text, groupId, source, aiGeneratedTextMetadata } = genText;
    const losers = generatedTextResults.filter((gText) => gText.id !== genText.id);

    onChange(text);
    setSelectedText(text);
    saveTextAndFeedback(
      {
        generatedText: text,
        groupId,
        feedbackType: 'GENERATIVE_AI_TEXT_FEEDBACK_TYPE_RANKED_WINNER',
        messageId,
        source,
        generatedTextInputMetadata: aiGeneratedTextMetadata
          ? getLoggingMetadataFromGeneratedTextMetadata([...aiGeneratedTextMetadata])
          : { origin: EMAIL_ORIGIN },
      },
      losers.map((loserGenText) => ({
        messageId,
        generatedText: loserGenText.text,
        source: loserGenText.source,
        generatedTextInputMetadata: loserGenText.aiGeneratedTextMetadata
          ? getLoggingMetadataFromGeneratedTextMetadata([...loserGenText.aiGeneratedTextMetadata])
          : { origin: EMAIL_ORIGIN },
      }))
    );
  };

  // Clears selected text after a couple of seconds so that the selected state in the UI
  // is temporary to fulfill design requirements
  useEffect(() => {
    if (!selectedText) return;
    const timeout = setTimeout(() => setSelectedText(undefined), 2000);
    return () => clearTimeout(timeout);
  }, [selectedText]);

  const submitButtonLabel = `${
    generatedTextResults.length > 0 || isLoading ? 'Regenerate' : 'Generate'
  } subject lines`;

  return (
    <AIAccordion
      open={open}
      onOpenChange={onOpenChange}
      onBrandVoiceSettingsSaved={onBrandVoiceSettingsSaved}
    >
      <Box>
        <FormField>
          <FormField.Label htmlFor="emailSubjectPrompt">What is your email about?</FormField.Label>
          <TextArea
            id="emailSubjectPrompt"
            value={inputQueryText}
            placeholder="Use an existing email subject line, copy / paste your email content, or describe your email campaign."
            onChange={(event) => setInputQueryText(event.currentTarget.value)}
            css={{ height: 132, resize: 'none' }}
          />
        </FormField>
        <Box css={{ mt: '$space1' }}>
          <Button
            size="small"
            onClick={() => onSubmit()}
            disabled={!inputQueryText || isLoading}
            data-heap-tracking="ai-subject-line-generate-button"
            css={{ gap: '$space2', minHeight: '$size9', width: '100%' }}
            aria-label={submitButtonLabel}
          >
            <Icon name="Magic" size="extraSmall" />
            {submitButtonLabel}
          </Button>
          <Box css={{ display: 'flex', gap: '$space2', mt: '$space2' }}>
            {(generatedTextResults.length > 0 || isLoading) &&
              (Object.keys(refinementOptionMap) as Array<keyof typeof refinementOptionMap>).map(
                (value) => (
                  <PillButton
                    key={value}
                    value={value}
                    onClick={onRefinementClicked}
                    disabled={isLoading}
                    data-heap-tracking={`ai-subject-line-refinement-option-${refinementOptionMap[value]}`}
                  >
                    {refinementOptionMap[value]}
                  </PillButton>
                )
              )}
          </Box>
        </Box>
        {!isLoading && generatedTextResults.length === 0 && (
          <Text>
            <b>Tip:</b> Describe your campaign's duration, specify the discount, and tailor your
            tone to your intended audience.
          </Text>
        )}
        {isLoading ? (
          <Stack
            id="ai-subject-line-loading-content"
            spacing="$space2"
            css={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200,
            }}
          >
            <LoadingIndicator />
            <Text css={{ fontWeight: '$bold' }}> Generating subject lines! </Text>
            <Text>This may take a few seconds while we work our magic.</Text>
          </Stack>
        ) : (
          <Box
            id="ai-subject-line-content"
            css={{ display: 'grid', gap: '$space4', mt: '$space4' }}
          >
            {generatedTextResults?.map((generatedTextResult, idx) => (
              <GeneratedTextButton
                key={generatedTextResult.id}
                generatedText={generatedTextResult}
                onClick={() => onGeneratedTextSelected(generatedTextResult)}
                isAdded={generatedTextResult.text === selectedText}
                addButtonText="Use subject line"
                messageId={messageId || ''}
                trackingNameSpace="eslg"
                origin={EMAIL_ORIGIN}
              >
                {suggestionActions?.(generatedTextResult, idx + 1)}
              </GeneratedTextButton>
            ))}
          </Box>
        )}
        <Text variant="micro" color="subdued" css={{ marginTop: '$space3' }}>
          You are solely responsible for your use of AttentiveAI and any content generated, all of
          which is governed by these{' '}
          <Link
            href="https://attentive.com/legal/ai-terms"
            target="_blank"
            rel="noreferrer"
            css={{ color: 'inherit' }}
          >
            Terms
          </Link>
          .
        </Text>
      </Box>
    </AIAccordion>
  );
};

const DisabledEmailSubjectLineAssistant = () => {
  const [open, onOpenChange] = useState(false);

  return (
    <AIAccordion open={open} onOpenChange={onOpenChange} isDisabled>
      <Banner variant="warning" dismissible={false}>
        <Text>
          Suggested messages are currently disabled. We're aware of the issue and working to resolve
          it quickly.
        </Text>
      </Banner>
    </AIAccordion>
  );
};

export const EmailSubjectLineAssistant = (props: EmailSubjectLineAssistantProps) => {
  const isShutOffSwitchEngaged = useCompanyFeatureFlag('ENABLE_CA_SHUT_OFF_SWITCH');

  return isShutOffSwitchEngaged ? (
    <DisabledEmailSubjectLineAssistant />
  ) : (
    <EmailSubjectLineAssistantComponent {...props} />
  );
};
