import React, { Suspense } from 'react';

import { LoadingPlaceholder, styled } from '@attentive/picnic';

import { BrandVoicePreview } from '../BrandVoicePreview';
import { BrandVoiceSettingsForm } from '../BrandVoiceSettingsForm';
import { BrandVoiceSettingsFormLoader } from '../BrandVoiceSettingsFormProvider';

interface BrandVoiceSettingsDialogContentProps {
  showProductTitleFormatting?: boolean;
}

const DialogContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space8',
});

const BrandVoiceSettingsDialogContentFallback = () => (
  <DialogContentContainer>
    <LoadingPlaceholder css={{ height: '250px', width: '100%' }} />
  </DialogContentContainer>
);

const BrandVoiceSettingsDialogContentComponent: React.FunctionComponent<
  BrandVoiceSettingsDialogContentProps
> = ({ showProductTitleFormatting }) => {
  return (
    <DialogContentContainer id="brand-voice-settings-dialog">
      <BrandVoiceSettingsFormLoader />
      <BrandVoicePreview />
      <BrandVoiceSettingsForm showProductTitleFormatting={showProductTitleFormatting} />
    </DialogContentContainer>
  );
};

export const BrandVoiceSettingsDialogContent: React.FunctionComponent<
  BrandVoiceSettingsDialogContentProps
> = ({ showProductTitleFormatting }) => (
  <Suspense fallback={<BrandVoiceSettingsDialogContentFallback />}>
    <BrandVoiceSettingsDialogContentComponent
      showProductTitleFormatting={showProductTitleFormatting}
    />
  </Suspense>
);
