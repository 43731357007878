import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AIJourneyCreate } from './pages/AIJourneyCreate';
import { AIJourneyPerformancePage } from './pages/AIJourneyPerformancePage/AIJourneyPerformancePage';
import { AIJourneysIndex } from './pages/AIJourneysIndex';
import { AIJourneysList } from './pages/AIJourneysList';

export const AIJourneyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AIJourneysIndex />}>
        <Route index element={<Navigate to="list" replace />} />
        <Route path="list" element={<AIJourneysList />} />
      </Route>
      <Route path="/ai">
        <Route path=":id" element={<AIJourneyPerformancePage />} />
        <Route path="create/:testJourneyId" element={<AIJourneyCreate />} />
      </Route>
    </Routes>
  );
};
