/**
 * @generated SignedSource<<ca7820964eaa1feba2224d7338f813cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AutoJourneyStatus = "AUTO_JOURNEY_STATUS_ACTIVE" | "AUTO_JOURNEY_STATUS_DELETED" | "AUTO_JOURNEY_STATUS_PAUSED" | "AUTO_JOURNEY_STATUS_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AIJourneysTableRowFragment_autoJourney$data = {
  readonly config: {
    readonly trafficPercentage: number | null;
  };
  readonly id: string;
  readonly name: string;
  readonly status: AutoJourneyStatus;
  readonly updated: SerializedDateTime | null;
  readonly " $fragmentType": "AIJourneysTableRowFragment_autoJourney";
};
export type AIJourneysTableRowFragment_autoJourney$key = {
  readonly " $data"?: AIJourneysTableRowFragment_autoJourney$data;
  readonly " $fragmentSpreads": FragmentRefs<"AIJourneysTableRowFragment_autoJourney">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AIJourneysTableRowFragment_autoJourney",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutoJourneyConfig",
      "kind": "LinkedField",
      "name": "config",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trafficPercentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    }
  ],
  "type": "AutoJourney",
  "abstractKey": null
};

(node as any).hash = "2e96b26851f0c6a248649cc2f48341eb";

export default node;
