import { shuffleArray } from '../shuffleArray';

import {
  GenerateTextsFunctionArgs,
  GenerateTextsHookArgs,
  GenerateTextsHookReturnType,
} from './types';
import { useGenerateTextsFromTemplate } from './useGenerateTextsFromTemplate';

export type ExperimentOptions = Pick<
  GenerateTextsFunctionArgs['input'],
  'template' | 'n' | 'vendorOptions' | 'useMessageEvaluation'
>;

type UseGenerateExperimentTextsArgs = GenerateTextsHookArgs & {
  experiment?: ExperimentOptions;
  control?: ExperimentOptions;
};

type SuccessReturnType = Parameters<GenerateTextsFunctionArgs['onCompleted']>[0];

const mergeExperimentIntoArgs = (
  originalArgs: GenerateTextsFunctionArgs,
  experimentOptions: ExperimentOptions
): GenerateTextsFunctionArgs => ({
  ...originalArgs,
  input: {
    ...originalArgs.input,
    ...experimentOptions,
    // Add an extra result to generate in case of sanitization
    n: experimentOptions.n ? experimentOptions.n + 1 : originalArgs.input.n,
  },
});

export const useGenerateExperimentTexts = ({
  experiment,
  control = {},
  ...opts
}: UseGenerateExperimentTextsArgs): GenerateTextsHookReturnType => {
  const [generateTexts, isInFlight] = useGenerateTextsFromTemplate(opts);

  const generateExperimentTexts = (args: GenerateTextsFunctionArgs) => {
    const isRunningExperiment = !!experiment;
    const createGenerateTextsPromise = (promiseArgs: GenerateTextsFunctionArgs) =>
      new Promise<SuccessReturnType>((resolve, reject) => {
        generateTexts({
          ...promiseArgs,
          onCompleted: resolve,
          onError: reject,
        });
      });

    const promises = [
      createGenerateTextsPromise(mergeExperimentIntoArgs(args, control)),
      isRunningExperiment
        ? createGenerateTextsPromise(mergeExperimentIntoArgs(args, experiment))
        : [],
    ];

    Promise.all(promises)
      .then(([controlData, experimentData]) => {
        const data = [];

        // Trim out the extra generated results
        if (Array.isArray(controlData) && controlData.length) {
          data.push(...(control.n ? controlData.splice(0, control.n) : controlData));
        }

        if (isRunningExperiment && Array.isArray(experimentData) && experimentData.length) {
          data.push(...(experiment.n ? experimentData.splice(0, experiment.n) : experimentData));
        }

        args.onCompleted(isRunningExperiment ? shuffleArray(data) : data);
      })
      .catch((err: Error) => {
        args.onError(err);
      });
  };

  return [generateExperimentTexts, isInFlight];
};
