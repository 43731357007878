import React, { useEffect, useRef, ChangeEvent } from 'react';

import { Grid, IconButton, Text, TextInput } from '@attentive/picnic';

import { ParamInterface } from './CustomParameters';
import { UTMTypes, replaceSpaceWithDash } from './customParametersUtils';

const DUPLICATE_UTM =
  'This UTM parameter is already included by Google Analytics link tracking. You can disable this above.';
const EMPTY_STRING = 'Please enter a value';

interface CustomParameterRowProps {
  param: ParamInterface;
  isTrackingEnabled: boolean;
  disabled?: boolean;
  onChange: (param: ParamInterface) => void;
  onDelete: () => void;
}

export const CustomParameterRow = ({
  param,
  isTrackingEnabled,
  disabled,
  onChange,
  onDelete,
}: CustomParameterRowProps) => {
  const { key, value, keyError, valueError } = param;
  const prevIsTrackingEnabled = useRef(isTrackingEnabled);

  const getKeyError = (input: string) => {
    if (input.length < 1) {
      return EMPTY_STRING;
    } else if (isTrackingEnabled && Object.values(UTMTypes).includes(input as UTMTypes)) {
      return DUPLICATE_UTM;
    }
    return '';
  };

  const getValueError = (input: string) => {
    if (input.length < 1) {
      return EMPTY_STRING;
    }
    return '';
  };

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = replaceSpaceWithDash(event.target.value);
    onChange({ ...param, key: input, keyError: getKeyError(input) });
  };
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = replaceSpaceWithDash(event.target.value);
    onChange({ ...param, value: input, valueError: getValueError(input) });
  };

  const handleKeyBlur = () => {
    onChange({ ...param, keyError: getKeyError(key) });
  };
  const handleValueBlur = () => {
    onChange({ ...param, valueError: getValueError(value) });
  };

  useEffect(() => {
    if (prevIsTrackingEnabled.current !== isTrackingEnabled && key.length > 0) {
      handleKeyBlur();
    }
    prevIsTrackingEnabled.current = isTrackingEnabled;
  });

  const errorText = keyError || valueError;

  return (
    <>
      <Grid columns={20} css={{ alignItems: 'center', columnGap: '$space2', mb: '$space2' }}>
        <Grid.Cell colSpan={9}>
          <TextInput
            onChange={handleKeyChange}
            onBlur={handleKeyBlur}
            value={key}
            state={keyError ? 'error' : undefined}
            aria-label="url param name"
            placeholder="e.g. src"
            disabled={disabled}
          />
        </Grid.Cell>
        <Grid.Cell colSpan={9}>
          <TextInput
            onChange={handleValueChange}
            onBlur={handleValueBlur}
            value={value}
            state={valueError ? 'error' : undefined}
            aria-label="url param value"
            placeholder="e.g. site"
            disabled={disabled}
          />
        </Grid.Cell>
        <Grid.Cell colSpan={2}>
          <IconButton
            iconName="X"
            description="delete url param"
            size="small"
            css={{ flex: '1 1 10%' }}
            onClick={onDelete}
          />
        </Grid.Cell>
      </Grid>
      {errorText && (
        <Text variant="caption" color="critical">
          {errorText}
        </Text>
      )}
    </>
  );
};
