import { AutoJourneyType } from './__generated__/AIJourneyCreateMutation.graphql';
import { JourneyTriggerType } from './__generated__/AIJourneyCreateQuery.graphql';

export const getDefaultUtmCampaign = (trigger?: JourneyTriggerType) => {
  switch (trigger) {
    case 'JOURNEY_TRIGGER_TYPE_ADD_TO_CART':
      return 'ai_cart_journey';
    case 'JOURNEY_TRIGGER_TYPE_SIGNED_UP':
      return 'ai_welcome_journey';
    default:
      return 'ai_journey';
  }
};

export const getAIJourneyName = (trigger?: JourneyTriggerType) => {
  switch (trigger) {
    case 'JOURNEY_TRIGGER_TYPE_ADD_TO_CART':
      return 'AI Cart Abandonment';
    case 'JOURNEY_TRIGGER_TYPE_SIGNED_UP':
      return 'AI Welcome Journey';
    default:
      return 'AI Journey';
  }
};

export const getAIJourneyType = (trigger?: JourneyTriggerType): AutoJourneyType => {
  switch (trigger) {
    case 'JOURNEY_TRIGGER_TYPE_ADD_TO_CART':
      return 'CART_ABANDONMENT';
    case 'JOURNEY_TRIGGER_TYPE_SIGNED_UP':
      return 'WELCOME';
    default:
      return 'UNKNOWN';
  }
};
