/**
 * @generated SignedSource<<f51d22bd3a96d3775dd4bba4503be355>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TriggerIdSelectMLCompanyQuery$variables = {
  companyId: string;
};
export type TriggerIdSelectMLCompanyQuery$data = {
  readonly company: {
    readonly availableJourneyTriggers?: {
      readonly journeyTriggers: ReadonlyArray<{
        readonly count: number | null;
        readonly id: string;
        readonly name: string;
        readonly source: {
          readonly oneofSource: {
            readonly __typename: string;
          } | null;
        };
      }>;
    } | null;
  };
};
export type TriggerIdSelectMLCompanyQuery = {
  response: TriggerIdSelectMLCompanyQuery$data;
  variables: TriggerIdSelectMLCompanyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JourneyTriggers",
      "kind": "LinkedField",
      "name": "availableJourneyTriggers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JourneyTrigger",
          "kind": "LinkedField",
          "name": "journeyTriggers",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "companyId",
                  "variableName": "companyId"
                }
              ],
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TriggerSource",
              "kind": "LinkedField",
              "name": "source",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "oneofSource",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TriggerIdSelectMLCompanyQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TriggerIdSelectMLCompanyQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69cd31d8f34750a96428895a589f0a2f",
    "id": null,
    "metadata": {},
    "name": "TriggerIdSelectMLCompanyQuery",
    "operationKind": "query",
    "text": "query TriggerIdSelectMLCompanyQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      availableJourneyTriggers {\n        journeyTriggers {\n          id\n          name\n          count(companyId: $companyId)\n          source {\n            oneofSource {\n              __typename\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ccf5265f5785db5737897aac1a9d3d82";

export default node;
