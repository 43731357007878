/**
 * @generated SignedSource<<bfb21903c4fa2b196e56e540f6932d6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JourneyRevisionPublishedStatus = "JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE" | "JOURNEY_REVISION_PUBLISHED_STATUS_DRAFT" | "JOURNEY_REVISION_PUBLISHED_STATUS_INACTIVE" | "JOURNEY_REVISION_PUBLISHED_STATUS_SCHEDULED" | "JOURNEY_REVISION_PUBLISHED_STATUS_UNKNOWN" | "%future added value";
export type JourneysTableML_Companies_refetchable$variables = {
  after?: string | null;
  companyId: string;
  first?: number | null;
  journeyTriggerId?: string | null;
  searchTerm?: string | null;
  status?: JourneyRevisionPublishedStatus | null;
};
export type JourneysTableML_Companies_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JourneysTableML_Companies_fragment">;
};
export type JourneysTableML_Companies_refetchable = {
  response: JourneysTableML_Companies_refetchable$data;
  variables: JourneysTableML_Companies_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "journeyTriggerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "journeyTriggerId",
        "variableName": "journeyTriggerId"
      },
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedTime",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v7 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JourneysTableML_Companies_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JourneysTableML_Companies_fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JourneysTableML_Companies_refetchable",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "JourneyConnection",
                "kind": "LinkedField",
                "name": "journeys",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JourneyEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Journey",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isActive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "approvalStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "eligibleSubscriptionTypes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyRevision",
                            "kind": "LinkedField",
                            "name": "draftRevision",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "publishedStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "approvalFlowRequired",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyRevision",
                            "kind": "LinkedField",
                            "name": "publishedRevision",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v4/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyStats",
                            "kind": "LinkedField",
                            "name": "stats",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "messagesSent",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "clicks",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Money",
                                "kind": "LinkedField",
                                "name": "attributedRevenue",
                                "plural": false,
                                "selections": (v6/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Money",
                                "kind": "LinkedField",
                                "name": "attributedRevenueByCurrency",
                                "plural": true,
                                "selections": (v6/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyTrigger",
                            "kind": "LinkedField",
                            "name": "journeyTrigger",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "JourneysTableML_company_journeys",
                "kind": "LinkedHandle",
                "name": "journeys"
              },
              (v7/*: any*/)
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b13857255fd23bd640d6ccf69c6c20c6",
    "id": null,
    "metadata": {},
    "name": "JourneysTableML_Companies_refetchable",
    "operationKind": "query",
    "text": "query JourneysTableML_Companies_refetchable(\n  $after: String\n  $companyId: ID!\n  $first: Int\n  $journeyTriggerId: ID\n  $searchTerm: String\n  $status: JourneyRevisionPublishedStatus\n) {\n  ...JourneysTableML_Companies_fragment\n}\n\nfragment JourneysTableML_Companies_fragment on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      journeys(first: $first, after: $after, filter: {status: $status, journeyTriggerId: $journeyTriggerId, searchTerm: $searchTerm}) {\n        edges {\n          node {\n            id\n            ...JourneysTableML_Journey_fragment\n            __typename\n          }\n          cursor\n        }\n        totalCount\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment JourneysTableML_Journey_fragment on Journey {\n  id\n  internalId\n  name\n  isActive\n  approvalStatus\n  eligibleSubscriptionTypes\n  draftRevision {\n    lastUpdatedTime\n    publishedStatus\n    approvalFlowRequired\n    internalId\n    id\n  }\n  publishedRevision {\n    lastUpdatedTime\n    internalId\n    id\n  }\n  stats {\n    messagesSent\n    clicks\n    attributedRevenue {\n      currency\n      amount\n    }\n    attributedRevenueByCurrency {\n      currency\n      amount\n    }\n  }\n  journeyTrigger {\n    type\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa0ed345ccb2ee13a79ba5cb6cd548da";

export default node;
