import React, { useState, useMemo, useEffect } from 'react';

import { InputGroup, Text, TextInput, TextInputProps, styled } from '@attentive/picnic';

const regionId = 'livePlaceholderRegion';

enum InputState {
  FOCUSED,
  ACTIVE,
  NOT_ACTIVE,
}

type ElementAttributes = {
  input: Pick<React.HTMLAttributes<HTMLInputElement>, 'aria-describedby'>;
  region: Pick<React.HTMLAttributes<HTMLParagraphElement>, 'aria-live' | 'className'>;
};

const PlaceholderRegion = styled(Text, {
  position: 'absolute',
  width: 'max-content',
  pointerEvents: 'none',

  '&.visually-hidden': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
  },
});

export const RotatingPlaceholderInput = ({
  placeholders,
  value,
  ...inputProps
}: TextInputProps & { value: string; placeholders: string[] }) => {
  const [activePlaceholderIndex, setActivePlaceholderIndex] = useState(0);
  const [inputState, setInputState] = useState<InputState>(InputState.NOT_ACTIVE);
  const updateActivePlaceholderIndex = () => {
    if (!value.length) {
      setActivePlaceholderIndex((currentIndex) => (currentIndex + 1) % placeholders.length);
    }
  };
  const elementAttributes = useMemo<ElementAttributes>(() => {
    const attrs: ElementAttributes = { input: {}, region: {} };

    if (value.length) {
      attrs.region.className = 'visually-hidden';
    }

    switch (inputState) {
      case InputState.ACTIVE:
        attrs.region['aria-live'] = 'polite';
        attrs.input['aria-describedby'] = !value.length ? regionId : undefined;
        break;
      case InputState.FOCUSED:
        attrs.input['aria-describedby'] = regionId;
        attrs.region['aria-live'] = 'polite';
        break;
      case InputState.NOT_ACTIVE:
        attrs.region['aria-live'] = 'off';
        break;
    }

    return attrs;
  }, [value, inputState]);

  useEffect(() => {
    const intervalId = setInterval(updateActivePlaceholderIndex, 5000);
    return () => clearInterval(intervalId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onInputFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
    setInputState(InputState.FOCUSED);
    if (inputProps.onFocus) inputProps.onFocus(ev);
  };

  const onInputBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    setInputState(InputState.NOT_ACTIVE);
    if (inputProps.onBlur) inputProps.onBlur(ev);
  };

  const onInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInputState(InputState.ACTIVE);
    if (inputProps.onChange) inputProps.onChange(ev);
  };

  return (
    <InputGroup>
      <TextInput
        {...inputProps}
        value={value}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onChange={onInputChange}
        {...elementAttributes.input}
      />
      <InputGroup.LeftElement css={{ paddingRight: 0, paddingLeft: '14px' }}>
        <PlaceholderRegion
          id={regionId}
          role="status"
          {...elementAttributes.region}
          variant="caption"
          color="subdued"
        >
          {placeholders[activePlaceholderIndex] || ''}
        </PlaceholderRegion>
      </InputGroup.LeftElement>
    </InputGroup>
  );
};
