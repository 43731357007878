import _useSaveBrandVoiceSettingsFormMutation from "./__generated__/useSaveBrandVoiceSettingsFormMutation.graphql";
import { useMutation } from 'react-relay';
import { useCurrentCompanyId, Tracker, TrackerEvents, useCurrentUser } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { checkNoAdaptiveValues, useBrandVoiceSettingsForm } from './BrandVoiceSettingsFormProvider';
import Mutation from './__generated__/useSaveBrandVoiceSettingsFormMutation.graphql';
_useSaveBrandVoiceSettingsFormMutation;
export function useSaveBrandVoiceSettingsForm() {
  const companyId = useCurrentCompanyId();
  const [saveBrandVoiceSettings, inFlight] = useMutation(Mutation);
  const [{
    brandVoiceSettings,
    savedBrandVoiceSettings
  }] = useBrandVoiceSettingsForm();
  const {
    company
  } = useCurrentUser();

  const wrappedSaveBrandVoiceSettings = config => {
    if (brandVoiceSettings === null) return;
    if (!checkNoAdaptiveValues(brandVoiceSettings)) return;
    Tracker.track({
      eventName: TrackerEvents.BRAND_VOICE_SETTINGS_SAVED,
      properties: {
        companyId: company.id,
        companyName: company.name,
        previousBrandVoiceSettings: JSON.stringify(savedBrandVoiceSettings),
        timeSaved: new Date().toISOString()
      }
    });
    saveBrandVoiceSettings({
      variables: {
        input: {
          companyId,
          aiBrandVoiceSettings: brandVoiceSettings
        }
      },
      ...config,
      onCompleted: (response, errors) => {
        var _response$setAIBrandV, _config$onCompleted;

        if (((_response$setAIBrandV = response.setAIBrandVoiceSettingsV2) === null || _response$setAIBrandV === void 0 ? void 0 : _response$setAIBrandV.__typename) === 'DefaultErrorFailure') {
          var _config$onError;

          (_config$onError = config.onError) === null || _config$onError === void 0 ? void 0 : _config$onError.call(config, new Error(response.setAIBrandVoiceSettingsV2.message));
          return;
        }

        (_config$onCompleted = config.onCompleted) === null || _config$onCompleted === void 0 ? void 0 : _config$onCompleted.call(config, response, errors);
      }
    });
  };

  return [wrappedSaveBrandVoiceSettings, inFlight];
}