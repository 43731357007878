import React from 'react';

import { Heading, IconCircle, IconName, Box } from '@attentive/picnic';

export const IconHeader = ({
  text,
  iconName,
  children,
  level = 'h2',
}: {
  iconName: IconName;
  text: string;
  children?: React.ReactNode;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}) => {
  return (
    <Box css={{ display: 'flex', gap: '$space3', alignItems: 'center', marginBottom: '$space4' }}>
      <IconCircle iconName={iconName} color="default" size="small" />
      <Heading as={level} variant="md">
        {text}
      </Heading>
      {children}
    </Box>
  );
};
