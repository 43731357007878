/**
 * @generated SignedSource<<2abf8a5b6df66fca3859602d7eeb89f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExperimentStatusDialogFragment_autoJourney$data = {
  readonly config: {
    readonly journey: {
      readonly name: string;
    } | null;
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "ExperimentStatusDialogFragment_autoJourney";
};
export type ExperimentStatusDialogFragment_autoJourney$key = {
  readonly " $data"?: ExperimentStatusDialogFragment_autoJourney$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperimentStatusDialogFragment_autoJourney">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperimentStatusDialogFragment_autoJourney",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AutoJourneyConfig",
      "kind": "LinkedField",
      "name": "config",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Journey",
          "kind": "LinkedField",
          "name": "journey",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AutoJourney",
  "abstractKey": null
};
})();

(node as any).hash = "cf37639ab45493c571b5331069762626";

export default node;
