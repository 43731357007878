import React from 'react';

import { MultiSelect, MultiSelectProps, styled } from '@attentive/picnic';

const toneKeywords: string[] = [
  'amusing',
  'approachable',
  'witty',
  'respectful',
  'funny',
  'irreverent',
  'playful',
  'excited',
  'friendly',
  'uplifting',
  'encouraging',
  'optimistic',
  'joyful',
  'loving',
  'inviting',
  'direct',
  'casual',
  'bold',
  'confident',
  'professional',
  'inspirational',
  'promotional',
  'informative',
  'persuasive',
  'trendy',
  'elegant',
  'sophisticated',
  'traditional',
  'exclusive',
  'wise',
  'genuine',
  'relatable',
  'evocative',
].sort();

const StyledMultiSelect = styled(MultiSelect, {
  '& ul': {
    listStyleType: 'none',
  },
  '& ul:not([data-testid^="optgroup"])': {
    padding: '$space4 $space6',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(224px, 25%))',
    'li[role="option"]': {
      margin: '$space0 $space3',
    },
  },
});

type ToneDropdownProps = Omit<MultiSelectProps, 'children'> & { value: string[]; max?: number };

export const ToneDropdown: React.FunctionComponent<ToneDropdownProps> = ({ max, ...props }) => (
  <StyledMultiSelect placeholder="Select keywords" {...props}>
    {toneKeywords.map((word) => (
      <MultiSelect.Item
        key={word}
        value={word}
        disabled={
          typeof max === 'number'
            ? !props.value.includes(word) && max === props.value.length
            : false
        }
      >
        {word}
      </MultiSelect.Item>
    ))}
  </StyledMultiSelect>
);
