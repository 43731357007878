import React from 'react';

import { Box, PicnicCss, Text } from '@attentive/picnic';

export const TextMessagePreview = ({
  body,
  mediaUrl,
  css,
  disableLinks,
}: {
  body: string;
  mediaUrl?: string;
  css?: PicnicCss;
  disableLinks?: boolean;
}) => (
  <Box css={css}>
    {mediaUrl ? (
      <Box css={{ width: '60%', marginBottom: '$space2' }}>
        <Box
          as="img"
          src={mediaUrl}
          css={{ width: '100%', height: 'auto', borderRadius: '$radius2' }}
        />
      </Box>
    ) : null}
    <Box
      css={{
        backgroundColor: '$bgAccent',
        a: { color: '$textHover', pointerEvents: disableLinks ? 'none' : 'auto' },
        borderRadius: '$radius2',
        padding: '$space3',
      }}
    >
      <Text>{body}</Text>
    </Box>
  </Box>
);
