import React from 'react';

import { Box, Text } from '@attentive/picnic';

import { BrandVoiceSettingsValues } from '../BrandVoiceSettingsFormProvider';

import { StyledRadioButtonProps } from './StyledRadioButton';

type RadioOptions<K> = Array<StyledRadioButtonProps & { value: K }>;

const frequentlyLabel = 'Frequently';
const sparinglyLabel = 'Sparingly';
const neverLabel = 'Never';

const RadioLabelWithSubtext = ({
  label,
  subtext,
  children = null,
}: {
  label: string;
  subtext?: string;
  children?: React.ReactNode;
}) => (
  <Box css={{ display: 'flex', alignItems: 'center' }}>
    <Box>
      <Text variant="caption">{label}</Text>
      {subtext && <Text variant="micro">{subtext}</Text>}
    </Box>
    {children}
  </Box>
);

export const emojiFrequencyOptions: RadioOptions<BrandVoiceSettingsValues['emojiFrequency']> = [
  {
    value: 'EMOJI_FREQUENCY_FREQUENTLY',
    label: <RadioLabelWithSubtext label={frequentlyLabel} />,
  },
  {
    value: 'EMOJI_FREQUENCY_SPARINGLY',
    label: <RadioLabelWithSubtext label={sparinglyLabel} />,
  },
  {
    value: 'EMOJI_FREQUENCY_NEVER',
    label: <RadioLabelWithSubtext label={neverLabel} />,
  },
];

export const urgencyOptions: RadioOptions<BrandVoiceSettingsValues['urgency']> = [
  {
    value: 'URGENCY_FREQUENTLY',
    label: <RadioLabelWithSubtext label={frequentlyLabel} />,
  },
  {
    value: 'URGENCY_SPARINGLY',
    label: <RadioLabelWithSubtext label={sparinglyLabel} />,
  },
  {
    value: 'URGENCY_NEVER',
    label: <RadioLabelWithSubtext label={neverLabel} />,
  },
];

export const allCapsOptions: RadioOptions<BrandVoiceSettingsValues['allCapsUsage']> = [
  {
    value: 'ALL_CAPS_USAGE_FREQUENTLY',
    label: <RadioLabelWithSubtext label={frequentlyLabel} />,
  },
  {
    value: 'ALL_CAPS_USAGE_SPARINGLY',
    label: <RadioLabelWithSubtext label={sparinglyLabel} />,
  },
  {
    value: 'ALL_CAPS_USAGE_NEVER',
    label: <RadioLabelWithSubtext label={neverLabel} />,
  },
];

export const productTitleFormattingOptions: RadioOptions<
  BrandVoiceSettingsValues['productTitleFormatting']
> = [
  {
    value: 'PRODUCT_TITLE_FORMATTING_TITLE_CASE',
    label: <RadioLabelWithSubtext label="Title case" subtext={`"Sunset Hoodie"`} />,
  },
  {
    value: 'PRODUCT_TITLE_FORMATTING_UPPER_CASE',
    label: <RadioLabelWithSubtext label="All caps" subtext={`"SUNSET HOODIE"`} />,
  },
  {
    value: 'PRODUCT_TITLE_FORMATTING_LOWER_CASE',
    label: <RadioLabelWithSubtext label="Lower case" subtext={`"sunset hoodie"`} />,
  },
];
