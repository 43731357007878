import { OpenAIModelEnum } from '../../constants/openAIModels';

import { TextGenVendorOptions } from './__generated__/useGenerateTextsFromTemplateGenerateAITextMutation.graphql';

const brandVoiceVendorOptions: TextGenVendorOptions = {
  vendorOptions: {
    openaiOptions: {
      model: OpenAIModelEnum.OPENAI_MODEL_GPT_4_O,
    },
  },
};

export { brandVoiceVendorOptions };
