import _useLogGeneratedTextFeedbackMutation from "./__generated__/useLogGeneratedTextFeedbackMutation.graphql";
import { useCallback } from 'react';
import { useMutation } from 'react-relay';
import { useCurrentCompanyId, useCurrentUser } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import useLogGeneratedTextFeedbackMutation from './__generated__/useLogGeneratedTextFeedbackMutation.graphql';
_useLogGeneratedTextFeedbackMutation;
export function useLogGeneratedTextFeedback() {
  const companyId = useCurrentCompanyId();
  const {
    id: userId
  } = useCurrentUser();
  const [commitFeedback] = useMutation(useLogGeneratedTextFeedbackMutation);
  return useCallback(args => {
    commitFeedback({
      variables: {
        input: {
          companyId,
          generatedTextFeedback: [{
            userId,
            ...args
          }]
        }
      }
    });
  }, [commitFeedback, userId, companyId]);
}