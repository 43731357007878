import React from 'react';

import {
  Grid,
  styled,
  Button,
  Box,
  Icon,
  PicnicCss,
  LoadingPlaceholder,
  Text,
  Link,
} from '@attentive/picnic';

const StyledImageButton = styled(Button, {
  width: '100%',
  height: 'auto',
  aspectRatio: 1,
  backgroundSize: 'contain',
  backgroundRepeat: ' no-repeat',
  backgroundPosition: 'center',
  borderRadius: '$radius2',
  margin: 0,
  border: '1px solid $borderDefault',
  backgroundColor: 'transparent',
  '&:focus-visible': {
    transform: 'scale(1.05)',
    opacity: 1,
  },
  '&:hover:not([disabled]):not(:active), &:hover:not([disabled])': {
    transform: 'scale(1.05)',
    border: '1px solid $borderDefault',
    boxShadow: '0 4px 8px 0 $colors$grayscale900_12',
    opacity: 1,
    backgroundColor: 'transparent',
  },
  variants: {
    status: {
      selected: {
        border: '2px solid $borderSelectedToggle',
        '&:hover:not([disabled]):not(:active), &:hover:not([disabled]), &:focus': {
          border: '2px solid $borderSelectedToggle',
          boxShadow: 'none',
        },
      },
      subdued: {
        opacity: 0.5,
      },
      loading: {},
    },
  },
});

const CircleContainer = styled('div', {
  height: '$size6',
  width: '$size6',
  borderRadius: '$radiusMax',
  backgroundColor: 'white',
  position: 'absolute',
  right: 'calc($space1 * -1.5)',
  top: 'calc($space1 * -1.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 3,
});

type ImageButtonProps = {
  src?: string;
  badge?: React.ReactNode;
  wrapperCss?: PicnicCss;
} & React.ComponentProps<typeof StyledImageButton>;

export const ImageButton = ({
  src,
  badge = null,
  status,
  wrapperCss = {},
  css = {},
  ...buttonProps
}: ImageButtonProps) => (
  <Box css={{ position: 'relative', aspectRatio: 1, ...wrapperCss }}>
    {src && status !== 'loading' ? (
      <>
        {status === 'selected' && (
          <CircleContainer>
            <Icon
              description="selected"
              name="CircleCheckmark"
              mode="presentational"
              css={{ zIndex: 4 }}
            />
          </CircleContainer>
        )}
        {badge}
        <StyledImageButton
          css={{ backgroundImage: `url(${src})`, ...css }}
          data-testid={src}
          variant="basic"
          status={status}
          {...buttonProps}
        />
      </>
    ) : (
      <LoadingPlaceholder
        variant="shimmer"
        css={{ height: '100%', width: '100%', borderRadius: '$radius2' }}
      />
    )}
  </Box>
);

const ImageLink = ({ src }: Required<Pick<ImageButtonProps, 'src'>>) => (
  <Text variant="micro">
    <Link href={src} target="_blank" css={{ padding: '$space2' }}>
      View larger
    </Link>
  </Text>
);

export const ImageButtonWithLink = (props: ImageButtonProps) => {
  const { src } = props;

  return (
    <Box>
      <ImageButton {...props} />
      <Box css={{ paddingTop: '$space2', textAlign: 'center' }}>
        {!!src && <ImageLink src={src} />}
      </Box>
    </Box>
  );
};

export const ImageButtonGrid = styled(Grid, {
  gap: '$space2',
});

const fallbackArray = [...Array.from({ length: 12 })];

export const ImageButtonGridSkeleton = ({ columns = 4 }: { columns?: number }) => (
  <Box>
    <ImageButtonGrid columns={columns}>
      {fallbackArray.map((_, idx) => (
        <ImageButton
          key={idx} // eslint-disable-line react/no-array-index-key
          status="loading"
        />
      ))}
    </ImageButtonGrid>
  </Box>
);
