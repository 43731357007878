import { Locale, CurrencyCode } from '@attentive/locale-utils';

export const calculateClickThroughRate = ({
  messagesSent,
  clicks,
}: {
  messagesSent: number;
  clicks: number;
}): number => (messagesSent === 0 && clicks === 0 ? 0 : (clicks / messagesSent) * 100);

export const formatMoneyValue = ({
  locale = Locale.enUS,
  currencyCode = 'USD',
  amount,
}: {
  locale: Locale;
  currencyCode: `${CurrencyCode}` | CurrencyCode;
  amount: number;
}): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(amount);
};

export const formatPercentage = ({
  percentage,
  decimalPlaces = 1,
}: {
  percentage: number;
  decimalPlaces?: number;
}): string => `${percentage.toFixed(decimalPlaces)}%`;

export const formatNumberIntoString = ({
  locale = Locale.enUS,
  amount,
}: {
  locale: Locale;
  amount: number;
}): string => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(amount);
};
