import { useMemo } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { GenerativeAITextTemplateInput } from '@attentive/data/types';

import { useIsSelfServeAIProEnabled } from './featureFlags';

export type GenerativeTopicKey = 'MORE_LIKE_THIS' | 'FLASH_SALE' | 'BESTSELLERS' | 'RETARGETING';

const topicDisplayNameMap: { [K in GenerativeTopicKey]: string } = {
  MORE_LIKE_THIS: 'More like this',
  FLASH_SALE: 'Flash sale',
  BESTSELLERS: 'Bestsellers',
  RETARGETING: 'Follow-up message',
};

export function getTopicDisplayName(topic: GenerativeTopicKey): string {
  return topicDisplayNameMap[topic] || '';
}

type GenerativeTopicTemplateMap = {
  [K in GenerativeTopicKey]: GenerativeAITextTemplateInput;
};

//TODO: Deprecate holiday specific templates as they are not defined. However there is currently some dependency in client-ui
export function useGenerativeTopicTemplateMap(): GenerativeTopicTemplateMap {
  const enableBrandVoiceBrandKitUI = useCompanyFeatureFlag('ENABLE_AI_BRAND_VOICE_BRAND_KIT_UI');
  const isAIProBrandVoiceDev = useCompanyFeatureFlag('ENABLE_AI_PRO_BRAND_VOICE_DEV');
  const enableAIProBrandVoiceExp = useCompanyFeatureFlag('ENABLE_BV_AI_PRO_ASSISTANTS_EXPERIMENT');
  const isSelfServeAIProEnabled = useIsSelfServeAIProEnabled();

  return useMemo<GenerativeTopicTemplateMap>(
    () => ({
      // EXAMPLE: (() => {
      //   // If you need logic that can't be resolved through an expression,
      //   // use an IIFE to keep it together
      //   return {
      //     templateName: '',
      //     version: '',
      //   };
      // })(),
      MORE_LIKE_THIS: {
        templateName: 'sms_generate_variant',
        version: '1',
      },
      FLASH_SALE: (() => {
        return isSelfServeAIProEnabled ||
          (enableBrandVoiceBrandKitUI && isAIProBrandVoiceDev) ||
          enableAIProBrandVoiceExp
          ? { templateName: 'sms_flash_sale_bv', version: 'live' }
          : { templateName: 'sms_flash_sale', version: '1' };
      })(),
      BESTSELLERS: (() => {
        return isSelfServeAIProEnabled ||
          (enableBrandVoiceBrandKitUI && isAIProBrandVoiceDev) ||
          enableAIProBrandVoiceExp
          ? { templateName: 'sms_bestseller_bv', version: 'live' }
          : { templateName: 'sms_bestseller', version: '1' };
      })(),
      RETARGETING: {
        templateName: 'sms_retargeting',
        version: '2',
      },
    }),
    [
      isSelfServeAIProEnabled,
      enableBrandVoiceBrandKitUI,
      isAIProBrandVoiceDev,
      enableAIProBrandVoiceExp,
    ]
  );
}
