import _useGenerateTextsFromTemplateGenerateAITextMutation from "./__generated__/useGenerateTextsFromTemplateGenerateAITextMutation.graphql";
import { useMutation } from 'react-relay';
import { useCurrentCompanyId, useCompanyFeatureFlag, Tracker, TrackerEvents } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { sanitizeAiGeneratedCopySet } from '..';
import GenerateAITextMutation from './__generated__/useGenerateTextsFromTemplateGenerateAITextMutation.graphql';
_useGenerateTextsFromTemplateGenerateAITextMutation;

const trackSanitizedTextMessage = function (sanitizedText) {
  let refinementOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let origin = arguments.length > 2 ? arguments[2] : undefined;
  Tracker.track({
    eventName: TrackerEvents.COPY_ASSISTANT_SUGGESTION_SANITIZED_OUT,
    properties: {
      suggestion: sanitizedText.text.text,
      inputPrompt: sanitizedText.prompt,
      reason: sanitizedText.reason,
      refinementOptions: refinementOptions.join(', '),
      origin
    }
  });
};

export function useGenerateTextsFromTemplate(_ref) {
  let {
    origin
  } = _ref;
  const companyId = useCurrentCompanyId();
  const enableBrandVoice = useCompanyFeatureFlag('ENABLE_AI_BRAND_VOICE');
  const [commitGenerateAITexts, isInFlight] = useMutation(GenerateAITextMutation);

  const generateTextsFromTemplate = _ref2 => {
    let {
      input: {
        promptRefinementOptions = [],
        n = 3,
        prompt = '',
        ...restOfInput
      },
      onCompleted,
      onError
    } = _ref2;
    const refinementOptions = enableBrandVoice ? [...promptRefinementOptions, 'PROMPT_REFINEMENT_OPTION_USE_BRAND_VOICE'] : promptRefinementOptions;
    commitGenerateAITexts({
      variables: {
        input: {
          companyId,
          origin,
          n,
          promptRefinementOptions: refinementOptions,
          prompt: [prompt],
          ...restOfInput
        }
      },
      onCompleted: (data, errors) => {
        var _data$generateAIText;

        if (errors) {
          onError(new Error(errors.map(err => err.message).join('. ')));
        } else if ((_data$generateAIText = data.generateAIText) !== null && _data$generateAIText !== void 0 && _data$generateAIText.generatedTextResponses) {
          var _data$generateAIText2;

          const [validResponses, filteredResponses] = sanitizeAiGeneratedCopySet([...((_data$generateAIText2 = data.generateAIText) === null || _data$generateAIText2 === void 0 ? void 0 : _data$generateAIText2.generatedTextResponses)], prompt);
          filteredResponses.forEach(text => trackSanitizedTextMessage(text, refinementOptions, origin));
          onCompleted(validResponses);
        }
      },
      onError
    });
  };

  return [generateTextsFromTemplate, isInFlight];
}