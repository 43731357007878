const containsHashtags = (text = ''): boolean => /\B#\w+/gi.test(text);

enum SanitizationReason {
  HASHTAGS = 'Contains hashtags',
  PROMPT_DUPLICATE = 'Text same as prompt',
  GENERATED_DUPLICATE = 'Text same as another generated text',
}

export type SanitizedText<T> = {
  text: T;
  reason: SanitizationReason;
  prompt: string;
};

/**
 * Returns a tuple of the valid responses and the filtered-out responses
 */
export function sanitizeAiGeneratedCopySet<
  T extends {
    text: string;
  }
>(input: T[], prompt: string): [T[], Array<SanitizedText<T>>] {
  const sanitizedTexts: Array<SanitizedText<T>> = [];

  const validTexts = input
    // filter out hashtags, placeholders, and duplicate response
    .filter((generated: T) => {
      let reason;

      if (containsHashtags(generated.text)) {
        reason = SanitizationReason.HASHTAGS;
      } else if (generated.text === prompt) {
        reason = SanitizationReason.PROMPT_DUPLICATE;
      }

      if (reason) {
        sanitizedTexts.push({
          text: generated,
          reason,
          prompt,
        });
      }

      return !reason;
    })
    // clean up text by removing leading and/or trailing quotes and spaces
    .map((generated: T) => ({
      ...generated,
      text: generated.text.trim().replace(/^[:"']+(\s*)|(\s*)[:"']+$/g, ''),
    }))
    // dedupe by text
    .filter((generated: T, index: number, self: T[]) => {
      const isUnique = self.findIndex((t) => t.text === generated.text) === index;

      if (!isUnique) {
        sanitizedTexts.push({
          text: generated,
          reason: SanitizationReason.GENERATED_DUPLICATE,
          prompt,
        });
      }

      return isUnique;
    });

  return [validTexts, sanitizedTexts];
}
