import React, { useState, useEffect } from 'react';

import { IconButton, Popover } from '@attentive/picnic';

type CopyToClipboardButtonProps = {
  textToCopy: string;
  onCopyText?: (copiedText: string) => void;
} & Partial<React.ComponentProps<typeof IconButton>>;

let clearPopoverTimer: ReturnType<typeof setTimeout>;

export const CopyToClipboardButton = ({
  textToCopy,
  onCopyText,
  ...buttonProps
}: CopyToClipboardButtonProps) => {
  const [showSuccessPopover, setShowSuccessPopover] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(textToCopy);
    onCopyText?.(textToCopy);
    setShowSuccessPopover(true);

    clearPopoverTimer = setTimeout(() => {
      setShowSuccessPopover(false);
    }, 5000);
  };

  useEffect(
    () => () => {
      if (clearPopoverTimer) {
        clearTimeout(clearPopoverTimer);
      }
    },
    []
  );

  return (
    <Popover open={showSuccessPopover} onOpenChange={setShowSuccessPopover}>
      <Popover.Anchor>
        <IconButton
          onClick={copyToClipboard}
          description="Copy to clipboard"
          iconName="Copy"
          variant="subdued"
          {...buttonProps}
        />
      </Popover.Anchor>
      <Popover.Content showCloseButton={false} css={{ width: 'auto' }}>
        Copied!
      </Popover.Content>
    </Popover>
  );
};
