import * as Collapsible from '@radix-ui/react-collapsible';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { upperFirst } from '@attentive/nodash';
import { Box, Form, Button, styled, Icon, useForm, Text } from '@attentive/picnic';

import {
  Filter,
  filterOptions,
  Palette,
  palettes,
  Perspective,
  perspectiveOptions,
  Placement,
  placementOptions,
  ScaleAiApiParameters,
  ScenePreposition,
  scenePrepositions,
  TimeOfDay,
  timeOfDayOptions,
  Vibe,
  vibeOptions,
} from './scaleAiTypes';

export type ScaleAiFormValues = ScaleAiApiParameters;

export const clearModifierOption = 'None' as const;

const clearableFields: Array<Partial<keyof ScaleAiFormValues>> = [
  'timeOfDay',
  'perspective',
  'filter',
  'placement',
  'vibe',
];

export const createFormSchema = ({
  sceneName = 'scene',
}: {
  sceneName?: string;
}): Yup.SchemaOf<ScaleAiFormValues> =>
  Yup.object().shape({
    preposition: Yup.mixed<ScenePreposition>()
      .oneOf([...scenePrepositions])
      .required(),
    scene: Yup.string().required(`${upperFirst(sceneName)} is a required field.`),
    timeOfDay: Yup.mixed<TimeOfDay | typeof clearModifierOption>()
      .oneOf([clearModifierOption, ...timeOfDayOptions])
      .optional(),
    perspective: Yup.mixed<Perspective | typeof clearModifierOption>()
      .oneOf([clearModifierOption, ...perspectiveOptions])
      .optional(),
    filter: Yup.mixed<Filter | typeof clearModifierOption>()
      .oneOf([clearModifierOption, ...filterOptions])
      .optional(),
    placement: Yup.mixed<Placement | typeof clearModifierOption>()
      .oneOf([clearModifierOption, ...placementOptions])
      .optional(),
    vibe: Yup.mixed<Vibe | typeof clearModifierOption>()
      .oneOf([clearModifierOption, ...vibeOptions])
      .optional(),
    palette: Yup.array().of(Yup.mixed<Palette>()).optional(),
  });

const AdvancedOptionFormField = styled(Form.FormField, {
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gap: '$space3',
  alignItems: 'center',
  '& > div': {
    margin: 0,
    '& span[role="label"]': {
      fontWeight: 'normal',
    },
  },
});

export const ScaleAiForm = ({ showRefreshImages = false }) => {
  const { values, setFieldValue } = useForm<ScaleAiFormValues>();
  const [showAdvancedControls, setShowAdvancedControls] = useState(false);

  useEffect(() => {
    if (!showAdvancedControls) return;

    clearableFields.forEach((fieldName) => {
      if (values[fieldName] === clearModifierOption) {
        setFieldValue(fieldName, undefined);
      }
    });
  }, [showAdvancedControls, values, setFieldValue]);

  return (
    <Box>
      <Form.FormField name="preposition">
        <Box css={{ display: 'flex', gap: '$space2', alignItems: 'center', mb: '$space2' }}>
          <Form.Label css={{ whiteSpace: 'nowrap', fontWeight: 'normal' }}>
            I want to see my product
          </Form.Label>
          <Form.Select css={{ maxWidth: '120px' }}>
            {scenePrepositions.map((prep) => (
              <Form.Select.Item key={prep} value={prep}>
                {prep}
              </Form.Select.Item>
            ))}
          </Form.Select>
        </Box>
      </Form.FormField>
      <Form.FormField name="scene">
        <Form.TextInput
          aria-label="Scene description"
          placeholder="Example: a table with holiday decorations"
        ></Form.TextInput>
      </Form.FormField>
      <Collapsible.Root open={showAdvancedControls} onOpenChange={setShowAdvancedControls}>
        <Collapsible.Trigger asChild>
          <Button
            variant="subdued"
            data-heap-tracking="toggle-advanced-controls"
            css={{ marginTop: '$space1' }}
          >
            {showAdvancedControls ? 'Hide advanced controls' : 'Show advanced controls'}
          </Button>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <Box css={{ backgroundColor: '$bgAccent', borderRadius: '$radius2', padding: '$space4' }}>
            <Box css={{ display: 'grid', gap: '$space3' }}>
              <AdvancedOptionFormField name="palette">
                <Form.Label>Palette:</Form.Label>
                <Form.MultiSelect>
                  {palettes.map((opt) => (
                    <Form.Select.Item key={opt} value={opt}>
                      {opt}
                    </Form.Select.Item>
                  ))}
                </Form.MultiSelect>
              </AdvancedOptionFormField>
              <AdvancedOptionFormField name="timeOfDay">
                <Form.Label>Time of day:</Form.Label>
                <Form.Select placeholder="Select">
                  <Form.Select.Item value={clearModifierOption}>
                    <Text as="span" color="subdued">
                      {clearModifierOption}
                    </Text>
                  </Form.Select.Item>
                  {timeOfDayOptions.map((opt) => (
                    <Form.Select.Item key={opt} value={opt}>
                      {opt}
                    </Form.Select.Item>
                  ))}
                </Form.Select>
              </AdvancedOptionFormField>
              <AdvancedOptionFormField name="filter">
                <Form.Label>Filter:</Form.Label>
                <Form.Select placeholder="Select">
                  <Form.Select.Item value={clearModifierOption}>
                    <Text as="span" color="subdued">
                      {clearModifierOption}
                    </Text>
                  </Form.Select.Item>
                  {filterOptions.map((opt) => (
                    <Form.Select.Item key={opt} value={opt}>
                      {opt}
                    </Form.Select.Item>
                  ))}
                </Form.Select>
              </AdvancedOptionFormField>
              <AdvancedOptionFormField name="placement">
                <Form.Label>Placement:</Form.Label>
                <Form.Select placeholder="Select">
                  <Form.Select.Item value={clearModifierOption}>
                    <Text as="span" color="subdued">
                      {clearModifierOption}
                    </Text>
                  </Form.Select.Item>
                  {placementOptions.map((opt) => (
                    <Form.Select.Item key={opt} value={opt}>
                      {opt}
                    </Form.Select.Item>
                  ))}
                </Form.Select>
              </AdvancedOptionFormField>
              <AdvancedOptionFormField name="vibe">
                <Form.Label>Vibe:</Form.Label>
                <Form.Select placeholder="Select">
                  <Form.Select.Item value={clearModifierOption}>
                    <Text as="span" color="subdued">
                      {clearModifierOption}
                    </Text>
                  </Form.Select.Item>
                  {vibeOptions.map((opt) => (
                    <Form.Select.Item key={opt} value={opt}>
                      {opt}
                    </Form.Select.Item>
                  ))}
                </Form.Select>
              </AdvancedOptionFormField>
              <AdvancedOptionFormField name="perspective">
                <Form.Label>Perspective:</Form.Label>
                <Form.Select placeholder="Select">
                  <Form.Select.Item value={clearModifierOption}>
                    <Text as="span" color="subdued">
                      {clearModifierOption}
                    </Text>
                  </Form.Select.Item>
                  {perspectiveOptions.map((opt) => (
                    <Form.Select.Item key={opt} value={opt}>
                      {opt}
                    </Form.Select.Item>
                  ))}
                </Form.Select>
              </AdvancedOptionFormField>
            </Box>
          </Box>
        </Collapsible.Content>
      </Collapsible.Root>

      <Form.SubmitButton
        css={{ marginTop: '$space4' }}
        variant={showRefreshImages ? 'secondary' : 'primary'}
        aria-label={showRefreshImages ? 'Refresh images' : 'Generate images'}
      >
        {showRefreshImages ? (
          <>
            <Icon name="Sync" css={{ marginRight: '$space2' }} mode="decorative" />
            Refresh images
          </>
        ) : (
          'Generate images'
        )}
      </Form.SubmitButton>
    </Box>
  );
};
