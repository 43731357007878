import React from 'react';

import {
  CompanySearchDropdown,
  useCurrentCompanyId,
  useSetCurrentCompanyId,
} from '@attentive/acore-utils';
import { Box, Heading } from '@attentive/picnic';

export const AIJourneysCompanyFilter = () => {
  const currentCompanyId = useCurrentCompanyId();
  const setCurrentCompanyId = useSetCurrentCompanyId();

  const handleSelectCompany = (id: string | null) => {
    if (id) {
      setCurrentCompanyId(id);
    }
  };
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$space2',
        alignItems: 'flex-start',
      }}
    >
      <Heading variant="sm">Switch company</Heading>
      <Box css={{ width: 250 }}>
        <CompanySearchDropdown value={currentCompanyId} onSelectCompany={handleSelectCompany} />
      </Box>
    </Box>
  );
};
