import React from 'react';

import { styled, Text } from '@attentive/picnic';

const Pill = styled('button', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$bgDefault',
  border: '1px solid $bgDecorative3Accent',
  borderRadius: '$radius1',
  cursor: 'pointer',
  padding: '2px 8px',
  defaultTransition: ['border-color', 'background', 'color'],
  '&:hover:not([disabled])': {
    borderColor: '$textHover',
  },
  '&:disabled': {
    background: '$bgActionSecondaryDisabled',
    cursor: 'not-allowed',
    color: '$textDisabled',
    borderColor: '$bgDecorative3Accent',
  },
  variants: {
    isSelected: {
      true: {
        background: '$textHover',
        borderColor: '$textHover',
        color: '$textInverted',
        '&:hover:not([disabled])': {
          backgroundColor: '$textPressed',
          borderColor: '$textPressed',
          color: '$textInverted',
        },
        '&:disabled': {
          background: '$bgDecorative3Accent',
          borderColor: '$bgDecorative3Accent',
          color: '$textInverted',
        },
      },
    },
  },
});

export type PillButtonProps<T extends string> = Omit<
  React.ComponentProps<typeof Pill>,
  'onClick'
> & {
  children: React.ReactNode;
  value: T;
  onClick: (value: T, ev: React.MouseEvent<HTMLButtonElement>) => void;
  isSelected?: boolean;
};

export const PillButton = <T extends string>({
  children,
  value,
  onClick,
  isSelected,
  ...buttonProps
}: PillButtonProps<T>) => (
  <Pill
    onClick={(ev: React.MouseEvent<HTMLButtonElement>) => {
      onClick(value, ev);
    }}
    isSelected={isSelected}
    aria-pressed={isSelected}
    {...buttonProps}
  >
    <Text as="span" variant="micro" css={{ fontWeight: '$bold' }}>
      {children}
    </Text>
  </Pill>
);

PillButton.displayName = 'PillButton';
