/**
 * Simplified fork of the use-deep-compare-effect package: https://github.com/kentcdodds/use-deep-compare-effect/blob/main/src/index.ts
 * This is a function that is used in Creatives with preact, translated into React.
 */

import { useEffect, useRef } from 'react';

import { isEqual } from '@attentive/nodash';

type UseEffectParams = Parameters<typeof useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];

const useDeepCompareMemoize = (value: DependencyList) => {
  const ref = useRef<DependencyList>();
  const signalRef = useRef<number>(0);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return [signalRef.current];
};

export const useDeepCompareEffect = (callback: EffectCallback, dependencies: DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, useDeepCompareMemoize(dependencies));
};
