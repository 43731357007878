import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Heading, Text, styled, Icon, Button } from '@attentive/picnic';

export const filteredJourneysEmptyMessage =
  'Try selecting a different option or create a new journey.';
export const noDefaultWelcomeMessage =
  'Unlock all triggered journeys by creating the default \n welcome journey for new subscribers who just signed up.';
export const filteredAndSearchedJourneysEmptyMessage =
  "Hmm...your search doesn't match any journeys. Try \n searching for a different name or adjust the filters?";

export const PU_MAX_HEIGHT = 96;

const StyledWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$space8 0 $space16',
  width: '100%',
  gridColumn: '1 / -1',
});

interface JourneysTableZeroStateProps {
  areFiltersActive: boolean;
}

export const JourneysTableZeroState: React.FunctionComponent<JourneysTableZeroStateProps> = ({
  areFiltersActive,
}) => {
  const innerContent = areFiltersActive ? (
    <>
      <Heading variant="lg">No journeys found</Heading>
      <Box css={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
        <Text css={{ mt: '$space3' }}>{filteredAndSearchedJourneysEmptyMessage}</Text>
      </Box>
    </>
  ) : (
    <>
      <Heading variant="lg" css={{ mb: '$space1' }}>
        Create your first journey
      </Heading>
      <Box css={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
        <Text>{noDefaultWelcomeMessage}</Text>
      </Box>
      <Box css={{ display: 'flex', margin: '$space6 $space1 0 $space1' }}>
        <Button variant="primary" size="medium" as={RouterLink} to="/journeys">
          <Icon name="PlusSign" size="small" css={{ marginRight: '$space2' }} />
          Create Journey
        </Button>
      </Box>
    </>
  );

  return <StyledWrapper>{innerContent}</StyledWrapper>;
};
