import { ParamInterface } from '../CustomParameters';

export const formatURLTextLink = ({
  customParams,
  utmCampaign,
  utmMedium,
  utmSource,
}: {
  customParams: ParamInterface[];
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}) => {
  const utmParamsObject = {
    ...(utmCampaign && { utm_campaign: utmCampaign }),
    ...(utmMedium && { utm_medium: utmMedium }),
    ...(utmSource && { utm_source: utmSource }),
  };

  const queryParams = Object.assign(
    utmParamsObject,
    customParams.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {})
  );

  return `?${new URLSearchParams(queryParams).toString()}`;
};
