/**
 * @generated SignedSource<<d25d6b85fcd63f964097fb4303db9014>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalStatus = "APPROVAL_STATUS_APPROVAL_REQUESTED" | "APPROVAL_STATUS_APPROVED" | "APPROVAL_STATUS_NEEDS_APPROVAL" | "APPROVAL_STATUS_REJECTED" | "APPROVAL_STATUS_UNKNOWN" | "%future added value";
export type CurrencyCode = "AED" | "AFN" | "ALL" | "AMD" | "ANG" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BOV" | "BRL" | "BSD" | "BTN" | "BWP" | "BYN" | "BZD" | "CAD" | "CDF" | "CHE" | "CHF" | "CHW" | "CLF" | "CLP" | "CNY" | "COP" | "COU" | "CRC" | "CUC" | "CUP" | "CURRENCY_UNKNOWN" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "FJD" | "FKP" | "GBP" | "GEL" | "GHS" | "GIP" | "GMD" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HTG" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KGS" | "KHR" | "KMF" | "KPW" | "KRW" | "KWD" | "KYD" | "KZT" | "LAK" | "LBP" | "LKR" | "LRD" | "LSL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MNT" | "MOP" | "MRU" | "MUR" | "MVR" | "MWK" | "MXN" | "MXV" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PGK" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SBD" | "SCR" | "SDG" | "SEK" | "SGD" | "SHP" | "SLE" | "SLL" | "SOS" | "SRD" | "SSP" | "STN" | "SVC" | "SYP" | "SZL" | "THB" | "TJS" | "TMT" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "USN" | "UYI" | "UYU" | "UYW" | "UZS" | "VED" | "VES" | "VND" | "VUV" | "WST" | "XAF" | "XAG" | "XAU" | "XBA" | "XBB" | "XBC" | "XBD" | "XCD" | "XDR" | "XOF" | "XPD" | "XPF" | "XPT" | "XSU" | "XTS" | "XUA" | "XXX" | "YER" | "ZAR" | "ZMW" | "ZWL" | "%future added value";
export type JourneyRevisionPublishedStatus = "JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE" | "JOURNEY_REVISION_PUBLISHED_STATUS_DRAFT" | "JOURNEY_REVISION_PUBLISHED_STATUS_INACTIVE" | "JOURNEY_REVISION_PUBLISHED_STATUS_SCHEDULED" | "JOURNEY_REVISION_PUBLISHED_STATUS_UNKNOWN" | "%future added value";
export type JourneyTriggerType = "JOURNEY_TRIGGER_TYPE_ADD_TO_CART" | "JOURNEY_TRIGGER_TYPE_BACK_IN_STOCK" | "JOURNEY_TRIGGER_TYPE_CUSTOM_EVENT" | "JOURNEY_TRIGGER_TYPE_INTEGRATION_EVENT" | "JOURNEY_TRIGGER_TYPE_JOINED_A_SEGMENT" | "JOURNEY_TRIGGER_TYPE_LOW_INVENTORY" | "JOURNEY_TRIGGER_TYPE_OPT_IN_BACK_IN_STOCK" | "JOURNEY_TRIGGER_TYPE_ORDER_CREATED" | "JOURNEY_TRIGGER_TYPE_ORDER_FULFILLED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CHARGE_FAILED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CREATED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_SKIPPED" | "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_UPCOMING_CHARGE" | "JOURNEY_TRIGGER_TYPE_PAGE_VIEW" | "JOURNEY_TRIGGER_TYPE_PRICE_DROP" | "JOURNEY_TRIGGER_TYPE_PRODUCT_VIEW" | "JOURNEY_TRIGGER_TYPE_PURCHASE" | "JOURNEY_TRIGGER_TYPE_SIGNED_UP" | "JOURNEY_TRIGGER_TYPE_SIGNED_UP_FOR_EMAIL" | "JOURNEY_TRIGGER_TYPE_TEXTED_A_KEYWORD" | "JOURNEY_TRIGGER_TYPE_UNKNOWN" | "JOURNEY_TRIGGER_TYPE_USER_WIN_BACK" | "%future added value";
export type SubscriptionType = "TYPE_CHECKOUT_ABANDONED" | "TYPE_MARKETING" | "TYPE_TRANSACTIONAL" | "TYPE_UNKNOWN" | "%future added value";
export type AIConnectedJourneysProviderQuery$variables = {
  companyId: string;
  shouldQuery: boolean;
};
export type AIConnectedJourneysProviderQuery$data = {
  readonly company?: {
    readonly addToCartJourneys?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"JourneysTableML_Journey_fragment">;
        };
      }>;
    } | null;
    readonly welcomeJourneys?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"JourneysTableML_Journey_fragment">;
        };
      }>;
    } | null;
  };
};
export type AIConnectedJourneysProviderQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly addToCartJourneys: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly approvalStatus: ApprovalStatus;
          readonly draftRevision: {
            readonly approvalFlowRequired: boolean;
            readonly id: string;
            readonly internalId: number;
            readonly lastUpdatedTime: SerializedDateTime;
            readonly publishedStatus: JourneyRevisionPublishedStatus;
          } | null;
          readonly eligibleSubscriptionTypes: ReadonlyArray<SubscriptionType>;
          readonly id: string;
          readonly internalId: string;
          readonly isActive: boolean;
          readonly journeyTrigger: {
            readonly id: string;
            readonly type: JourneyTriggerType;
          } | null;
          readonly name: string;
          readonly publishedRevision: {
            readonly id: string;
            readonly internalId: number;
            readonly lastUpdatedTime: SerializedDateTime;
          } | null;
          readonly stats: {
            readonly attributedRevenue: {
              readonly amount: string;
              readonly currency: CurrencyCode | null;
            };
            readonly attributedRevenueByCurrency: ReadonlyArray<{
              readonly amount: string;
              readonly currency: CurrencyCode | null;
            }>;
            readonly clicks: number;
            readonly messagesSent: number;
          } | null;
        };
      }>;
    } | null;
    readonly id: string;
    readonly welcomeJourneys: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly approvalStatus: ApprovalStatus;
          readonly draftRevision: {
            readonly approvalFlowRequired: boolean;
            readonly id: string;
            readonly internalId: number;
            readonly lastUpdatedTime: SerializedDateTime;
            readonly publishedStatus: JourneyRevisionPublishedStatus;
          } | null;
          readonly eligibleSubscriptionTypes: ReadonlyArray<SubscriptionType>;
          readonly id: string;
          readonly internalId: string;
          readonly isActive: boolean;
          readonly journeyTrigger: {
            readonly id: string;
            readonly type: JourneyTriggerType;
          } | null;
          readonly name: string;
          readonly publishedRevision: {
            readonly id: string;
            readonly internalId: number;
            readonly lastUpdatedTime: SerializedDateTime;
          } | null;
          readonly stats: {
            readonly attributedRevenue: {
              readonly amount: string;
              readonly currency: CurrencyCode | null;
            };
            readonly attributedRevenueByCurrency: ReadonlyArray<{
              readonly amount: string;
              readonly currency: CurrencyCode | null;
            }>;
            readonly clicks: number;
            readonly messagesSent: number;
          } | null;
        };
      }>;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type AIConnectedJourneysProviderQuery = {
  rawResponse: AIConnectedJourneysProviderQuery$rawResponse;
  response: AIConnectedJourneysProviderQuery$data;
  variables: AIConnectedJourneysProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldQuery"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v3 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "status": "JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE",
      "triggerType": "JOURNEY_TRIGGER_TYPE_ADD_TO_CART"
    }
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JourneyEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Journey",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JourneysTableML_Journey_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "status": "JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE",
      "triggerType": "JOURNEY_TRIGGER_TYPE_SIGNED_UP"
    }
  },
  (v2/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedTime",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JourneyEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Journey",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approvalStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eligibleSubscriptionTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JourneyRevision",
            "kind": "LinkedField",
            "name": "draftRevision",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publishedStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalFlowRequired",
                "storageKey": null
              },
              (v7/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JourneyRevision",
            "kind": "LinkedField",
            "name": "publishedRevision",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v7/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JourneyStats",
            "kind": "LinkedField",
            "name": "stats",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "messagesSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clicks",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "attributedRevenue",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "attributedRevenueByCurrency",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JourneyTrigger",
            "kind": "LinkedField",
            "name": "journeyTrigger",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIConnectedJourneysProviderQuery",
    "selections": [
      {
        "condition": "shouldQuery",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": "company",
              "args": (v1/*: any*/),
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "addToCartJourneys",
                      "args": (v3/*: any*/),
                      "concreteType": "JourneyConnection",
                      "kind": "LinkedField",
                      "name": "journeys",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": "journeys(filter:{\"status\":\"JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE\",\"triggerType\":\"JOURNEY_TRIGGER_TYPE_ADD_TO_CART\"},first:1)"
                    },
                    {
                      "alias": "welcomeJourneys",
                      "args": (v6/*: any*/),
                      "concreteType": "JourneyConnection",
                      "kind": "LinkedField",
                      "name": "journeys",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": "journeys(filter:{\"status\":\"JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE\",\"triggerType\":\"JOURNEY_TRIGGER_TYPE_SIGNED_UP\"},first:1)"
                    }
                  ],
                  "type": "Company",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "company"
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIConnectedJourneysProviderQuery",
    "selections": [
      {
        "condition": "shouldQuery",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "company",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "addToCartJourneys",
                    "args": (v3/*: any*/),
                    "concreteType": "JourneyConnection",
                    "kind": "LinkedField",
                    "name": "journeys",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": "journeys(filter:{\"status\":\"JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE\",\"triggerType\":\"JOURNEY_TRIGGER_TYPE_ADD_TO_CART\"},first:1)"
                  },
                  {
                    "alias": "welcomeJourneys",
                    "args": (v6/*: any*/),
                    "concreteType": "JourneyConnection",
                    "kind": "LinkedField",
                    "name": "journeys",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": "journeys(filter:{\"status\":\"JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE\",\"triggerType\":\"JOURNEY_TRIGGER_TYPE_SIGNED_UP\"},first:1)"
                  }
                ],
                "type": "Company",
                "abstractKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "23dbae2d136c36307428b00dfef7f827",
    "id": null,
    "metadata": {},
    "name": "AIConnectedJourneysProviderQuery",
    "operationKind": "query",
    "text": "query AIConnectedJourneysProviderQuery(\n  $companyId: ID!\n  $shouldQuery: Boolean!\n) {\n  company: node(id: $companyId) @include(if: $shouldQuery) {\n    __typename\n    ... on Company {\n      addToCartJourneys: journeys(filter: {triggerType: JOURNEY_TRIGGER_TYPE_ADD_TO_CART, status: JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE}, first: 1) {\n        edges {\n          node {\n            id\n            ...JourneysTableML_Journey_fragment\n          }\n        }\n      }\n      welcomeJourneys: journeys(filter: {triggerType: JOURNEY_TRIGGER_TYPE_SIGNED_UP, status: JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE}, first: 1) {\n        edges {\n          node {\n            id\n            ...JourneysTableML_Journey_fragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment JourneysTableML_Journey_fragment on Journey {\n  id\n  internalId\n  name\n  isActive\n  approvalStatus\n  eligibleSubscriptionTypes\n  draftRevision {\n    lastUpdatedTime\n    publishedStatus\n    approvalFlowRequired\n    internalId\n    id\n  }\n  publishedRevision {\n    lastUpdatedTime\n    internalId\n    id\n  }\n  stats {\n    messagesSent\n    clicks\n    attributedRevenue {\n      currency\n      amount\n    }\n    attributedRevenueByCurrency {\n      currency\n      amount\n    }\n  }\n  journeyTrigger {\n    type\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "43fbb8b419ce857b0b5c0790a4e8f8ee";

export default node;
