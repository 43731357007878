import { useReducer } from 'react';

import {
  GenerateTextsHookArgs,
  GenerateTextsHookReturnType,
  GenerateTextsFunctionArgs,
} from './types';
import { useGenerateExperimentTexts, ExperimentOptions } from './useGenerateExperimentTexts';

type ExperimentInput = Omit<ExperimentOptions, 'n'>;

// Returns either 1 or 2 to randomize the starting positions
const getInitialGenCounter = () => Math.ceil(Math.random() * 2);

/**
 * Generates an even split of the experiment texts based on the count.
 * Each text generation call will randomize the number and order
 * of the messages created by each template.
 */
export function useGenerateSplitExperimentTexts({
  experimentInputs,
  count,
  ...opts
}: {
  experimentInputs: [ExperimentInput, ExperimentInput];
  count: number;
} & GenerateTextsHookArgs): GenerateTextsHookReturnType {
  const [genCounter, incrementGenCounter] = useReducer((val) => val + 1, getInitialGenCounter());

  const [generateTexts, inFlight] = useGenerateExperimentTexts({
    control: {
      ...experimentInputs[genCounter % 2],
      n: Math.ceil(count / 2),
    },
    experiment: {
      ...experimentInputs[(genCounter + 1) % 2],
      n: Math.floor(count / 2),
    },
    ...opts,
  });

  const wrappedGenerateTexts = (args: GenerateTextsFunctionArgs) => {
    generateTexts({
      ...args,
      onCompleted: (completedArgs) => {
        incrementGenCounter();
        args.onCompleted(completedArgs);
      },
    });
  };

  return [wrappedGenerateTexts, inFlight];
}
