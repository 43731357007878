import React, { useRef, useState, useEffect } from 'react';

import { Box, styled, Text, Button, Icon, LoadingPlaceholder } from '@attentive/picnic';

import { GeneratedTextFeedbackFlag } from '../GeneratedTextFeedback';

import { CopyToClipboardButton } from './CopyToClipboardButton';

const loadingClass = '--is-loading';

const GeneratedTextContainer = styled('div', {
  position: 'relative',
  borderRadius: '$radius2',
  '&:hover': {
    textDecoration: 'none',
    cursor: 'pointer',
    boxShadow: '$shadow3',
    transform: 'translateY(-2px)',
  },
  defaultTransition: ['transform', 'border', 'box-shadow', 'color'],
  '&:focus': {
    boxShadow: '$focus',
  },

  [`&.${loadingClass}`]: {
    pointerEvents: 'none',
  },
});

const Message = styled('button', {
  position: 'relative',
  textAlign: 'left',
  transform: 'translateZ(0)',
  border: '$borderWidths$borderWidth1 solid $bgDecorative3Accent',
  borderRadius: 'inherit',
  overflow: 'hidden',
  backgroundColor: '$bgDefault',
  boxShadow: '$shadow1',
  padding: '$space3 $space3 $space2',
  cursor: 'pointer',
  width: '100%',
  defaultTransition: ['box-shadow'],
  '&:focus': {
    boxShadow: '$focus',
  },
});

const AddMessageButton = styled(Button, {
  // CSS specificity trick to be applied over other button styling
  '&&': {
    textDecoration: 'none',
    minHeight: 0,
    color: '$textSubdued',
  },
  '& svg, & strong': {
    color: 'inherit',
  },
  [`${GeneratedTextContainer}:has(${Message}:hover) &`]: {
    color: '$textHover',
  },
});

const ButtonBar = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$space3 $space3 0',
  position: 'absolute',
  width: '100%',
  bottom: '$sizes$size2',
  zIndex: '$zIndex1',
  pointerEvents: 'none',
  [`${GeneratedTextContainer}:not(.${loadingClass}) & > *`]: {
    pointerEvents: 'auto',
  },
});

const LoadingTextRow = styled(LoadingPlaceholder, {
  height: '$size3',
  width: '100%',
  borderRadius: '$radius1',
});

type GeneratedTextButtonProps = {
  origin: string;
  onClick: () => void;
  generatedText?: {
    text: string;
    id: string;
    groupId: string;
    source: string;
  };
  messageId: string;
  isAdded?: boolean;
  children?: React.ReactNode;
  addButtonText?: string;
  trackingNameSpace?: string;
};

const DefaultActions = ({
  messageId,
  generatedText,
  origin,
}: Pick<GeneratedTextButtonProps, 'messageId' | 'generatedText' | 'origin'>) => (
  <Box css={{ display: 'flex', gap: '$space2' }}>
    <CopyToClipboardButton
      textToCopy={generatedText?.text || ''}
      size="extraSmall"
      css={{ color: '$iconSubdued' }}
      disabled={!generatedText}
    />
    {generatedText ? (
      <GeneratedTextFeedbackFlag
        origin={origin}
        css={{ color: '$iconSubdued' }}
        generatedText={generatedText}
        messageId={messageId}
      />
    ) : (
      <Icon name="Flag" color="disabled" size="extraSmall" />
    )}
  </Box>
);

export const GeneratedTextButton = ({
  generatedText,
  onClick,
  isAdded = false,
  messageId,
  children,
  addButtonText = 'Add message',
  trackingNameSpace,
  origin,
}: GeneratedTextButtonProps) => {
  const buttonBarRef = useRef<HTMLDivElement>(null);
  const [buttonBarHeight, setButtonBarHeight] = useState(buttonBarRef.current?.clientHeight || 0);
  const isLoading = !generatedText;

  useEffect(() => {
    if (buttonBarRef.current) {
      setButtonBarHeight(buttonBarRef.current.clientHeight);
    }
  }, [buttonBarRef]);

  return (
    <GeneratedTextContainer className={isLoading ? loadingClass : undefined}>
      <Message
        aria-label={`Insert text: ${generatedText?.text}`}
        disabled={isLoading}
        onClick={onClick}
        data-ml-common-id={
          trackingNameSpace ? `${trackingNameSpace}-generated-text-button-message-cta` : undefined
        }
      >
        {isLoading ? (
          <Box
            className="generated-text-loading-placeholder"
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$space2',
            }}
          >
            <LoadingTextRow />
            <LoadingTextRow />
            <LoadingTextRow css={{ width: '65%' }} />
          </Box>
        ) : (
          <Text
            className="generated-text-content-text"
            variant="caption"
            css={{ whiteSpace: 'pre-line' }}
          >
            {generatedText.text}
          </Text>
        )}
        <Box css={{ height: buttonBarHeight }} />
      </Message>
      <ButtonBar ref={buttonBarRef}>
        <AddMessageButton
          variant="subdued"
          size="small"
          aria-label={addButtonText}
          onClick={onClick}
          data-ml-common-id={
            trackingNameSpace
              ? `${trackingNameSpace}-generated-text-button-add-message-cta`
              : undefined
          }
          disabled={isLoading}
        >
          <Icon
            name={isAdded ? 'CircleCheckmark' : 'PlusSign'}
            css={{ width: '0.75em', height: '0.75em', marginRight: '$space1' }}
          />
          <Text as="strong" variant="micro" css={{ fontWeight: '$bold' }}>
            {isAdded ? 'Added!' : addButtonText}
          </Text>
        </AddMessageButton>
        {children || (
          <DefaultActions messageId={messageId} generatedText={generatedText} origin={origin} />
        )}
      </ButtonBar>
    </GeneratedTextContainer>
  );
};
