/**
 * @generated SignedSource<<f95d4ae320c98df0f60b4f3f7213d044>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BrandVoicePreviewQuery$variables = {
  companyId: string;
};
export type BrandVoicePreviewQuery$data = {
  readonly company: {
    readonly attentiveDomain?: string;
    readonly bestsellerProducts?: {
      readonly productRecommendations: ReadonlyArray<{
        readonly product: {
          readonly name: string;
        } | null;
      }>;
    } | null;
    readonly displayName?: string;
  };
};
export type BrandVoicePreviewQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly attentiveDomain: string;
    readonly bestsellerProducts: {
      readonly productRecommendations: ReadonlyArray<{
        readonly product: {
          readonly id: string;
          readonly name: string;
        } | null;
      }>;
    } | null;
    readonly displayName: string;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type BrandVoicePreviewQuery = {
  rawResponse: BrandVoicePreviewQuery$rawResponse;
  response: BrandVoicePreviewQuery$data;
  variables: BrandVoicePreviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attentiveDomain",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandVoicePreviewQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductRecommendationsRedactedResponse",
                  "kind": "LinkedField",
                  "name": "bestsellerProducts",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductRecommendationMetaRedacted",
                      "kind": "LinkedField",
                      "name": "productRecommendations",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "product",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Company",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandVoicePreviewQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductRecommendationsRedactedResponse",
                "kind": "LinkedField",
                "name": "bestsellerProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductRecommendationMetaRedacted",
                    "kind": "LinkedField",
                    "name": "productRecommendations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cdb9f641d12e3e10d461cb8af0eea10",
    "id": null,
    "metadata": {},
    "name": "BrandVoicePreviewQuery",
    "operationKind": "query",
    "text": "query BrandVoicePreviewQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      displayName\n      attentiveDomain\n      bestsellerProducts {\n        productRecommendations {\n          product {\n            name\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "479610268d416c977c3291b6b9f31c07";

export default node;
