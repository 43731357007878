import React, { useState } from 'react';

import {
  Box,
  StandardDialog,
  Heading,
  Link,
  Switch,
  TextInput,
  Banner,
  FormField,
  Text,
} from '@attentive/picnic';

import { CustomParameters, ParamInterface } from '../CustomParameters';

import { formatURLTextLink } from './AIJourneySettingsModalUtils';

type GoogleAnalyticsTrackingProps = {
  utmCampaign: string;
  setUtmCampaign: (val: string) => void;
};

export type AIJourneySettingsFormValues = {
  isUtmTrackingEnabled: boolean;
  utmCampaign: string;
  customUrlInputParams: ParamInterface[];
};

type SettingsModalProps = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  initialValues: AIJourneySettingsFormValues;
  onSubmit: (values: AIJourneySettingsFormValues) => void;
};

const GoogleAnalyticsTracking = ({ utmCampaign, setUtmCampaign }: GoogleAnalyticsTrackingProps) => {
  return (
    <Box>
      <FormField>
        <FormField.Label requirement="optional">UTM campaign</FormField.Label>
        <FormField.HelperText>The campaign name, promo code, etc.</FormField.HelperText>
        <TextInput
          size="small"
          value={utmCampaign}
          onChange={(input) => setUtmCampaign(input.target.value)}
        />
      </FormField>
    </Box>
  );
};

export const AIJourneysSettingsModal = ({
  isOpen,
  onOpenChange,
  initialValues,
  onSubmit,
}: SettingsModalProps) => {
  const [values, setValues] = useState(initialValues);
  const { utmCampaign, customUrlInputParams, isUtmTrackingEnabled } = values;
  const showInfoBanner = customUrlInputParams.length > 0 || (utmCampaign && isUtmTrackingEnabled);

  return (
    <StandardDialog open={isOpen} onOpenChange={onOpenChange}>
      <StandardDialog.Content css={{ width: '700px' }}>
        <StandardDialog.Header>
          <StandardDialog.Heading>AI Journey settings</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <Heading variant="subheading" css={{ mb: '$space6' }}>
            SHORTLINK
          </Heading>
          <Box css={{ display: 'flex', gap: '$space2', mb: '$space6' }}>
            <Switch.SwitchItem
              checked={isUtmTrackingEnabled}
              onChange={() =>
                setValues((formValues) => ({
                  ...formValues,
                  isUtmTrackingEnabled: !formValues.isUtmTrackingEnabled,
                }))
              }
              aria-label="Add Google Analytics link tracking"
            />
            <Text css={{ fontWeight: '$bold' }}>Add Google Analytics link tracking</Text>
          </Box>
          {isUtmTrackingEnabled && (
            <GoogleAnalyticsTracking
              utmCampaign={utmCampaign}
              setUtmCampaign={(utmCampaignVal) => {
                setValues((formValues) => ({
                  ...formValues,
                  utmCampaign: utmCampaignVal,
                }));
              }}
            />
          )}
          <Box css={{ mt: '$space6' }}>
            <CustomParameters
              isTrackingEnabled={isUtmTrackingEnabled}
              params={customUrlInputParams}
              onParamsChange={(params) => {
                setValues((formValues) => ({
                  ...formValues,
                  customUrlInputParams: params,
                }));
              }}
            />
          </Box>
          {showInfoBanner && (
            <Banner variant="info" css={{ mt: '$space2' }}>
              <Banner.Text>
                Appended to URL (any special characters will be encoded to ensure that your link is
                valid):{' '}
                <Link css={{ wordBreak: 'break-all' }}>
                  {formatURLTextLink({ customParams: customUrlInputParams, utmCampaign })}
                </Link>
              </Banner.Text>
            </Banner>
          )}
        </StandardDialog.Body>
        <StandardDialog.Footer>
          <StandardDialog.Close variant="secondary">Cancel</StandardDialog.Close>
          <StandardDialog.Close onClick={() => onSubmit(values)}>
            Save Settings
          </StandardDialog.Close>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};
