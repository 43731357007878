import React, { useState, useEffect } from 'react';
import { EffectFade, Autoplay, Pagination, Controller, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import swiperCSS from 'swiper/swiper-bundle.min.css';
import { Swiper as SwiperClass } from 'swiper/types';

import { styled, Text, Icon, Stack } from '@attentive/picnic';

import PhoneImage from '../../assets/phone.svg';

const PhoneWrapper = styled('div', {
  width: '320px',
  height: '500px',
  overflow: 'hidden',
  position: 'relative',
});

const ContentWrapper = styled('div', {
  position: 'absolute',
  left: '$space10',
  top: '124px',
  width: '240px',
  height: '75%',
});

const SlideWrapper = styled('div', {
  height: '85%',
  width: '100%',
  background: 'white',
  overflowY: 'auto',
});

const HeaderWrapper = styled('div', {
  minHeight: '32px',
  width: '320px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'transparent',
});

const EmptyStateWrapper = styled('div', {
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});

const EmptyStateContainer = styled('div', {
  padding: '$space5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '$borderDefault $borderWidths$borderWidth1 solid',
  boxShadow: '$shadow1',
  borderRadius: '$radius2',
});

const SwiperStyleOverrides = styled('div', {
  width: '320px',
  height: 'auto',

  '.swiper-pagination-bullet': {
    width: '$size4',
    height: '$size4',
    background: '$bgTogglePressed',
    textAlign: 'center',
    opacity: 1,
    zIndex: 10,
  },
  '.swiper-pagination-bullet-active': {
    background: '$bgToggleSelected',
  },
  '.swiper': {
    height: '100%',
  },
});

interface PhoneCarouselProps {
  headers?: string[] | React.ReactNode[];
  empty?: boolean;
}

const STYLE_ID = 'swiper-styles__ml-common';

const EmptyState = () => (
  <EmptyStateWrapper>
    <EmptyStateContainer>
      <Stack direction="horizontal" spacing="$space2">
        <Icon name="CircleError" size="medium" color="subdued" />
        <Text>Results pending</Text>
      </Stack>
    </EmptyStateContainer>
  </EmptyStateWrapper>
);

/**
 * Note: Try to make sure headers and children are of the same length,
 * although no ill effects have been found yet if this isn't true
 */
export const PhoneCarousel: React.FunctionComponent<PhoneCarouselProps> = ({
  children,
  empty,
  headers,
}) => {
  const [firstSwiper, setFirstSwiper] = useState<SwiperClass>();
  const [secondSwiper, setSecondSwiper] = useState<SwiperClass>();

  // Add swiper styling on mount
  useEffect(() => {
    if (!document.getElementById(STYLE_ID)) {
      const styleElement = document.createElement('style');
      styleElement.id = STYLE_ID;
      styleElement.textContent = swiperCSS;
      document.head.appendChild(styleElement);
    }

    return () => document.getElementById(STYLE_ID)?.remove();
  }, []);

  return (
    <SwiperStyleOverrides>
      {!empty && (
        <Swiper
          controller={{ control: firstSwiper }}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          modules={[EffectFade, Controller, A11y]}
          onSwiper={setSecondSwiper}
        >
          {headers?.map((header, index) => {
            const slideKey = `header-${index}`;
            return (
              <SwiperSlide key={slideKey}>
                <HeaderWrapper>{header}</HeaderWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <PhoneWrapper>
        <img
          style={{
            height: '648px',
            width: '318px',
          }}
          src={PhoneImage}
          alt="Phone frame"
        />
        <ContentWrapper>
          {empty ? (
            <EmptyState />
          ) : (
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              controller={{ control: secondSwiper }}
              effect="fade"
              fadeEffect={{ crossFade: true }}
              modules={[EffectFade, Autoplay, Pagination, Controller, A11y]}
              onSwiper={setFirstSwiper}
              pagination={{
                clickable: true,
                renderBullet: (_, className) => {
                  return `<button class="${className}"/></button>`;
                },
              }}
            >
              {React.Children.map(children, (child) => (
                <SwiperSlide>
                  <SlideWrapper>{child}</SlideWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </ContentWrapper>
      </PhoneWrapper>
    </SwiperStyleOverrides>
  );
};
