import { getTopicDisplayName } from '../../utils/generativeTopicTemplateMap';

import { TopicalGeneratedText } from './CopyAssistantPlayground';

const fixedEncodeURIComponent = (str: string) =>
  encodeURIComponent(str).replace(/[.!'()*]/g, (char) => `%${char.charCodeAt(0).toString(16)}`);

export function getCreateCampaignLink(
  companyId: string,
  topicalGeneratedText: TopicalGeneratedText
): string {
  const topicName = topicalGeneratedText.topic
    ? getTopicDisplayName(topicalGeneratedText.topic)
    : '';

  const searchParams: Record<string, string> = {
    cid: companyId,
    type: 'text',
    body: topicalGeneratedText.text,
    ccName: topicName,
    cmgName: topicName,
    genTextId: topicalGeneratedText.id,
    genTextGroupId: topicalGeneratedText.groupId,
  };

  const searchString = Object.keys(searchParams).reduce(
    (acc, key) =>
      searchParams[key]
        ? `${acc}${acc.length ? '&' : '?'}${key}=${fixedEncodeURIComponent(searchParams[key])}`
        : acc,
    ''
  );

  return `/campaigns/create${searchString}`;
}
