import React from 'react';

import { Text, Icon, IconName, Stack, styled } from '@attentive/picnic';

interface ExperimentStatusLabelProps {
  label: string;
  subLabel: string;
  iconName?: IconName;
  active?: boolean;
}
export const ExperimentStatusLabel: React.FunctionComponent<ExperimentStatusLabelProps> = ({
  label,
  subLabel,
  active = false,
  iconName = 'Magic',
}) => {
  const LabelWrapper = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    borderRadius: '$radius2',
    padding: '$space2 $space4',
    alignSelf: 'stretch',
    background: '$bgAccent',

    variants: {
      active: {
        true: {
          backgroundImage: '$bgGradientMagic',
          backgroundColor: '$bgGradientMagicFallback',
        },
      },
    },
  });

  return (
    <LabelWrapper active={active}>
      <Stack direction="horizontal" spacing="$space1">
        <Icon name={iconName} size="medium" />
        <Text variant="body" css={{ fontWeight: '$bold', width: '244px' }}>
          {label}
        </Text>
      </Stack>
      <Text variant="body">{subLabel}</Text>
    </LabelWrapper>
  );
};
