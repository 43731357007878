import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Heading, Text } from '@attentive/picnic';

import { AIConnectedJourneysLoader } from '../components/AIConnectedJourneysProvider';
import { AIJourneysCompanyFilter } from '../components/AIJourneysCompanyFilter';

export const AIJourneysIndex = () => {
  return (
    <AIConnectedJourneysLoader>
      <Box css={{ padding: '$space4 $space6', height: '100%' }}>
        <Box
          as="header"
          css={{ display: 'flex', justifyContent: 'space-between', marginBottom: '$space5' }}
        >
          <Box>
            <Heading variant="page" css={{ marginBottom: '$space3' }}>
              AI Journeys
            </Heading>
            <Text>
              Build customer loyalty by sending messages based on customers' browsing or purchase
              behavior.
            </Text>
          </Box>

          <AIJourneysCompanyFilter />
        </Box>
        <Outlet />
      </Box>
    </AIConnectedJourneysLoader>
  );
};
