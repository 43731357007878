import React from 'react';

import { Box, Grid, FormField, Text, Heading, Icon, Button } from '@attentive/picnic';

import { CustomParameterRow } from './CustomParameterRow';
import { hasParamsError } from './customParametersUtils';

const generateId = (() => {
  let counter = 0;
  return () => {
    counter += 1;
    return `id_${counter + 1}`;
  };
})();

export interface ParamInterface {
  key: string;
  value: string;
  id: string;
  keyError: string;
  valueError: string;
}

interface CustomParametersProps {
  isTrackingEnabled: boolean;
  params: ParamInterface[];
  onParamsChange: (params: ParamInterface[]) => void;
  maxNumParams?: number;
  disabled?: boolean;
}

export const CustomParameters = ({
  isTrackingEnabled,
  params,
  onParamsChange,
  maxNumParams = 20,
  disabled,
}: CustomParametersProps) => {
  const hasCustomParamsError = hasParamsError(params);

  const handleChange = (index: number) => (param: ParamInterface) => {
    onParamsChange(
      params.reduce((acc: ParamInterface[], curr, i) => {
        if (i !== index) {
          return [...acc, curr];
        }
        return [...acc, param];
      }, [])
    );
  };

  const handleDelete = (index: number) => () => {
    onParamsChange(
      params.reduce((acc: ParamInterface[], { key, value, ...rest }, i) => {
        if (i !== index) {
          return [...acc, { key, value, ...rest }];
        }
        return acc;
      }, [])
    );
  };

  const handleAdd = () => {
    onParamsChange([
      ...params,
      { key: '', value: '', id: generateId(), keyError: '', valueError: '' },
    ]);
  };

  return (
    <>
      <Box css={{ marginBottom: '$space2' }}>
        <FormField.Label requirement="optional">Add custom URL parameters</FormField.Label>
        <Text variant="caption" color="subdued">
          Parameters will be appended to link or after Google analytics parameters if enabled.
        </Text>
      </Box>
      {params.length > 0 && (
        <Grid columns={20} css={{ columnGap: '$space2' }}>
          <Grid.Cell colSpan={9}>
            <Heading variant="sm">Name</Heading>
          </Grid.Cell>
          <Grid.Cell colSpan={9}>
            <Heading variant="sm">Value</Heading>
          </Grid.Cell>
        </Grid>
      )}
      {params.map((param, index) => (
        <CustomParameterRow
          key={param.id}
          param={param}
          isTrackingEnabled={isTrackingEnabled}
          onChange={handleChange(index)}
          onDelete={handleDelete(index)}
          disabled={disabled}
        />
      ))}
      <Button
        variant="subdued"
        disabled={params.length > maxNumParams || hasCustomParamsError || disabled}
        onClick={handleAdd}
      >
        <Icon name="PlusSign" size="extraSmall" css={{ marginRight: '$space2' }} /> Add parameter
      </Button>
    </>
  );
};
