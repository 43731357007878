import React from 'react';

import { deserializeGQLDateTime } from '@attentive/data';
import { Text, styled, Card } from '@attentive/picnic';

import { useMessageSpotlightContext } from './MessageSpotlightLoader';
import { PhoneCarousel } from './PhoneCarousel';
import { TextMessagePreview } from './TextMessagePreview';

const SpotlightCarouselContainer = styled(Card, {
  width: '100%',
  background: '$bgDecorative3Default',
  padding: 0,
  position: 'relative',
});

const PhoneContainer = styled('div', {
  margin: '0 25%',
});

const PaginationContainer = styled('div', {
  position: 'absolute',
  bottom: 0,
  borderTop: '$borderWidths$borderWidth1 solid $borderDefault',
  height: '$size12',
  width: '100%',
  background: '$bgDefault',
});

const MessageSentHeader: React.FunctionComponent<{ timestamp: SerializedDateTime }> = ({
  timestamp,
}) => {
  const dateSent = new Date(deserializeGQLDateTime(timestamp)).toLocaleDateString();
  const timeSent = new Date(deserializeGQLDateTime(timestamp)).toLocaleTimeString();

  return (
    <Text css={{ marginTop: '$space4' }} variant="caption">
      <strong>Sent: </strong>
      {dateSent} at {timeSent}
    </Text>
  );
};
export const SpotlightCarousel: React.FunctionComponent<{ messageCount: number }> = ({
  messageCount,
}) => {
  const messages = useMessageSpotlightContext();
  //TODO: Once retrieving messages that lead to conversions is figured out, revert isPendingResults to be the commented out condition.
  const isPendingResults = /* messages?.length < messageCount */ !!messageCount;
  return (
    <SpotlightCarouselContainer>
      <PhoneContainer>
        <PhoneCarousel
          empty={isPendingResults}
          headers={messages?.map((message) => (
            <MessageSentHeader key={message.id} timestamp={message.created} />
          ))}
        >
          {messages?.map((message) => (
            <TextMessagePreview
              body={message.body}
              key={message.id}
              mediaUrl={message.mediaUrls[0] ?? undefined}
              disableLinks
            />
          ))}
        </PhoneCarousel>
      </PhoneContainer>
      {!isPendingResults && <PaginationContainer />}
    </SpotlightCarouselContainer>
  );
};
