import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { ContainedLabel, Link, Text, Box, Button } from '@attentive/picnic';

import { formatDateFromUnixTimestamp } from '../../utils/formatDates';

import { AIJourneysTableRowFragment_autoJourney$data as AutoJourney } from './__generated__/AIJourneysTableRowFragment_autoJourney.graphql';

const EMPTY_PLACEHOLDER = '-';

type CellType =
  | 'LAST_EDITED'
  | 'REVENUE'
  | 'CLICK_THROUGH_RATE'
  | 'STATUS'
  | 'MESSAGES_SENT'
  | 'NAME'
  | 'ACTIONS';

const getJourneyStatus = (autoJourney: AutoJourney): 'ACTIVE' | 'TEST' | 'PAUSED' | null => {
  if (autoJourney.status === 'AUTO_JOURNEY_STATUS_PAUSED') return 'PAUSED';
  if (
    autoJourney.status === 'AUTO_JOURNEY_STATUS_ACTIVE' &&
    autoJourney.config.trafficPercentage === 100
  )
    return 'ACTIVE';
  if (autoJourney.status === 'AUTO_JOURNEY_STATUS_ACTIVE' && autoJourney.config.trafficPercentage)
    return 'TEST';
  return null;
};

const StatusCell = ({
  status,
  trafficPercentage,
}: {
  status: 'ACTIVE' | 'TEST' | 'PAUSED' | null;
  trafficPercentage?: number | null;
}) => {
  if (!status) return <Text variant="caption">{EMPTY_PLACEHOLDER}</Text>;
  if (status === 'PAUSED') {
    return <ContainedLabel variant="neutral">Paused</ContainedLabel>;
  }

  return (
    <Box>
      <ContainedLabel variant="magic">
        <ContainedLabel.Icon name="Magic" css={{ marginRight: '$space1', whiteSpace: 'nowrap' }} />
        {status === 'TEST' ? 'Active test' : 'Active'}
      </ContainedLabel>
      {status === 'TEST' && trafficPercentage && (
        <Text variant="micro" css={{ marginTop: '$space2' }}>
          {trafficPercentage}% of traffic
        </Text>
      )}
    </Box>
  );
};

const getAIJourneyLink = (aiJourney: AutoJourney) => `../ai/${aiJourney.id}`;

const getLastEditedDate = (aiJourney: AutoJourney) =>
  aiJourney.updated ? formatDateFromUnixTimestamp(aiJourney.updated) : EMPTY_PLACEHOLDER;
const getRevenue = () => EMPTY_PLACEHOLDER;
const getClickThroughRate = () => EMPTY_PLACEHOLDER;
const getMessagesSent = () => EMPTY_PLACEHOLDER;

export const useFormatCell = () => {
  const isShowStatsEnabled = useCompanyFeatureFlag('ENABLE_SHOW_AUTO_JOURNEY_STATS');

  return ({
    aiJourney,
    format,
  }: {
    aiJourney: AutoJourney | undefined;
    format: CellType;
  }): React.ReactNode => {
    if (!aiJourney) return EMPTY_PLACEHOLDER;

    switch (format) {
      case 'REVENUE':
        return (
          <Text variant="caption" as="div">
            {isShowStatsEnabled ? getRevenue() : 'Results are pending'}
          </Text>
        );
      case 'CLICK_THROUGH_RATE':
        return (
          <Text variant="caption">
            {isShowStatsEnabled ? getClickThroughRate() : EMPTY_PLACEHOLDER}
          </Text>
        );
      case 'MESSAGES_SENT':
        return (
          <Text variant="caption">
            {isShowStatsEnabled ? getMessagesSent() : EMPTY_PLACEHOLDER}
          </Text>
        );
      case 'NAME':
        return (
          <Text variant="caption">
            <Link as={RouterLink} to={getAIJourneyLink(aiJourney)}>
              <strong>{aiJourney.name}</strong>
            </Link>
          </Text>
        );
      case 'LAST_EDITED':
        return <Text variant="caption">{getLastEditedDate(aiJourney)}</Text>;
      case 'STATUS':
        return (
          <StatusCell
            status={getJourneyStatus(aiJourney)}
            trafficPercentage={aiJourney.config.trafficPercentage}
          />
        );
      case 'ACTIONS':
        return (
          <Button as={RouterLink} variant="secondary" size="small" to={getAIJourneyLink(aiJourney)}>
            View performance
          </Button>
        );
      default:
        return EMPTY_PLACEHOLDER;
    }
  };
};
