export const scenePrepositions = ['on', 'in', 'among'] as const;
export type ScenePreposition = (typeof scenePrepositions)[number];

export const timeOfDayOptions = ['Sunrise', 'Noon', 'Afternoon', 'Sunset', 'Night'] as const;
export type TimeOfDay = (typeof timeOfDayOptions)[number];

export const perspectiveOptions = ['Close up', 'Wide angle', 'Top down', 'Straight on'] as const;
export type Perspective = (typeof perspectiveOptions)[number];

export const filterOptions = [
  'Natural light',
  'Front lit',
  'Back lit',
  'Top lit',
  'Shallow depth of field',
  'Deep depth of field',
  'Tilt shift',
  'HDR',
  'Long Exposure',
  'Macro lens',
  'Light leak',
  'Polaroid',
] as const;
export type Filter = (typeof filterOptions)[number];

export const placementOptions = ['Outdoors', 'Indoors'] as const;
export type Placement = (typeof placementOptions)[number];

export const vibeOptions = [
  'Moody',
  'Retro',
  'Cheerful',
  'Foggy',
  'Warm',
  'Cool',
  'Dramatic',
] as const;
export type Vibe = (typeof vibeOptions)[number];

export const palettes = [
  'Tan',
  'Yellow',
  'Gold',
  'Orange',
  'Red',
  'Maroon',
  'Pink',
  'Violet',
  'Indigo',
  'Green',
  'Skyblue',
  'Blue',
  'White',
  'Silver',
  'Gray',
  'Offwhite',
  'Black',
] as const;
export type Palette = (typeof palettes)[number];

// TODO: See if these types can be generated through OpenApi

export type ScaleAiApiParameters = {
  preposition: ScenePreposition;
  scene: string;
  timeOfDay?: TimeOfDay;
  perspective?: Perspective;
  filter?: Filter;
  placement?: Placement;
  vibe?: Vibe;
  palette?: Palette[];
};

export type ModifierTitle = Exclude<keyof ScaleAiApiParameters, 'scene' | 'preposition'>;

export const modifierTitles: Readonly<{ [k in ModifierTitle]: string }> = {
  filter: 'Filters',
  perspective: 'Perspective',
  placement: 'Placement',
  palette: 'Palette',
  vibe: 'Vibe',
  timeOfDay: 'Time of day',
};
