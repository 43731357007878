/**
 * These functions serve as an interface with sessionStorage to help with analtyics for ML/AI/Automation
 * Functions here should be used exclusively for analtyics and not for user-facing functionality
 */
export const mlAnalyticsStorageKey = 'ML_ANALYTICS_STORAGE_KEY';

type MlAnalyticsData = {
  /**
   * The `hasGeneratedText` variable represents if a given session/UX has utilized generated text
   */
  hasGeneratedText?: boolean;
  /**
   * The `generatedTextSource` variable represents where a given session/UX has created the utilized generated text
   */
  generatedTextSource?: string;
};

type MlAnalyticsSessionStorage = {
  get: <T extends keyof MlAnalyticsData>(key: T) => MlAnalyticsData[T] | null;
  set: <T extends keyof MlAnalyticsData>(key: T, value: MlAnalyticsData[T]) => void;
  setMultiple: (values: Partial<MlAnalyticsData>) => void;
  unset: (key: keyof MlAnalyticsData) => void;
  unsetAll: () => void;
};

export const mlAnalyticsSessionStorage: MlAnalyticsSessionStorage = {
  get(key) {
    const storedData = sessionStorage.getItem(mlAnalyticsStorageKey);

    /* Nothing stored so we return null */
    if (!storedData) {
      return null;
    }

    try {
      const parsedData = JSON.parse(storedData);
      return typeof parsedData[key] === 'undefined' ? null : parsedData[key];
    } catch (e) {
      return null;
    }
  },
  set(key, value) {
    const storedData = sessionStorage.getItem(mlAnalyticsStorageKey);

    /* Nothing stored so we stringify the whole payload */
    if (!storedData) {
      return sessionStorage.setItem(mlAnalyticsStorageKey, JSON.stringify({ [key]: value }));
    }

    try {
      const parsedData = JSON.parse(storedData);
      parsedData[key] = value;
      return sessionStorage.setItem(mlAnalyticsStorageKey, JSON.stringify(parsedData));
    } catch (e) {
      return null;
    }
  },
  setMultiple(values) {
    const storedData = sessionStorage.getItem(mlAnalyticsStorageKey);

    /* Nothing stored so we stringify the whole payload */
    if (!storedData) {
      return sessionStorage.setItem(mlAnalyticsStorageKey, JSON.stringify(values));
    }

    try {
      const parsedData = JSON.parse(storedData);
      return sessionStorage.setItem(
        mlAnalyticsStorageKey,
        JSON.stringify({
          ...parsedData,
          ...values,
        })
      );
    } catch (e) {
      return null;
    }
  },
  unset(key) {
    const storedData = sessionStorage.getItem(mlAnalyticsStorageKey);

    /* Nothing stored so we return early */
    if (!storedData) {
      return;
    }

    try {
      const parsedData = JSON.parse(storedData);
      return sessionStorage.setItem(
        mlAnalyticsStorageKey,
        JSON.stringify({ ...parsedData, [key]: undefined })
      );
    } catch (e) {
      return;
    }
  },
  unsetAll() {
    sessionStorage.removeItem(mlAnalyticsStorageKey);
  },
};
