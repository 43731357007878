import { ParamInterface } from './CustomParameters';

export enum UTMTypes {
  SOURCE = 'utm_source',
  MEDIUM = 'utm_medium',
  CAMPAIGN = 'utm_campaign',
}

export const replaceSpaceWithDash = (string: string) => string.replace(/\s+/g, '-');

export const hasParamsError = (params: ParamInterface[]) =>
  params.reduce((acc: boolean, { key, keyError, value, valueError }) => {
    return acc || !!keyError || !!valueError || key.length < 1 || value.length < 1;
  }, false);
