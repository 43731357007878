import React from 'react';

import {
  StandardDialog,
  Icon,
  IconCircle,
  Box,
  ButtonBar,
  Text,
  Link,
  PicnicCss,
} from '@attentive/picnic';

interface TestSetupDialogProps {
  open: boolean;
  openOnChange: (isOpen: boolean) => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

const customHeader: PicnicCss = {
  borderBottomWidth: '$borderWidth1',
  borderBottomStyle: 'solid',
  borderBottomColor: '$borderDefault',
};

const customBox: PicnicCss = {
  '&:not(.box-parent, .box-experiment)': {
    display: 'inline-block',
    p: '$space3',
    backgroundColor: '$bgDecorative3Accent',
    borderRadius: '$radius1',
    margin: '$space0 $space5',
    position: 'relative',
  },

  '&.box-parent': {
    padding: '6px 0 $space5',
    margin: '$space5 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  '&.box-experiment': {
    flexShrink: '1',
    flexBasis: '240px',

    '&:first-child': {
      margin: '$space0 10px $space0 $space5',
      position: 'relative',

      '&::before': {
        content: '',
        width: 'calc(100% + $size5)',
        height: 'calc(100% + 26px)',
        borderWidth: '$borderWidth1',
        borderStyle: 'solid',
        borderColor: '$grayscale1000',
        position: 'absolute',
        top: '-6px',
        left: '50%',
        margin: 'auto',
      },
    },

    '&:last-child': {
      margin: '$space0 $space5 $space0 10px',
    },
  },

  '&.box-detail': {
    display: 'block',
    margin: '$space0',
  },

  '&.box-ai': {
    backgroundImage: '$bgGradientMagic',
    backgroundColor: '$bgGradientMagicFallback',
  },

  '&.connect-bottom': {
    position: 'relative',

    '&::after': {
      content: '',
      width: '1px',
      height: '$size5',
      backgroundColor: '$grayscale1000',
      position: 'absolute',
      bottom: '-$space5',
      left: '0',
      right: '0',
      margin: 'auto',
    },
  },
};

const customIcon: PicnicCss = {
  verticalAlign: 'bottom',
  marginRight: '$space1',
};

const customIconCircle: PicnicCss = {
  margin: '0 auto $space3',
  position: 'relative',

  '&::before': {
    content: '',
    width: '1px',
    height: 'calc(100% + $size3)',
    backgroundColor: '$grayscale1000',
    position: 'absolute',
    top: '-6px',
    left: '0',
    right: '0',
    margin: 'auto',
    zIndex: '-1',
  },
};

const customFooter: PicnicCss = {
  borderTopWidth: '$borderWidth1',
  borderTopStyle: 'solid',
  borderTopColor: '$borderDefault',
  p: '$space6',
};

const customButtonBar: PicnicCss = {
  justifyContent: 'space-between',

  '@bp3': {
    '> div': {
      width: '100%',
    },

    button: {
      '&:first-child': {
        justifySelf: 'start',
      },
      '&:last-child': {
        justifySelf: 'end',
      },
    },
  },
};

export const TestSetupDialog: React.FunctionComponent<TestSetupDialogProps> = ({
  open,
  openOnChange,
  onConfirm,
  isLoading = false,
}) => {
  return (
    <StandardDialog open={open} onOpenChange={openOnChange}>
      <StandardDialog.Content>
        <StandardDialog.Header css={customHeader}>
          <StandardDialog.Heading>How the test works</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body css={{ p: '$space0' }}>
          <Box
            css={{
              textAlign: 'center',
              backgroundColor: '$bgDecorative3Default',
              padding: '$space5 0 $space6',
            }}
          >
            <Box className="connect-bottom" css={customBox}>
              <Text variant="caption" css={{ fontWeight: '$bold' }}>
                <Icon name="TestTube" size="small" css={customIcon} />
                Experiment begins
              </Text>
            </Box>
            <Box className="box-parent connect-bottom" css={customBox}>
              <Box className="box-experiment" css={customBox}>
                <IconCircle iconName="Magic" size="medium" color="magic" css={customIconCircle} />
                <Box className="box-detail box-ai" css={customBox}>
                  <Text variant="caption" css={{ fontWeight: '$bold' }}>
                    AI journey
                  </Text>
                  <Text variant="caption">50% of your traffic</Text>
                </Box>
              </Box>
              <Box className="box-experiment" css={customBox}>
                <IconCircle iconName="Workflow" size="medium" css={customIconCircle} />
                <Box className="box-detail" css={customBox}>
                  <Text variant="caption" css={{ fontWeight: '$bold' }}>
                    Your existing journey
                  </Text>
                  <Text variant="caption">50% of your traffic</Text>
                </Box>
              </Box>
            </Box>
            <Box css={customBox}>
              <Text variant="caption" css={{ fontWeight: '$bold' }}>
                <Icon name="EndJourney" size="small" css={customIcon} />
                Choose your path
              </Text>
              <Text variant="micro">
                When you're ready to make a decision, you can choose to send up to 100% of your
                traffic to your AI journey or revert back to your standard journey.
              </Text>
            </Box>
          </Box>
          <Text css={{ p: '$space6' }}>
            You will be able to monitor your results throughout the test and turn it off at any
            time. Learn more about how the test works{' '}
            <Link
              href="https://help.attentivemobile.com/hc/en-us/articles/18592457424916"
              target="_blank"
            >
              here
            </Link>
            .
          </Text>
        </StandardDialog.Body>
        <StandardDialog.Footer css={customFooter}>
          <ButtonBar css={customButtonBar}>
            <StandardDialog.Close variant="subdued">Cancel</StandardDialog.Close>
            <StandardDialog.Close variant="primary" onClick={onConfirm} loading={isLoading}>
              Start test now
            </StandardDialog.Close>
          </ButtonBar>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};
