import React, { useEffect, useState, useCallback } from 'react';

import { useDebouncedValue, useCompanyFeatureFlag } from '@attentive/acore-utils';
import { PromptRefinementOption } from '@attentive/data/types';
import { Box, Link, Text } from '@attentive/picnic';

import { PillButtonGroup } from '..';
import { logger } from '../../logger';
import { useIsSelfServeAIProEnabled } from '../../utils/featureFlags';
import {
  useSaveGeneratedTextAndFeedback,
  getLoggingMetadataFromGeneratedTextMetadata,
} from '../../utils/generatedTextFeedback';
import {
  useGenerativeTopicTemplateMap,
  GenerativeTopicKey,
} from '../../utils/generativeTopicTemplateMap';
import {
  useGenerateTextsFromTemplate,
  GeneratedText,
  GenerateTextsFunctionInput,
  brandVoiceVendorOptions,
  useGenerateExperimentTexts,
} from '../../utils/useGenerateTextsFromTemplate';
import { usePrevious } from '../../utils/usePrevious';
import { GeneratedTextButton } from '../GeneratedTextButton';
import { GeneratedTextFeedbackStateProvider } from '../GeneratedTextFeedback';

import { CopyAssistantAccordion } from './CopyAssistantAccordion';
import {
  DisabledCopyAssistant,
  ErrorState,
  GetStartedState,
  EmptyState,
} from './CopyAssistantStates';

enum CopyAssistantState {
  ERROR,
  GET_STARTED,
  NO_RESULTS,
  RESULTS,
}

type VisibleRefinementOption = Extract<
  PromptRefinementOption,
  | 'PROMPT_REFINEMENT_OPTION_MAKE_SHORTER'
  | 'PROMPT_REFINEMENT_OPTION_ADD_URGENCY'
  | 'PROMPT_REFINEMENT_OPTION_MAKE_CASUAL'
  | 'PROMPT_REFINEMENT_OPTION_MAKE_FUNNY'
  | 'PROMPT_REFINEMENT_OPTION_ADD_EMOJIS'
>;

const refinementOptionMap: Record<VisibleRefinementOption, string> = {
  PROMPT_REFINEMENT_OPTION_MAKE_SHORTER: 'Make shorter',
  PROMPT_REFINEMENT_OPTION_ADD_URGENCY: 'Add urgency',
  PROMPT_REFINEMENT_OPTION_MAKE_CASUAL: 'Make casual',
  PROMPT_REFINEMENT_OPTION_MAKE_FUNNY: 'Make funny',
  PROMPT_REFINEMENT_OPTION_ADD_EMOJIS: 'Add emojis',
};

type CopyAssistantProps = {
  inputText: string;
  onInsertMessage: (message: { text: string }) => void;
  messageId: string;
  origin: Parameters<typeof useGenerateTextsFromTemplate>[0]['origin'];
  maxDisplayCount?: number;
  suggestionActions?: (props: GeneratedText, genTextPosition: number) => React.ReactElement;
  trackingNamespace?: string;
  starterTopics: GenerativeTopicKey[];
  messageType?: string;
} & Pick<React.ComponentProps<typeof CopyAssistantAccordion>, 'open' | 'onOpenChange'>;

const LoadingCard = ({ messageId, hideCard = false }: { messageId: string; hideCard?: boolean }) =>
  !hideCard ? <GeneratedTextButton messageId={messageId} onClick={() => {}} origin="" /> : null;

type GenerateTextModelInput = Pick<GenerateTextsFunctionInput, 'template' | 'vendorOptions'>;

const defaultTemplateInput: GenerateTextModelInput = {
  template: {
    templateName: 'sms_inline',
    version: 'live',
  },
  vendorOptions: undefined,
};
const defaultTemplateBVInput: GenerateTextModelInput = {
  template: {
    templateName: 'sms_inline_bv',
    version: 'live',
  },
  vendorOptions: brandVoiceVendorOptions,
};

const CopyAssistantComponent = ({
  open,
  onOpenChange,
  inputText,
  onInsertMessage,
  messageId,
  maxDisplayCount = 3,
  suggestionActions,
  origin,
  trackingNamespace = 'inline-ai-ca',
  starterTopics,
  messageType,
}: CopyAssistantProps) => {
  const debouncedInputText = useDebouncedValue(inputText, 700);
  const [generateTexts, isLoadingGen] = useGenerateTextsFromTemplate({ origin });

  const isAIProBrandVoiceDev = useCompanyFeatureFlag('ENABLE_AI_PRO_BRAND_VOICE_DEV');
  const enableContentModeration = useCompanyFeatureFlag('ENABLE_MESSAGE_CONTENT_MODERATION');
  const isMessageContentModerationEnabled = enableContentModeration && isAIProBrandVoiceDev;
  const isSelfServeAIProEnabled = useIsSelfServeAIProEnabled();
  // Only enable content moderation if the FF's are true and it's the experiment
  const [generateExpTexts, isLoadingExp] = useGenerateExperimentTexts({
    control: {
      ...defaultTemplateInput,
      n: 2,
    },
    experiment: {
      ...defaultTemplateBVInput,
      n: 1,
      useMessageEvaluation: isMessageContentModerationEnabled || isSelfServeAIProEnabled,
    },
    origin,
  });

  const isLoading = isLoadingGen || isLoadingExp;
  const topicTemplateMap = useGenerativeTopicTemplateMap();
  const [generatedTexts, setGeneratedTexts] = useState<GeneratedText[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const saveTextAndFeedback = useSaveGeneratedTextAndFeedback();
  const hasUnifiedAIJourneys = useCompanyFeatureFlag('ENABLE_UNIFIED_AUTO_JOURNEY');
  const enableAIProBrandVoiceExp = useCompanyFeatureFlag('ENABLE_BV_AI_PRO_ASSISTANTS_EXPERIMENT');
  const enableBrandVoiceBrandKitUI = useCompanyFeatureFlag('ENABLE_AI_BRAND_VOICE_BRAND_KIT_UI');
  const hasBrandVoiceAccess =
    (messageType === 'COMPOSE_MESSAGE_TYPE_JOURNEY'
      ? enableBrandVoiceBrandKitUI || hasUnifiedAIJourneys
      : enableBrandVoiceBrandKitUI) && isAIProBrandVoiceDev;
  const [lastAddedTextId, setLastAddedTextId] = useState<string | null>(null);
  const previousInputText = usePrevious(debouncedInputText);
  const [refinementOptions, setRefinementOptions] = useState<PromptRefinementOption[]>([]);
  const copyAssistantStateValue: CopyAssistantState = (() => {
    if (error) return CopyAssistantState.ERROR;
    if (isLoading || generatedTexts.length) return CopyAssistantState.RESULTS;
    return debouncedInputText.length
      ? CopyAssistantState.NO_RESULTS
      : CopyAssistantState.GET_STARTED;
  })();

  const handleError = useCallback((err: Error) => {
    logger.logError(err);
    setError(err);
  }, []);

  const generateTextsFromTopics = useCallback(
    (topics: GenerativeTopicKey[], options: PromptRefinementOption[] = []) => {
      setError(null);
      setGeneratedTexts([]);

      let topicsToGenerate = [...topics];

      // If topics length doesn't match maxDisplayCount, update to match
      while (topicsToGenerate.length !== maxDisplayCount) {
        if (topicsToGenerate.length < maxDisplayCount) {
          topicsToGenerate.push(...topicsToGenerate);
        }
        if (topicsToGenerate.length > maxDisplayCount) {
          topicsToGenerate = topicsToGenerate.slice(0, maxDisplayCount);
        }
      }

      topicsToGenerate.forEach((topicKey) => {
        generateTexts({
          input: {
            n: 2,
            template: topicTemplateMap[topicKey],
            promptRefinementOptions: options,
            useMessageEvaluation: isMessageContentModerationEnabled || isSelfServeAIProEnabled,
            vendorOptions:
              isSelfServeAIProEnabled || hasBrandVoiceAccess || enableAIProBrandVoiceExp
                ? brandVoiceVendorOptions
                : undefined,
          },
          onCompleted: (data) => {
            if (data.length) {
              setGeneratedTexts((texts) => [
                ...texts,
                {
                  ...data[0],
                  topic: topicKey,
                },
              ]);
            }
          },
          onError: handleError,
        });
      });
    },
    [handleError, topicTemplateMap, hasBrandVoiceAccess] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const generateTextsFromPrompt = useCallback(
    (prompt: string, options: PromptRefinementOption[] = []) => {
      setError(null);
      setGeneratedTexts([]);

      if (enableAIProBrandVoiceExp) {
        generateExpTexts({
          input: {
            prompt,
            promptRefinementOptions: options,
          },
          onCompleted: (data) => {
            if (data.length) setGeneratedTexts(data);
          },
          onError: handleError,
        });
      } else {
        const generateOptions: Omit<GenerateTextsFunctionInput, 'prompt' | 'promptRefinement'> =
          isSelfServeAIProEnabled || hasBrandVoiceAccess
            ? {
                n: 1,
                ...defaultTemplateBVInput,
                useMessageEvaluation: isMessageContentModerationEnabled || isSelfServeAIProEnabled,
              }
            : {
                n: 1,
                ...defaultTemplateInput,
              };

        //Longest time to generate text previously (out of 10 attempts) was 4.85s
        // New method, longest time to generate text was 3.5s
        for (let i = 0; i < maxDisplayCount; i++) {
          generateTexts({
            input: {
              prompt,
              promptRefinementOptions: options,
              ...generateOptions,
            },
            onCompleted: (data) => {
              if (data.length) {
                setGeneratedTexts((texts) => [
                  ...texts,
                  {
                    ...data[0],
                  },
                ]);
              }
            },
            onError: handleError,
          });
        }
      }
    },
    [handleError, maxDisplayCount, isSelfServeAIProEnabled, hasBrandVoiceAccess] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const regenerateTexts = (options: PromptRefinementOption[] = []) => {
    if (inputText) {
      generateTextsFromPrompt(inputText, options);
    } else {
      generateTextsFromTopics(starterTopics, options);
    }
  };

  const handleChangeRefinementOptions = (options: PromptRefinementOption[]) => {
    setRefinementOptions(options);
    regenerateTexts(options);
  };

  useEffect(() => {
    if (!open) return;

    if (
      lastAddedTextId &&
      generatedTexts.find((genText) => genText.id === lastAddedTextId)?.text === inputText
    ) {
      // If user has selected a generated message, don't make suggestions until they change it
      return;
    }

    // If no user input but there are starterPrompts, get results for them
    if (!debouncedInputText.length) {
      generateTextsFromTopics(starterTopics, refinementOptions);
      return;
    }

    // Generate texts as the inputText changes
    if (debouncedInputText !== previousInputText || !generatedTexts.length) {
      generateTextsFromPrompt(debouncedInputText, refinementOptions);
    }
  }, [debouncedInputText, lastAddedTextId, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInsertMessage = (text: GeneratedText) => {
    const losers = generatedTexts.filter((genText) => genText.id !== text.id);

    onInsertMessage(text);
    setLastAddedTextId(text.id);
    saveTextAndFeedback(
      {
        generatedText: text.text,
        groupId: text.groupId,
        source: text.source,
        generatedTextInputMetadata: text.aiGeneratedTextMetadata
          ? getLoggingMetadataFromGeneratedTextMetadata([...text.aiGeneratedTextMetadata])
          : { origin },
        feedbackType: 'GENERATIVE_AI_TEXT_FEEDBACK_TYPE_RANKED_WINNER',
        messageId,
      },
      losers.map((genText) => ({
        messageId,
        generatedText: genText.text,
        source: genText.source,
        generatedTextInputMetadata: genText.aiGeneratedTextMetadata
          ? getLoggingMetadataFromGeneratedTextMetadata([...genText.aiGeneratedTextMetadata])
          : { origin },
      }))
    );
  };

  return (
    <GeneratedTextFeedbackStateProvider>
      <CopyAssistantAccordion
        open={open}
        onOpenChange={onOpenChange}
        onRegenerateOptions={() => regenerateTexts(refinementOptions)}
        isRegenerateDisabled={!open || isLoading}
        showProductTitleFormattingInBrandVoiceSettings={
          messageType === 'COMPOSE_MESSAGE_TYPE_JOURNEY'
        }
      >
        <Box css={{ display: 'flex', flexDirection: 'column', gap: '$space2', minHeight: '275px' }}>
          {copyAssistantStateValue === CopyAssistantState.RESULTS && (
            <PillButtonGroup
              label="Refinement options"
              value={refinementOptions}
              onChange={handleChangeRefinementOptions}
              css={{ display: 'flex', gap: '$space1' }}
            >
              {(Object.keys(refinementOptionMap) as Array<keyof typeof refinementOptionMap>).map(
                (opt) => (
                  <PillButtonGroup.PillButton<PromptRefinementOption>
                    key={opt}
                    value={opt}
                    disabled={!generatedTexts.length}
                    data-heap-tracking={`${trackingNamespace}-refinement-option-${refinementOptionMap[opt]}`}
                  >
                    {refinementOptionMap[opt]}
                  </PillButtonGroup.PillButton>
                )
              )}
            </PillButtonGroup>
          )}
          {copyAssistantStateValue === CopyAssistantState.ERROR && <ErrorState />}
          {copyAssistantStateValue === CopyAssistantState.GET_STARTED && <GetStartedState />}
          {copyAssistantStateValue === CopyAssistantState.NO_RESULTS && <EmptyState />}
          {copyAssistantStateValue === CopyAssistantState.RESULTS &&
            Array.from({ length: maxDisplayCount }).map((_, idx) =>
              generatedTexts[idx] ? (
                <GeneratedTextButton
                  key={generatedTexts[idx].id}
                  generatedText={generatedTexts[idx]}
                  messageId={messageId}
                  onClick={() => handleInsertMessage(generatedTexts[idx])}
                  isAdded={generatedTexts[idx].id === lastAddedTextId}
                  origin={origin}
                >
                  {suggestionActions?.(generatedTexts[idx], idx + 1)}
                </GeneratedTextButton>
              ) : (
                <LoadingCard key={idx} messageId={messageId} hideCard={!isLoading} /> // eslint-disable-line react/no-array-index-key
              )
            )}
        </Box>
        <Text variant="micro" color="subdued" css={{ marginTop: '$space3' }}>
          You are solely responsible for your use of AttentiveAI and any content generated, all of
          which is governed by these{' '}
          <Link
            href="https://attentive.com/legal/ai-terms"
            target="_blank"
            rel="noreferrer"
            css={{ color: 'inherit' }}
          >
            Terms
          </Link>
          .
        </Text>
      </CopyAssistantAccordion>
    </GeneratedTextFeedbackStateProvider>
  );
};

export const CopyAssistant = (props: CopyAssistantProps) => {
  const isShutOffSwitchEngaged = useCompanyFeatureFlag('ENABLE_CA_SHUT_OFF_SWITCH');

  return isShutOffSwitchEngaged ? <DisabledCopyAssistant /> : <CopyAssistantComponent {...props} />;
};
