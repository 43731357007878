import { useCompanyFeatureFlag } from '@attentive/acore-utils';

export const useIsSelfServeAIProEnabled = () => {
  const enableAIProClientFacingExperience = useCompanyFeatureFlag(
    'ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE'
  );
  const enableAIProClientFacingBrandVoice = useCompanyFeatureFlag(
    'ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE_BRAND_VOICE'
  );
  return enableAIProClientFacingExperience && enableAIProClientFacingBrandVoice;
};
