import type { Decorator } from '@storybook/react';

import { MockDataOverrideDecorator } from '@attentive/mock-data';

import mutation, {
  useGenerateTextsFromTemplateGenerateAITextMutation as MutationType,
} from './__generated__/useGenerateTextsFromTemplateGenerateAITextMutation.graphql';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;

type GenerateTextsOverrideParams = DropFirst<
  Parameters<typeof MockDataOverrideDecorator<MutationType>>
>;

export const GenerateTextsFromTemplateMockOverrideDecorator = (
  ...args: GenerateTextsOverrideParams
): Decorator => {
  return (...decoratorArgs) =>
    MockDataOverrideDecorator<MutationType>(mutation, ...args)(...decoratorArgs);
};
