import {
  AIGeneratedTextOrigin,
  PromptRefinementOption,
  GenerateAITextInput,
  useGenerateTextsFromTemplateGenerateAITextMutation$data as ResponseType,
} from './__generated__/useGenerateTextsFromTemplateGenerateAITextMutation.graphql';

type GeneratedTextResponse = NonNullable<
  ResponseType['generateAIText']
>['generatedTextResponses'][number];

export type GeneratedText = Omit<GeneratedTextResponse, 'aiGeneratedTextMetadata'> &
  Partial<Pick<GeneratedTextResponse, 'aiGeneratedTextMetadata'>>;

export type GenerateTextsFunctionInput = Omit<
  GenerateAITextInput,
  'prompt' | 'promptRefinementOptions' | 'origin' | 'companyId'
> & {
  promptRefinementOptions?: PromptRefinementOption[];
  prompt?: string;
  useMessageEvaluation?: boolean;
};

export type GenerateTextsFunctionArgs = {
  input: GenerateTextsFunctionInput;
  onCompleted: (data: GeneratedText[]) => void;
  onError: (error: Error) => void;
};

export type GenerateTextsHookArgs = {
  origin: AIGeneratedTextOrigin;
};

export type GenerateTextsHookReturnType = [(args: GenerateTextsFunctionArgs) => void, boolean];
