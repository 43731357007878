/**
 * @generated SignedSource<<e7fa85b8628deb8f9050471e610320f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AutoJourneyStatus = "AUTO_JOURNEY_STATUS_ACTIVE" | "AUTO_JOURNEY_STATUS_DELETED" | "AUTO_JOURNEY_STATUS_PAUSED" | "AUTO_JOURNEY_STATUS_UNKNOWN" | "%future added value";
export type AIJourneyPerformancePageQuery$variables = {
  id: string;
};
export type AIJourneyPerformancePageQuery$data = {
  readonly autoJourney: {
    readonly created?: SerializedDateTime | null;
    readonly id?: string;
    readonly messages?: ReadonlyArray<{
      readonly composeMessage: {
        readonly id: string;
      } | null;
    }>;
    readonly name?: string;
    readonly status?: AutoJourneyStatus;
    readonly " $fragmentSpreads": FragmentRefs<"AIJourneySettingsCardJourneysUIFragment_autoJourney" | "TestPerformanceFragment_autoJourney">;
  } | null;
};
export type AIJourneyPerformancePageQuery = {
  response: AIJourneyPerformancePageQuery$data;
  variables: AIJourneyPerformancePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AutoJourneyMessage",
  "kind": "LinkedField",
  "name": "messages",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ComposeMessage",
      "kind": "LinkedField",
      "name": "composeMessage",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIJourneyPerformancePageQuery",
    "selections": [
      {
        "alias": "autoJourney",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TestPerformanceFragment_autoJourney"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AIJourneySettingsCardJourneysUIFragment_autoJourney"
              }
            ],
            "type": "AutoJourney",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIJourneyPerformancePageQuery",
    "selections": [
      {
        "alias": "autoJourney",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoJourneyConfig",
                "kind": "LinkedField",
                "name": "config",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Journey",
                    "kind": "LinkedField",
                    "name": "journey",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AutoJourneyClientSettings",
                "kind": "LinkedField",
                "name": "clientSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UtmSettings",
                    "kind": "LinkedField",
                    "name": "utmInputSettings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isUtmTrackingEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "utmCampaign",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AutoJourney",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3107d192483e3877c72d2ce11541e7b4",
    "id": null,
    "metadata": {},
    "name": "AIJourneyPerformancePageQuery",
    "operationKind": "query",
    "text": "query AIJourneyPerformancePageQuery(\n  $id: ID!\n) {\n  autoJourney: node(id: $id) {\n    __typename\n    ... on AutoJourney {\n      id\n      name\n      status\n      created\n      messages {\n        composeMessage {\n          id\n        }\n      }\n      ...TestPerformanceFragment_autoJourney\n      ...AIJourneySettingsCardJourneysUIFragment_autoJourney\n    }\n    id\n  }\n}\n\nfragment AIJourneySettingsCardJourneysUIFragment_autoJourney on AutoJourney {\n  clientSettings {\n    utmInputSettings {\n      isUtmTrackingEnabled\n      utmCampaign\n    }\n  }\n}\n\nfragment ExperimentStatusDialogFragment_autoJourney on AutoJourney {\n  id\n  name\n  config {\n    journey {\n      name\n      id\n    }\n  }\n}\n\nfragment TestPerformanceFragment_autoJourney on AutoJourney {\n  ...TestPerformanceTableFragment_autoJourney\n}\n\nfragment TestPerformanceTableFragment_autoJourney on AutoJourney {\n  id\n  name\n  ...ExperimentStatusDialogFragment_autoJourney\n}\n"
  }
};
})();

(node as any).hash = "51d4f6d21b0433f7ed1535d2d57eefe4";

export default node;
