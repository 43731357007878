export type ProductInfo = {
  name: string;
};

// To be updated as we onboard clients that we don't have access to products for,
// or have a preferred product override
const companyProductOverrides: Record<string, ProductInfo> = {
  Cariuma: {
    name: 'OCA Low Off-White Canvas Sneaker',
  },
  Keds: {
    name: 'Keds Point Linen Lace Up',
  },
};

export const fallbackProduct: ProductInfo = { name: 'Fluffy Robe' };

export const getProductOverrideForCompany = (companyName: string) =>
  companyProductOverrides[companyName] || null;
