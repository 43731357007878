import React from 'react';

import { styled, Text } from '@attentive/picnic';

import genericSubscriberPhoto from '../assets/genericSubscriberPhoto.png';

interface TextMessagePreviewHeaderProps {
  subscriberPhoto?: string;
  subscriberName?: string;
}

const PreviewHeaderContainer = styled('div', {
  display: 'flex',
  gap: '$space3',
  alignItems: 'center',
  width: '336px',
});

const SubscriberPhoto = styled('img', {
  height: '$size8',
  width: '$size8',
});

export const TextMessagePreviewHeader: React.FunctionComponent<TextMessagePreviewHeaderProps> = ({
  subscriberName,
  subscriberPhoto,
}) => {
  return (
    //Set a preset width to make sure image lines up with phone frame
    <PreviewHeaderContainer>
      <Text variant="caption" style={{ width: '140px', textAlign: 'right' }}>
        <strong>Sent to:</strong>
      </Text>
      <SubscriberPhoto src={subscriberPhoto ?? genericSubscriberPhoto} />
      <Text variant="caption" style={{ width: '140px' }}>
        {subscriberName ?? 'All customers'}
      </Text>
    </PreviewHeaderContainer>
  );
};
