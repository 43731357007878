/**
 * @generated SignedSource<<8d0f39375cf79ca5295696474baa0401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerativeAITextFeedbackCategory = "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BAD_GRAMMAR" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BRAND_VOICE_MISMATCH" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_CONTENT_PROMPT_MISMATCH" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_INCLUDES_PII" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OFFENSIVE_LANGUAGE" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OTHER" | "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_UNKNOWN" | "%future added value";
export type GenerativeAITextFeedbackType = "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_A_B_WINNER" | "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_FLAGGED" | "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_GENERATE_MORE_SIMILAR" | "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_RANKED_WINNER" | "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_UNKNOWN" | "%future added value";
export type SubmitGeneratedTextFeedbackInput = {
  companyId: string;
  generatedTextFeedback?: ReadonlyArray<GenerativeAITextFeedbackInput> | null;
};
export type GenerativeAITextFeedbackInput = {
  feedbackCategory?: GenerativeAITextFeedbackCategory | null;
  feedbackType: GenerativeAITextFeedbackType;
  generatedTextId: string;
  groupId: string;
  messageId?: string | null;
  userId: string;
};
export type useLogGeneratedTextFeedbackMutation$variables = {
  input: SubmitGeneratedTextFeedbackInput;
};
export type useLogGeneratedTextFeedbackMutation$data = {
  readonly submitGeneratedTextFeedback: {
    readonly statusText: string;
  } | null;
};
export type useLogGeneratedTextFeedbackMutation = {
  response: useLogGeneratedTextFeedbackMutation$data;
  variables: useLogGeneratedTextFeedbackMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubmitGeneratedTextFeedbackPayload",
    "kind": "LinkedField",
    "name": "submitGeneratedTextFeedback",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "statusText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLogGeneratedTextFeedbackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLogGeneratedTextFeedbackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b53b55ac964f75f38492df5ced0ebbba",
    "id": null,
    "metadata": {},
    "name": "useLogGeneratedTextFeedbackMutation",
    "operationKind": "mutation",
    "text": "mutation useLogGeneratedTextFeedbackMutation(\n  $input: SubmitGeneratedTextFeedbackInput!\n) {\n  submitGeneratedTextFeedback(input: $input) {\n    statusText\n  }\n}\n"
  }
};
})();

(node as any).hash = "50ab64e52a02f3c061a712f0ab976d67";

export default node;
