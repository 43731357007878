import { useMemo } from 'react';

import { Tracker, TrackerEvents, useProjectName } from '@attentive/acore-utils';

import { ScaleAiFormValues } from './ScaleAiForm';

const parseFormDataToTrackableProperties = (
  formData: ScaleAiFormValues
): Record<string, string | number> => {
  return Object.keys(formData).reduce((acc, key) => {
    let val = formData[key as keyof ScaleAiFormValues];

    if (Array.isArray(val)) {
      val = val.join(', ');
    }

    return {
      ...acc,
      [key]: val,
    };
  }, {});
};

export const useMagicImageTracking = () => {
  const projectName = useProjectName();

  const trackingMethods = useMemo(
    () => ({
      trackMagicImagesFormSubmitted: ({
        formData,
        ...properties
      }: {
        formData: ScaleAiFormValues;
        submitCount: number;
        imageSource?: string;
        messageId: string;
        messageChannel: string;
      }) => {
        Tracker.track({
          eventName: TrackerEvents.MAGIC_IMAGES_GENERATE_IMAGES_FORM_SUBMIT,
          properties: {
            projectName,
            ...parseFormDataToTrackableProperties(formData),
            ...properties,
          },
        });
      },
      trackAddImageInsertedImage: ({
        isImageGenerated = false,
        ...properties
      }: {
        imageSource: string;
        imageUrl: string;
        messageId: string;
        messageChannel: string;
        isImageGenerated?: boolean;
        location?: number;
      }) => {
        Tracker.track({
          eventName: TrackerEvents.MAGIC_IMAGES_INSERT_IMAGE,
          properties: {
            projectName,
            isImageGenerated,
            ...properties,
          },
        });
      },
      trackMagicImagesFormSubmitErrorred: ({
        formData,
        ...properties
      }: {
        errorMessage: string;
        imageSource?: string;
        formData?: ScaleAiFormValues;
        messageId: string;
        messageChannel: string;
      }) => {
        Tracker.track({
          eventName: TrackerEvents.MAGIC_IMAGES_GENERATE_IMAGES_ERROR,
          properties: {
            projectName,
            ...(formData ? parseFormDataToTrackableProperties(formData) : {}),
            ...properties,
          },
        });
      },
      trackMagicImagesSkippedImageGeneration: (properties: {
        imageUrl: string;
        imageSource?: string;
        messageId: string;
        messageChannel: string;
      }) => {
        Tracker.track({
          eventName: TrackerEvents.MAGIC_IMAGES_SKIPPED_IMAGE_GENERATION,
          properties: {
            projectName,
            ...properties,
          },
        });
      },
    }),
    [projectName]
  );

  return trackingMethods;
};
