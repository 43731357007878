import React, { useState } from 'react';

import { Text, Icon, Banner, styled } from '@attentive/picnic';

import { CopyAssistantAccordion } from './CopyAssistantAccordion';

const MessageBox = styled('div', {
  border: '1px solid $bgDecorative3Accent',
  borderRadius: '$radius3',
  padding: '$space6',
  textAlign: 'center',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  flexGrow: 1,
});

export const ErrorState = () => (
  <MessageBox css={{ gap: '$space2' }}>
    <Icon name="Clock" />
    <Text variant="caption" css={{ textAlign: 'center' }}>
      <strong>There's been an error.</strong>
      <br />
      Click the refresh icon to try again
    </Text>
  </MessageBox>
);

export const GetStartedState = () => (
  <MessageBox>
    <Text variant="caption">Enter text in the message field to generate suggestions.</Text>
  </MessageBox>
);

export const EmptyState = () => (
  <MessageBox>
    <Text variant="caption">
      <strong>Results failed to generate.</strong>
      <br />
      Click the refresh icon to try again.
    </Text>
  </MessageBox>
);

export const DisabledCopyAssistant = () => {
  const [open, onOpenChange] = useState(false);

  return (
    <CopyAssistantAccordion
      open={open}
      onOpenChange={onOpenChange}
      onRegenerateOptions={() => {}}
      isRegenerateDisabled
      isCopyAssistantDisabled
    >
      <Banner variant="warning" dismissible={false}>
        <Text>
          Suggested messages are currently disabled. We're aware of the issue and working to resolve
          it quickly.
        </Text>
      </Banner>
    </CopyAssistantAccordion>
  );
};
