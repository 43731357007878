import type { Decorator } from '@storybook/react';

import { MockDataOverrideDecorator } from '@attentive/mock-data';

import type { DropFirst } from '../../types';

import BrandVoicePreviewGenerateMutation, {
  BrandVoicePreviewGenerateAIJourneyTextMutation as MutationType,
} from './__generated__/BrandVoicePreviewGenerateAIJourneyTextMutation.graphql';
import BrandVoicePreviewQuery, {
  BrandVoicePreviewQuery as BrandVoicePreviewQueryType,
} from './__generated__/BrandVoicePreviewQuery.graphql';

type BrandVoicePreviewQueryOverrideParams = DropFirst<
  Parameters<typeof MockDataOverrideDecorator<BrandVoicePreviewQueryType>>
>;

export const BrandVoicePreviewQueryDecorator = (
  ...args: BrandVoicePreviewQueryOverrideParams
): Decorator => {
  return (...decoratorArgs) =>
    MockDataOverrideDecorator<BrandVoicePreviewQueryType>(
      BrandVoicePreviewQuery,
      ...args
    )(...decoratorArgs);
};

type BrandVoicePreviewMutationOverrideParams = DropFirst<
  Parameters<typeof MockDataOverrideDecorator<MutationType>>
>;

export const BrandVoicePreviewGenerateMutationDecorator = (
  ...args: BrandVoicePreviewMutationOverrideParams
): Decorator => {
  return (...decoratorArgs) =>
    MockDataOverrideDecorator<MutationType>(
      BrandVoicePreviewGenerateMutation,
      ...args
    )(...decoratorArgs);
};
