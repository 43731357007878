import type { Decorator } from '@storybook/react';
import React, { useContext, useState } from 'react';

type GeneratedTextFeedbackContextValue = {
  setGeneratedTextAsFlagged: (textId: string, isFlagged?: boolean) => void;
  checkIsGeneratedTextFlagged: (textId: string) => boolean;
};

const GeneratedTextFeedbackContext = React.createContext<GeneratedTextFeedbackContextValue>({
  setGeneratedTextAsFlagged: () => {},
  checkIsGeneratedTextFlagged: () => false,
});

export const useGeneratedTextFeedbackState = () => useContext(GeneratedTextFeedbackContext);

type GeneratedTextFeedbackProviderProps = {
  children: React.ReactNode;
};

export const GeneratedTextFeedbackStateProvider = ({
  children,
}: GeneratedTextFeedbackProviderProps) => {
  const [flaggedState, setFlaggedState] = useState<Record<string, boolean>>({});

  const setGeneratedTextAsFlagged = (textId: string, isFlagged = true) => {
    setFlaggedState((state) => ({
      ...state,
      [textId]: isFlagged,
    }));
  };

  const checkIsGeneratedTextFlagged = (textId: string) => flaggedState[textId] || false;

  return (
    <GeneratedTextFeedbackContext.Provider
      value={{ setGeneratedTextAsFlagged, checkIsGeneratedTextFlagged }}
    >
      {children}
    </GeneratedTextFeedbackContext.Provider>
  );
};

export const GeneratedTextFeedbackDecorator: Decorator = (StoryComponent) => {
  return (
    <GeneratedTextFeedbackStateProvider>
      <StoryComponent />
    </GeneratedTextFeedbackStateProvider>
  );
};
