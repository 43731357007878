import bathingSuitImage from '../../assets/bathingSuitProductImage.png';
import blouseImage from '../../assets/blouseProductImage.png';
import redHeelsImage from '../../assets/redHeelsProductImage.png';
import shirtsImage from '../../assets/shirtsProductImage.png';
import subscriber1Image from '../../assets/subscriberPhoto_1.png';
import subscriber2Image from '../../assets/subscriberPhoto_2.png';
import subscriber3Image from '../../assets/subscriberPhoto_3.png';
import subscriber4Image from '../../assets/subscriberPhoto_4.png';

export const exampleTextMessages: Array<{
  id: string;
  body: string;
  mediaUrl: string;
  subscriberName: string;
  subscriberPhoto: string;
}> = [
  {
    id: '1',
    body: `👋 Julie! Those stylish red heels you loved are still waiting for you 🛍 Just $26.59 to look AMAZING! 💙 Don't miss out, ACT NOW and COMPLETE YOUR PURCHASE ➡️ https://hudsonandivy.attn.tv/abcd1234`,
    mediaUrl: redHeelsImage,
    subscriberName: 'Julie, New York',
    subscriberPhoto: subscriber4Image,
  },
  {
    id: '2',
    body: `Jamie, noticed you left a cute heart-embroidered top and plaid pants for you toddler in your cart. 🎀👧 Only $9.99 for this stylish duo! 😍Hurry these won't last! SHOP NOW 🛍
https://hudsonandivy.attn.tv/abcd1234`,
    mediaUrl: blouseImage,
    subscriberName: 'Jamie, Illinois',
    subscriberPhoto: subscriber3Image,
  },
  {
    id: '3',
    body: `👋Hi! Your best sellers 5-Pack is waiting for YOU in the cart. 😍Hurry, don't miss out on the coolest threads around! 👕👕👕👕👕
https://hudsonandivy.attn.tv/abcd1234`,
    mediaUrl: shirtsImage,
    subscriberName: 'John, Oregon',
    subscriberPhoto: subscriber2Image,
  },
  {
    id: '4',
    body: `Hello Alexandra, that Red Sporti One Piece Swimsuit you've left behind is a total steal at $23.40! 🔴👙 Don't let this great deal slip away. Shop now!
    https://hudsonandivy.attn.tv/abcd1234`,
    mediaUrl: bathingSuitImage,
    subscriberName: 'Alexandra, Florida',
    subscriberPhoto: subscriber1Image,
  },
];
