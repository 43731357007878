import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Heading, Icon, Text, Stack, PicnicCss, IconPopover, Box } from '@attentive/picnic';

import AiJourneysImage from '../../assets/aiJourneysEmpty.png';
import { AIJourneyEstimatedRevenuePopoverText } from '../../components/AIJourneyEstimatedRevenuePopoverText';
import { useAIConnectedJourneys } from '../AIConnectedJourneysProvider';

const contentStackStyle: PicnicCss = {
  padding: '$space8',
  border: '$borderDefault $borderWidths$borderWidth1 solid',
  background: '$bgDefault',
  width: '100%',
  boxShadow: '$shadow1',
  borderRadius: '$radius2',
};

export const AIJourneysSectionEmptyState: React.FunctionComponent = () => {
  const { JOURNEY_TRIGGER_TYPE_ADD_TO_CART: addToCartJourney } = useAIConnectedJourneys();

  return (
    <Stack spacing="$space8" direction="horizontal" css={contentStackStyle}>
      <img src={AiJourneysImage} style={{ width: '200px' }} />
      <Box>
        <Box css={{ display: 'flex', alignItems: 'center' }}>
          <Heading variant="sm">Drive up to 100% more revenue with AI-powered Journeys</Heading>
          <IconPopover
            size="small"
            iconName="CircleInformation"
            align="end"
            css={{ verticalAlign: 'middle' }}
          >
            <AIJourneyEstimatedRevenuePopoverText />
          </IconPopover>
        </Box>
        <Text variant="caption" css={{ margin: '$space2 0 $space3' }}>
          Optimize your cart abandonment journey by personalizing every message with Attentive AI™
        </Text>
        <Box css={{ display: 'flex', gap: '$space3' }}>
          <Button
            variant="primary"
            size="small"
            to={addToCartJourney ? `../ai/create/${addToCartJourney.id}` : '#'}
            as={RouterLink}
          >
            Set up AI Journey
          </Button>
          <Button
            variant="secondary"
            size="small"
            as="a"
            href="https://help.attentivemobile.com/hc/en-us/articles/18592457424916"
            target="_blank"
          >
            Learn more
            <Icon name="NewWindow" size="extraSmall" css={{ marginLeft: '$space2' }} />
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};
