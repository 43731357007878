import React from 'react';

import { Text } from '@attentive/picnic';

export const AIJourneyEstimatedRevenuePopoverText = () => (
  <Text variant="micro">
    Estimated revenue multiplier based on early results from Attentive AI Journey customers, August
    2023. Actual results will vary and there is no guarantee that you will actually realize the
    economic results estimated. Any estimate generated is not and should not be interpreted as a
    promise of, or contract for, any given level of revenue.
  </Text>
);
