/**
 * @generated SignedSource<<52de5491fddb37afdd9071711bb9adc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AIJourneySettingsCardJourneysUIFragment_autoJourney$data = {
  readonly clientSettings: {
    readonly utmInputSettings: {
      readonly isUtmTrackingEnabled: boolean;
      readonly utmCampaign: string | null;
    };
  };
  readonly " $fragmentType": "AIJourneySettingsCardJourneysUIFragment_autoJourney";
};
export type AIJourneySettingsCardJourneysUIFragment_autoJourney$key = {
  readonly " $data"?: AIJourneySettingsCardJourneysUIFragment_autoJourney$data;
  readonly " $fragmentSpreads": FragmentRefs<"AIJourneySettingsCardJourneysUIFragment_autoJourney">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AIJourneySettingsCardJourneysUIFragment_autoJourney",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutoJourneyClientSettings",
      "kind": "LinkedField",
      "name": "clientSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UtmSettings",
          "kind": "LinkedField",
          "name": "utmInputSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isUtmTrackingEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "utmCampaign",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AutoJourney",
  "abstractKey": null
};

(node as any).hash = "7763b8cdba7a19bf2ebf01cb8d2eabb7";

export default node;
