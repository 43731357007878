/**
 * @generated SignedSource<<dd9634ddbd3fb2637ecc1532e6e375bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AIBrandVoiceAllCapsUsage = "ALL_CAPS_USAGE_ADAPTIVE" | "ALL_CAPS_USAGE_FREQUENTLY" | "ALL_CAPS_USAGE_NEVER" | "ALL_CAPS_USAGE_SPARINGLY" | "%future added value";
export type AIBrandVoiceEmojiFrequency = "EMOJI_FREQUENCY_ADAPTIVE" | "EMOJI_FREQUENCY_FREQUENTLY" | "EMOJI_FREQUENCY_NEVER" | "EMOJI_FREQUENCY_SPARINGLY" | "%future added value";
export type AIBrandVoiceProductTitleFormatting = "PRODUCT_TITLE_FORMATTING_LOWER_CASE" | "PRODUCT_TITLE_FORMATTING_TITLE_CASE" | "PRODUCT_TITLE_FORMATTING_UPPER_CASE" | "%future added value";
export type AIBrandVoiceUrgency = "URGENCY_ADAPTIVE" | "URGENCY_FREQUENTLY" | "URGENCY_NEVER" | "URGENCY_SPARINGLY" | "%future added value";
export type BrandVoiceSettingsFormLoaderQuery$variables = {
  companyId: string;
};
export type BrandVoiceSettingsFormLoaderQuery$data = {
  readonly company: {
    readonly aiBrandVoiceSettingsV2?: {
      readonly allCapsUsage: AIBrandVoiceAllCapsUsage | null;
      readonly emojiFrequency: AIBrandVoiceEmojiFrequency;
      readonly id: string;
      readonly productTitleFormatting: AIBrandVoiceProductTitleFormatting | null;
      readonly toneKeywords: ReadonlyArray<string>;
      readonly urgency: AIBrandVoiceUrgency | null;
    } | null;
  } | null;
};
export type BrandVoiceSettingsFormLoaderQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly aiBrandVoiceSettingsV2: {
      readonly allCapsUsage: AIBrandVoiceAllCapsUsage | null;
      readonly emojiFrequency: AIBrandVoiceEmojiFrequency;
      readonly id: string;
      readonly productTitleFormatting: AIBrandVoiceProductTitleFormatting | null;
      readonly toneKeywords: ReadonlyArray<string>;
      readonly urgency: AIBrandVoiceUrgency | null;
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type BrandVoiceSettingsFormLoaderQuery = {
  rawResponse: BrandVoiceSettingsFormLoaderQuery$rawResponse;
  response: BrandVoiceSettingsFormLoaderQuery$data;
  variables: BrandVoiceSettingsFormLoaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AIBrandVoiceSettingsV2",
      "kind": "LinkedField",
      "name": "aiBrandVoiceSettingsV2",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urgency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "toneKeywords",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allCapsUsage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emojiFrequency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productTitleFormatting",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandVoiceSettingsFormLoaderQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandVoiceSettingsFormLoaderQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "161d44a6b7ad0e908ebcc6159973a864",
    "id": null,
    "metadata": {},
    "name": "BrandVoiceSettingsFormLoaderQuery",
    "operationKind": "query",
    "text": "query BrandVoiceSettingsFormLoaderQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      aiBrandVoiceSettingsV2 {\n        id\n        urgency\n        toneKeywords\n        allCapsUsage\n        emojiFrequency\n        productTitleFormatting\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9882d8b1117299284b278a1c8f1ac31";

export default node;
