import React from 'react';

import { Box, Select, TextInput, InputGroup, Icon } from '@attentive/picnic';

import { TriggerIdSelect } from './TriggerIdSelect';

import { JourneyRevisionPublishedStatus } from './__generated__/JourneysTableML_Query.graphql';

const statusFilterOptions: Partial<Record<JourneyRevisionPublishedStatus, string>> = {
  JOURNEY_REVISION_PUBLISHED_STATUS_UNKNOWN: 'All statuses',
  JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE: 'Active',
  JOURNEY_REVISION_PUBLISHED_STATUS_DRAFT: 'Draft',
  JOURNEY_REVISION_PUBLISHED_STATUS_INACTIVE: 'Inactive',
};

export const JourneysTableControls = ({
  searchTerm,
  setSearchTerm,
  status,
  setStatusFilter,
  triggerId,
  setTriggerId,
}: {
  searchTerm: string;
  setSearchTerm: (val: string) => void;
  triggerId: string | null;
  setTriggerId: (id: string | null) => void;
  status: JourneyRevisionPublishedStatus | null;
  setStatusFilter: (status: JourneyRevisionPublishedStatus | null) => void;
}) => {
  const onStatusChange = (val: JourneyRevisionPublishedStatus) => {
    setStatusFilter(val === 'JOURNEY_REVISION_PUBLISHED_STATUS_UNKNOWN' ? null : val);
  };

  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '$space4',
      }}
    >
      <InputGroup css={{ minWidth: '320px' }}>
        <InputGroup.LeftElement>
          <Icon name="MagnifyingGlass" size="small" mode="decorative" />
        </InputGroup.LeftElement>
        <TextInput
          size="small"
          value={searchTerm}
          onChange={(ev) => setSearchTerm(ev.currentTarget.value)}
          placeholder="Search journey name"
          aria-label="Search by journey name"
        />
      </InputGroup>
      <Box css={{ display: 'flex', gap: '$space4' }}>
        <Select
          value={status || 'JOURNEY_REVISION_PUBLISHED_STATUS_UNKNOWN'}
          onChange={(val: string) => onStatusChange(val as JourneyRevisionPublishedStatus)}
          size="small"
        >
          {(Object.keys(statusFilterOptions) as JourneyRevisionPublishedStatus[]).map(
            (statusKey) => (
              <Select.Item key={statusKey} value={statusKey}>
                {statusFilterOptions[statusKey]}
              </Select.Item>
            )
          )}
        </Select>
        <TriggerIdSelect triggerId={triggerId} onTriggerIdChange={setTriggerId} />
      </Box>
    </Box>
  );
};
