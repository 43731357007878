import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Tracker, TrackerEvents, useCurrentCompanyId } from '@attentive/acore-utils';
import {
  Button,
  Text,
  Box,
  styled,
  Dialog,
  DropdownMenu,
  IconButton,
  LoadingPlaceholder,
} from '@attentive/picnic';

import { getTopicDisplayName } from '../../../utils/generativeTopicTemplateMap';
import { CopyToClipboardButton } from '../../GeneratedTextButton/CopyToClipboardButton';
import {
  GeneratedTextFeedbackForm,
  useGeneratedTextFeedbackState,
} from '../../GeneratedTextFeedback';
import { TopicalGeneratedText } from '../CopyAssistantPlayground';
import { getCreateCampaignLink } from '../getCreateCampaignLink';

type GeneratedTextCardProps = {
  onGenerateMoreLikeThis: (topicalGeneratedText: TopicalGeneratedText) => void;
  topicalGeneratedText?: TopicalGeneratedText;
  genTextPosition: number;
  origin: string;
};

type FooterActionMenu = {
  topicalGeneratedText?: TopicalGeneratedText;
  onGenerateMoreLikeThis: (topicalGeneratedText: TopicalGeneratedText) => void;
  origin: string;
};

// Used for anything that is interactive within the card,
// so it renders over the CardLink
const interactiveContentClassName = '--is-interactive';

const FooterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '$space3',
  width: '100%',
  borderTop: '1px solid $borderDefault',
});

const GeneratedTextCardContainer = styled('article', {
  display: 'flex',
  flexDirection: 'column',
  background: '$bgDefault',
  border: '1px solid $bgDecorative3Accent',
  padding: '$space4',
  position: 'relative',
  borderRadius: '$radius2',
  boxShadow: '$shadow1',
  width: '100%',
  '&:hover': {
    textDecoration: 'none',
    cursor: 'pointer',
    boxShadow: '$shadow4',
    transform: 'translateY(-2px)',
  },
  defaultTransition: ['transform', 'border', 'box-shadow', 'color'],

  [`& .${interactiveContentClassName}`]: {
    position: 'relative',
    zIndex: 2,
  },
});

const CardLink = styled(RouterLink, {
  '&::before': {
    content: '',
    position: 'absolute',
    inset: 0,
  },

  '& button': {
    defaultTransition: ['backgroundColor', 'borderColor'],
  },

  '&:hover button': {
    backgroundColor: '$bgActionPrimaryHover',
    borderColor: '$bgActionPrimaryHover',
  },
});

const FooterActionMenu = ({
  onGenerateMoreLikeThis,
  topicalGeneratedText,
  origin,
}: FooterActionMenu) => {
  const [isFlagDialogOpen, setIsFlagDialogOpen] = useState(false);
  const { checkIsGeneratedTextFlagged } = useGeneratedTextFeedbackState();

  if (!topicalGeneratedText)
    return (
      <IconButton
        description="More Options"
        iconName="DotsVertical"
        size="extraSmall"
        variant="subdued"
        className={interactiveContentClassName}
        disabled
      />
    );

  const { topic, ...generatedText } = topicalGeneratedText;

  const isTextFlagged = checkIsGeneratedTextFlagged(topicalGeneratedText.id);

  return (
    <div className={interactiveContentClassName}>
      <DropdownMenu>
        <DropdownMenu.Trigger>
          <IconButton
            iconName="DotsVertical"
            description="More Options"
            size="extraSmall"
            variant="subdued"
            css={{ color: '$textSubdued' }}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.TextItem
            onSelect={() => setIsFlagDialogOpen(true)}
            disabled={isTextFlagged}
          >
            Flag this message
          </DropdownMenu.TextItem>
          {!topic && (
            <DropdownMenu.TextItem onSelect={() => onGenerateMoreLikeThis(topicalGeneratedText)}>
              See more like this
            </DropdownMenu.TextItem>
          )}
        </DropdownMenu.Content>
      </DropdownMenu>
      <Dialog open={isFlagDialogOpen} onOpenChange={setIsFlagDialogOpen}>
        <Dialog.Content css={{ p: '$space2 $space6' }}>
          <GeneratedTextFeedbackForm
            origin={origin}
            generatedText={generatedText}
            onSuccess={() => setIsFlagDialogOpen(false)}
          />
        </Dialog.Content>
      </Dialog>
    </div>
  );
};

const LoadingTextRow = styled(LoadingPlaceholder, {
  height: '$size3',
  width: '100%',
  borderRadius: '$radius1',
});

const LoadingContent = () => {
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$space3',
      }}
    >
      <LoadingTextRow />
      <LoadingTextRow />
      <LoadingTextRow css={{ width: '55%' }} />
    </Box>
  );
};

export const GeneratedTextCard = ({
  onGenerateMoreLikeThis,
  topicalGeneratedText,
  origin,
}: GeneratedTextCardProps) => {
  const companyId = useCurrentCompanyId();
  const createCampaignLink = topicalGeneratedText
    ? getCreateCampaignLink(companyId, topicalGeneratedText)
    : '#';

  const logCreateCampaignFromMessage = (genText?: TopicalGeneratedText) => {
    if (!genText) return;

    Tracker.track({
      eventName: TrackerEvents.COPY_ASSISTANT_PLAYGROUND_CLICKED_DRAFT_CAMPAIGN,
      properties: {
        topicName: genText.topic,
        suggestion: genText.text,
      },
    });
  };

  return (
    <GeneratedTextCardContainer css={!topicalGeneratedText ? { pointerEvents: 'none' } : undefined}>
      <Box css={{ minHeight: '108px', marginBottom: '$space3', flexGrow: 1 }}>
        {topicalGeneratedText ? (
          <>
            {topicalGeneratedText.topic && (
              <Box
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid $borderDefault',
                  paddingBottom: '$space3',
                  marginBottom: '$space3',
                }}
              >
                <Text variant="caption" css={{ fontWeight: '$bold' }}>
                  {getTopicDisplayName(topicalGeneratedText.topic)}
                </Text>
                <Button
                  css={{ minHeight: 0 }}
                  variant="subdued"
                  size="small"
                  onClick={() => onGenerateMoreLikeThis(topicalGeneratedText)}
                  className={interactiveContentClassName}
                >
                  <Text as="span" css={{ fontWeight: '$regular' }} variant="micro">
                    See more like this
                  </Text>
                </Button>
              </Box>
            )}
            <Text variant="caption" css={{ whiteSpace: 'pre-line' }}>
              {topicalGeneratedText.text}
            </Text>
          </>
        ) : (
          <LoadingContent />
        )}
      </Box>
      <FooterContainer>
        <CardLink to={createCampaignLink}>
          <Button
            variant="primary"
            size="small"
            disabled={!topicalGeneratedText}
            onClick={() => logCreateCampaignFromMessage(topicalGeneratedText)}
          >
            Draft campaign
          </Button>
        </CardLink>
        <Box css={{ display: 'flex', gap: '$space2' }}>
          <CopyToClipboardButton
            className={interactiveContentClassName}
            textToCopy={topicalGeneratedText?.text || ''}
            size="extraSmall"
            css={{ color: '$iconSubdued' }}
            disabled={!topicalGeneratedText}
            onCopyText={() => {
              if (!topicalGeneratedText) return;
              Tracker.track({
                eventName: TrackerEvents.COPY_ASSISTANT_PLAYGROUND_COPIED_SUGGESTION,
                properties: {
                  topicName: topicalGeneratedText.topic,
                  suggestion: topicalGeneratedText.text,
                },
              });
            }}
          />
          <FooterActionMenu
            topicalGeneratedText={topicalGeneratedText}
            onGenerateMoreLikeThis={onGenerateMoreLikeThis}
            origin={origin}
          />
        </Box>
      </FooterContainer>
    </GeneratedTextCardContainer>
  );
};
