import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Tracker, TrackerEvents, useDebouncedValue } from '@attentive/acore-utils';
import { Box, Heading, IconButton, IconCircle, Link, Text, styled } from '@attentive/picnic';

import { logger } from '../../logger';
import { useSaveGeneratedTextAndFeedback } from '../../utils/generatedTextFeedback/useSaveGeneratedTextAndFeedback';
import {
  GenerativeTopicKey,
  useGenerativeTopicTemplateMap,
} from '../../utils/generativeTopicTemplateMap';
import { shuffleArray } from '../../utils/shuffleArray';
import {
  useGenerateTextsFromTemplate,
  GeneratedText,
  GenerateTextsHookArgs,
} from '../../utils/useGenerateTextsFromTemplate';
import { usePrevious } from '../../utils/usePrevious';
import { GeneratedTextFeedbackStateProvider } from '../GeneratedTextFeedback';

import { GeneratedTextCard } from './GeneratedTextCard';
import { RotatingPlaceholderInput } from './RotatingPlaceholderInput';

export type TopicalGeneratedText = GeneratedText & { topic?: GenerativeTopicKey };

const rotatingPlaceholders = [
  'Try: "Flash sale, all products, 20% off, sound urgent, use emojis"',
  'Sitewide sale, 10% off, no code needed, this weekend only',
  'Try: "$5 off, VIP deal, exclusive, use 🔥 emoji, use code VIP20"',
  'Try: "New product drop, exclusive launch, buy now before they sell out"',
];

const CustomIconCircle = styled(IconCircle, {
  '&&': { background: '$bgDecorative3Accent' },
  '& svg': { fill: '$textHover' },
});

const ErrorState = () => (
  <Box
    css={{
      border: '1px solid $bgDecorative3Accent',
      borderRadius: '$radius3',
      padding: '$space8 $space6',
      textAlign: 'center',
    }}
  >
    <Text variant="caption">
      <strong>There's been an error.</strong> Click the refresh icon to try again.
    </Text>
  </Box>
);

const cardArray = Array.from({ length: 3 }).map((_, idx) => idx);

const generateOptions: GenerateTextsHookArgs = {
  origin: 'GENERATED_TEXT_ORIGIN_CAMPAIGN_SUGGESTIONS_HOMEPAGE',
};

export const CopyAssistantPlayground = () => {
  const [generateTexts, isLoading] = useGenerateTextsFromTemplate(generateOptions);
  const topicTemplateMap = useGenerativeTopicTemplateMap();
  const saveGeneratedTextAndFeedback = useSaveGeneratedTextAndFeedback();
  const [promptInput, setPromptInput] = useState('');
  const debouncedPromptInput = useDebouncedValue(promptInput, 700);
  const previousInputText = usePrevious(debouncedPromptInput);
  const [generatedTexts, setGeneratedTexts] = useState<TopicalGeneratedText[]>([]);
  const [hasError, setHasError] = useState(false);
  const defaultTopics: GenerativeTopicKey[] = useMemo(
    () => shuffleArray(['FLASH_SALE', 'BESTSELLERS', 'FLASH_SALE']),
    []
  );

  const handleError = useCallback((err: Error) => {
    logger.logError(err);
    setHasError(true);
  }, []);

  const generateTextsFromTopics = useCallback(
    (topics: GenerativeTopicKey[]) => {
      setHasError(false);
      setGeneratedTexts([]);

      topics.forEach((topicKey) => {
        generateTexts({
          input: {
            n: 2,
            template: topicTemplateMap[topicKey],
          },
          onCompleted: (data) => {
            if (data.length) {
              setGeneratedTexts((texts) => [
                ...texts,
                {
                  ...data[0],
                  topic: topicKey,
                },
              ]);
            }
          },
          onError: handleError,
        });
      });
    },
    [handleError, topicTemplateMap] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const generateTextsFromPrompt = useCallback(
    (prompt: string) => {
      setHasError(false);
      setGeneratedTexts([]);

      generateTexts({
        input: {
          prompt,
          n: 5,
        },
        onCompleted: (data) => {
          setGeneratedTexts(data.slice(0, 3));
        },
        onError: handleError,
      });
    },
    [handleError] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const regenerateMessages = () => {
    if (debouncedPromptInput) {
      generateTextsFromPrompt(debouncedPromptInput);
    } else {
      generateTextsFromTopics(defaultTopics);
    }
  };

  const generateMoreLikeThis = (genText: TopicalGeneratedText) => {
    setPromptInput(genText.text);
    setHasError(false);
    setGeneratedTexts([]);

    generateTexts({
      input: {
        prompt: genText.text,
        n: 5,
        template: topicTemplateMap.MORE_LIKE_THIS,
      },
      onCompleted: (data) => {
        setGeneratedTexts(data.slice(0, 3));
      },
      onError: handleError,
    });

    saveGeneratedTextAndFeedback({
      groupId: genText.groupId,
      feedbackType: 'GENERATIVE_AI_TEXT_FEEDBACK_TYPE_GENERATE_MORE_SIMILAR',
      generatedText: genText.text,
      source: genText.source,
      generatedTextInputMetadata: { origin: generateOptions.origin },
    });
  };

  // As user types, update the generated responses.
  // If no user input, generate from the topic-based defaults.
  useEffect(() => {
    if (isLoading) return;

    if (debouncedPromptInput && debouncedPromptInput !== previousInputText) {
      generateTextsFromPrompt(debouncedPromptInput);
      Tracker.track({
        eventName: TrackerEvents.COPY_ASSISTANT_PLAYGROUND_ENTERED_PROMPT,
        properties: {
          userInput: debouncedPromptInput,
        },
      });
    } else if (!generatedTexts.length || !debouncedPromptInput) {
      generateTextsFromTopics(defaultTopics);
    }
  }, [debouncedPromptInput, previousInputText, handleError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GeneratedTextFeedbackStateProvider>
      <Box
        as="section"
        css={{
          background: '$bgDecorative3Default',
          padding: '$space5 $space6 $space4',
          borderRadius: '$radius2',
        }}
      >
        <Box
          css={{
            display: 'grid',
            gridTemplateColumns: 'auto auto 1fr auto',
            gap: '$space3',
            alignItems: 'center',
          }}
        >
          <CustomIconCircle iconName="Magic" size="small" />
          <Heading as="h2" variant="md" css={{ whiteSpace: 'nowrap' }}>
            Campaign Assistant
          </Heading>
          <Text variant="caption" css={{ marginTop: '$space1' }}>
            Enter a prompt below to generate message ideas for your next campaign.
          </Text>
          <Box css={{ marginLeft: 'auto' }}>
            <Text variant="caption">
              <Link
                href="https://help.attentivemobile.com/hc/en-us/articles/14180702046996-Magic-Message-Generate-SMS-copy"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </Link>
            </Text>
          </Box>
        </Box>
        <Box css={{ padding: '$space4 0' }}>
          <Box
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              gap: '$space2',
              marginBottom: '$space4',
            }}
          >
            <RotatingPlaceholderInput
              value={promptInput}
              onChange={(ev) => setPromptInput(ev.currentTarget.value)}
              placeholders={rotatingPlaceholders}
            />
            <IconButton
              variant="primary"
              iconName="Sync"
              description="Regenerate messages"
              onClick={regenerateMessages}
              disabled={isLoading}
            />
          </Box>
          {hasError ? (
            <ErrorState />
          ) : (
            <Box css={{ display: 'flex', gap: '$space4' }}>
              {cardArray.map((idx) => (
                <GeneratedTextCard
                  origin={generateOptions.origin}
                  key={idx}
                  topicalGeneratedText={generatedTexts[idx]}
                  onGenerateMoreLikeThis={generateMoreLikeThis}
                  genTextPosition={idx + 1}
                />
              ))}
            </Box>
          )}
        </Box>
        <Text variant="micro" color="subdued" css={{ textAlign: 'center' }}>
          You are solely responsible for your use of Attentive AI and any content generated, all of
          which is governed by these{' '}
          <Link
            href="https://attentive.com/legal/ai-terms"
            target="_blank"
            rel="noreferrer"
            css={{ color: 'inherit' }}
          >
            Terms
          </Link>
          .
        </Text>
      </Box>
    </GeneratedTextFeedbackStateProvider>
  );
};
