import _useSaveGeneratedTextAndFeedbackMutation from "./__generated__/useSaveGeneratedTextAndFeedbackMutation.graphql";
import { useCallback } from 'react';
import { useMutation } from 'react-relay';
import { useCurrentCompanyId, useCurrentUser } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { logger } from '../../logger';
import useSaveGeneratedTextAndFeedbackMutation from './__generated__/useSaveGeneratedTextAndFeedbackMutation.graphql';
_useSaveGeneratedTextAndFeedbackMutation;
export const getLoggingMetadataFromGeneratedTextMetadata = metadata => {
  var _metadata$find, _metadata$find2, _metadata$find3;

  const loggingMetadata = {};
  const templateName = (_metadata$find = metadata.find(item => item.key === 'userPromptTemplateName')) === null || _metadata$find === void 0 ? void 0 : _metadata$find.value;
  const version = (_metadata$find2 = metadata.find(item => item.key === 'userPromptTemplateVersion')) === null || _metadata$find2 === void 0 ? void 0 : _metadata$find2.value;

  if (templateName && version) {
    loggingMetadata.template = {
      templateName,
      version
    };
  }

  loggingMetadata.origin = (_metadata$find3 = metadata.find(item => item.key === 'origin')) === null || _metadata$find3 === void 0 ? void 0 : _metadata$find3.value;
  return loggingMetadata;
};
export function useSaveGeneratedTextAndFeedback() {
  const companyId = useCurrentCompanyId();
  const {
    id: userId
  } = useCurrentUser();
  const [saveTextAndFeedback] = useMutation(useSaveGeneratedTextAndFeedbackMutation);
  return useCallback((args, losers) => {
    saveTextAndFeedback({
      variables: {
        input: {
          companyId,
          userId,
          ...args
        }
      }
    }); // log the winner and losers to datadog

    if (losers !== null && losers !== void 0 && losers.length) {
      const winner = {
        source: args.source,
        generatedText: args.generatedText,
        generatedTextInputMetadata: args.generatedTextInputMetadata,
        messageId: args.messageId
      };
      logger.logAction('User selected generated text', {
        origin: args.generatedTextInputMetadata.origin || undefined,
        winner,
        losers
      });
    }
  }, [saveTextAndFeedback, userId, companyId]);
}